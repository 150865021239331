import { ComponentProps, FC } from "react";
import styled, { css } from "styled-components";

import { Text } from "@/components/common";

const Button = styled(Text)<{ $isSelect: boolean }>`
  text-transform: uppercase;
  margin-bottom: 26px;
  &:last-of-type {
    margin-bottom: 0;
  }
  transition: 0.2s ease-out color;
  &:hover {
    color: ${({ theme }) => theme.colors.red};
  }
  ${({ $isSelect, theme }) =>
    $isSelect &&
    css`
      width: 100%;
      padding: 7px 0;
      text-transform: none;
      text-align: left;
      margin: 0;
      &:focus {
        outline: none;
        color: ${theme.colors.red};
      }
    `}
`;

interface Props extends ComponentProps<typeof Button> {
  active?: boolean;
  select?: boolean;
}

export const DropdownItem: FC<Props> = ({
  active,
  select,
  children,
  ...props
}) => {
  return (
    <Button
      as="button"
      $isSelect={!!select}
      $type={active ? "labelBold" : "labelNormal"}
      $color={active ? "red" : "black"}
      {...props}
    >
      {children}
    </Button>
  );
};
