import { FC, useCallback, useEffect, useState } from "react";
import styled, { css } from "styled-components";

import { PaginationLinks, VideoResource } from "@/types/api";
import { Loader, Text } from "@/components/common";
import useInfiniteLoader from "@/hooks/useInfiniteLoader";
import { errorNotification } from "@/utilities/alerts";
import { PaginatedResponse } from "@/types";
import { api } from "@/utilities/api";
import { VideoCard } from "@/pages/Home/components/VideoCard";

const Heading = styled(Text).attrs({ $type: "textPoppinsBold", as: "h3" })`
  margin: 30px 0;
  text-transform: uppercase;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 30px;
  ${({ theme }) => css`
    ${theme.breakpoints.mediumDesktop} {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  `}
`;

const StyledLoader = styled(Loader)`
  margin-top: 30px;
`;

interface State {
  isLoading: boolean;
  videos: VideoResource[];
  links?: PaginationLinks;
}

interface Props {
  contributorId: number;
}

export const Reactions: FC<Props> = ({ contributorId }) => {
  const [state, setState] = useState<State>({
    isLoading: true,
    videos: [],
  });

  const fetchPaginatedVideos = useCallback(
    (link = "/v1/videos") => {
      return api.get<never, PaginatedResponse<VideoResource[]>>(link, {
        params: {
          include: [
            "media",
            "contributor",
            "contributor.user",
            "contributor.media",
            "meta",
            "reactions_count",
            "views_count",
          ].join(","),
          per_page: 12,
          "filter[contributor_id]": contributorId,
          "filter[visible]": 1,
        },
      });
    },
    [contributorId]
  );

  useEffect(() => {
    (async () => {
      try {
        const { data, links } = await fetchPaginatedVideos();
        setState((prevState) => ({
          videos: data,
          isLoading: false,
          links,
        }));
      } catch (err) {
        errorNotification();
      }
    })();
  }, [fetchPaginatedVideos]);

  const loader = useCallback(async () => {
    if (!state.links?.next) return;
    const { data: videos, links } = await fetchPaginatedVideos(
      state.links.next
    );
    setState((prevState) => ({
      videos: [...prevState.videos, ...videos],
      isLoading: false,
      links,
    }));
  }, [state.links?.next, fetchPaginatedVideos]);

  const { isLoading: isInfiniteLoading, ref } = useInfiniteLoader(loader);

  return (
    <section>
      {state.isLoading ? (
        <Loader />
      ) : (
        <>
          <Heading>More from this contributor</Heading>
          <Grid>
            {state.videos.map((video) => (
              <VideoCard video={video} key={video.id} />
            ))}
          </Grid>
          {!!state.links?.next && (
            <div ref={ref}>{isInfiniteLoading && <StyledLoader />}</div>
          )}
        </>
      )}
    </section>
  );
};
