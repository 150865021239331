import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { ComponentProps, FC } from "react";

import { Text } from "@/components/common";
import { ReactComponent as PlayIcon } from "@/assets/icons/play.svg";

const StyledPlayButton = styled(Link)`
  opacity: var(--play-btn-opacity, 0);
  position: absolute;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 0;
  width: 90px;
  height: 90px;
  transition: 0.2s ease-out all;
  background: rgba(32, 37, 44, 0.7);
  z-index: ${({ theme }) => theme.zIndex.onTop};
  > svg {
    margin-left: 5px;
  }
  &:hover {
    background: ${({ theme }) => theme.colors.red};
  }
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      opacity: 1;
    }
  `}
`;

export const PlayButton: FC<any> = (props) => {
  return (
    <StyledPlayButton {...props}>
      <PlayIcon />
    </StyledPlayButton>
  );
};

const StyledDuration = styled(Text)`
  height: 24px;
  display: inline-flex;
  align-items: center;
  padding: 0 10px;
  background-color: rgba(32, 37, 44, 0.7);
  border-radius: 5px;
  position: absolute;
  bottom: 20px;
  right: 30px;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      right: 15px;
      bottom: 15px;
    }
  `}
`;

export const Duration: FC<ComponentProps<"span">> = ({
  children,
  className,
}) => {
  return (
    <StyledDuration
      as="span"
      $type="label"
      $color="white"
      className={className}
    >
      {children}
    </StyledDuration>
  );
};
