import { FC, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { Stream } from "@cloudflare/stream-react";

import { VideoResource } from "@/types/api";
import { api } from "@/utilities/api";

const StyledStream = styled(Stream)`
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
  height: 524px;
  iframe {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  ${({ theme }) => css`
    ${theme.breakpoints.mediumDesktop} {
      height: 360px;
    }
    ${theme.breakpoints.tablet} {
      height: 388px;
    }
    ${theme.breakpoints.phone} {
      border-radius: 0;
      height: 220px;
      iframe {
        border-radius: 0;
      }
    }
  `}
`;

interface Props {
  video: VideoResource;
}

export const VideoPlayer: FC<Props> = ({ video }) => {
  const secondsViewdRef = useRef(0);
  const intervalRef = useRef<number | null>(null);
  // @cloudflare/stream-react has incorrect types definition, thats why we use any there
  const videoRef = useRef<any>(null);
  const [duration, setDuration] = useState<number>();

  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        window.clearInterval(intervalRef.current);
      }
      secondsViewdRef.current = 0;
      intervalRef.current = null;
    };
  }, [video.id]);

  const getVideoViewedThreshold = () => {
    if (!duration || duration > 30) return 30;

    return Math.floor(duration / 2);
  };

  const trackVideoView = () => {
    api.post(`/v1/videos/${video.id}/views`).catch((err) => console.error(err));
  };

  const onPlay = () => {
    intervalRef.current = window.setInterval(() => {
      secondsViewdRef.current++;
      if (secondsViewdRef.current === getVideoViewedThreshold()) {
        trackVideoView();
        window.clearInterval(intervalRef.current!);
      }
    }, 1000);
  };

  const onPause = () => {
    if (intervalRef.current) {
      window.clearInterval(intervalRef.current);
    }
  };

  const onDurationChange = () => {
    const duration = videoRef.current!.duration;

    if (Number.isNaN(duration)) return;

    setDuration(duration);
  };

  return (
    <StyledStream
      controls
      streamRef={videoRef}
      height={"100%"}
      width={"100%"}
      responsive={false}
      src={video.cloudflare_id}
      onPlay={onPlay}
      onPause={onPause}
      poster={video.thumbnail!.default}
      onDurationChange={onDurationChange}
      autoplay
    />
  );
};
