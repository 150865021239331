import { FC } from "react";
import styled, { css } from "styled-components";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import MediaQuery from "react-responsive";

import DesktopImg from "@/assets/img/about/about.png";
import MobileImg from "@/assets/img/about/about-mobile.png";
import Icon1 from "@/assets/img/about/1.svg";
import Icon2 from "@/assets/img/about/2.svg";
import Icon3 from "@/assets/img/about/3.svg";
import Icon4 from "@/assets/img/about/4.svg";
import Icon5 from "@/assets/img/about/5.svg";
import { Button, Card, Text } from "@/components/common";
import { Breakpoints } from "@/utilities/theme";

const Wrap = styled.div`
  padding: 32px 0 120px;
`;

const StyledCard = styled(Card)`
  padding: 103px 120px 87px;
  position: relative;
  ${({ theme }) => css`
    ${theme.breakpoints.mediumDesktop} {
      padding-left: 70px;
      padding-right: 70px;
    }
    ${theme.breakpoints.tablet} {
      padding: 80px 30px 45px;
    }
    ${theme.breakpoints.phone} {
      padding: 30px 15px;
    }
  `}
`;

const Image = styled.img`
  display: block;
  max-width: 100%;
  margin: 60px auto 90px;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      margin: 0 auto 47px;
    }
  `}
`;

const TopButton = styled(Button)`
  position: absolute;
  left: 0;
  right: 0;
  top: -24px;
  margin: 0 auto;
  width: 340px;
  max-width: 100%;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      position: static;
      display: flex;
      margin: 0 auto 43px;
    }
  `}
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-column-gap: 110px;
  ${({ theme }) => css`
    ${theme.breakpoints.smallDesktop} {
      grid-template-columns: minmax(0, 1fr);
      grid-row-gap: 72px;
    }
    ${theme.breakpoints.phone} {
      grid-row-gap: 40px;
    }
  `}
  > div {
    display: grid;
    grid-row-gap: 72px;
    grid-template-columns: minmax(0, 1fr);
    ${({ theme }) => css`
      ${theme.breakpoints.phone} {
        grid-row-gap: 40px;
      }
    `}
    &:last-of-type {
      grid-row-gap: 48px;
      ${({ theme }) => css`
        ${theme.breakpoints.mediumDesktop} {
          grid-row-gap: 80px;
        }
        ${theme.breakpoints.smallDesktop} {
          grid-row-gap: 72px;
        }
        ${theme.breakpoints.phone} {
          grid-row-gap: 40px;
        }
      `}
    }
  }
`;

const Column = styled.article`
  display: flex;
  align-items: flex-start;
  img {
    width: 90px;
    margin-right: 30px;
  }
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      flex-direction: column;
      align-items: center;
      text-align: center;
      img {
        margin: 0 0 20px;
      }
    }
  `}
`;

const Content = styled.div`
  flex: 1;
  p {
    margin: 0 0 20px;
    &:last-of-type {
      margin: 0;
    }
  }
`;

const Heading = styled(Text)`
  margin: 0 0 15px;
`;

const Footer = styled.div`
  text-align: center;
  margin-top: 80px;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      margin-top: 60px;
      margin-bottom: 30px;
    }
  `}
`;

const FooterButton = styled(Button)`
  width: 340px;
  max-width: 100%;
`;

const Strong = styled.strong`
  font-weight: 700;
  &::before {
    content: "•";
    color: ${({ theme }) => theme.colors.red};
    margin-right: 10px;
  }
`;

export const AboutPage: FC = () => {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>About Us | MyPoint.tv</title>
        <meta
          name="description"
          content="When you face important events that impact you, and those of imortance to you, whether it’s a positive or a negative event, share it with the world through MyPoint.tv. Our aim is to put the power of mass media in your hands."
        />
        <meta property="og:title" content="About | MyPoint.tv" />
        <meta
          property="og:description"
          content="When you face important events that impact you, and those of imortance to you, whether it’s a positive or a negative event, share it with the world through MyPoint.tv. Our aim is to put the power of mass media in your hands."
        />
      </Helmet>
      <Wrap>
        <MediaQuery minWidth={Breakpoints.min.tablet}>
          <Image src={DesktopImg} alt="Everyone will see" />
        </MediaQuery>

        <StyledCard>
          <MediaQuery maxWidth={Breakpoints.max.phone}>
            <Image src={MobileImg} alt="Everyone will see" />
          </MediaQuery>
          <TopButton onClick={() => navigate("/register")}>
            Become a contributor
          </TopButton>
          <Row>
            <div>
              <Column>
                <img src={Icon1} alt="Eye" />
                <Content>
                  <Heading $type="heading1" as="h3">
                    Your Point Matters
                  </Heading>
                  <Text>
                    When you face important events that impact you, and those of
                    imortance to you, whether it’s a positive or a negative
                    event, share it with the world through MyPoint.tv. Our aim
                    is to put the power of mass media in your hands.
                  </Text>
                </Content>
              </Column>
              <Column>
                <img src={Icon2} alt="Chat" />
                <Content>
                  <Heading $type="heading1" as="h3">
                    Get a Helping Hand
                  </Heading>
                  <Text>
                    Our team of editors is dedicated to helping you in every
                    step of contributing a story: finding a relevant topic,
                    overcoming technical difficulties, making your videos more
                    appealing to bigger audiences.
                  </Text>
                </Content>
              </Column>
              <Column>
                <img src={Icon3} alt="Gas station" />
                <Content>
                  <Heading $type="heading1" as="h3">
                    Find Inspiration
                  </Heading>
                  <Text>
                    Alongside your personal stories and experiences, each of our
                    communities are full of interesting news and important
                    events.
                  </Text>
                  <Text>
                    Here is a list of topics to get you going: Changes that
                    affect the public, Traffic, Acts of kindness and charity
                    events, Places worth visiting, Crime, Demonstrations,
                    Environmental concerns, Social injustice or prejudice, New
                    construction, Unexpected encounters.
                  </Text>
                </Content>
              </Column>
            </div>
            <div>
              <Column>
                <img src={Icon4} alt="Chat" />
                <Content>
                  <Heading $type="heading1" as="h3">
                    Receive Perks
                  </Heading>
                  <Text>
                    Becoming a MyPoint.TV community member means that you are
                    eligible to receiving incentives such as event passes, media
                    kits and other reporting essentials, etc. All you have to do
                    is publish 5 videos on our website to be verified.
                  </Text>
                </Content>
              </Column>
              <Column>
                <img src={Icon5} alt="Chat" />
                <Content>
                  <Heading $type="heading1" as="h3">
                    Earn Money
                  </Heading>
                  <Text>
                    <Strong>50% cut of advertising revenue.</Strong> The more
                    views and reactions that your videos generate, the more you
                    can earn from our shared advertising income model. We’ll
                    help. Each week we promote the best performing videos to a
                    further +100K people. We want to ensure that important
                    stories reach the largest audience possible.
                  </Text>
                  <Text>
                    <Strong>90% of author’s support</Strong> (taxes excluded).
                    We incourage MyPoint.tv’s viewer community to support our
                    video creators monetarily and thus be involved in spreading
                    one’s message. The remaining cut goes to our team of
                    editors, who make sure that content complies with our Terms
                    & Conditions.
                  </Text>
                  <Text>
                    <Strong>50% of content re-sell profit.</Strong> Our content
                    being unique, makes it appealing to other media outlets. In
                    case of your video being re-purposed by another news
                    channel, you are elligible to share our profit.
                  </Text>
                </Content>
              </Column>
            </div>
          </Row>
          <Footer>
            <FooterButton onClick={() => navigate("/register")}>
              Become a Contributor
            </FooterButton>
          </Footer>
        </StyledCard>
      </Wrap>
    </>
  );
};
