import { FC, useEffect } from "react";
import styled, { css } from "styled-components";
import { Outlet, useSearchParams } from "react-router-dom";

import { Card } from "@/components/common";
import { MinimalLayout } from "@/components/Layouts/MinimalLayout";
import { errorNotification } from "@/utilities/alerts";

const Inner = styled.div`
  width: 720px;
  padding: 65px 15px 70px;
  max-width: 100%;
  margin: 0 auto;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      width: 100%;
      padding: 0 15px 68px;
    }
  `}
`;

const StyledCard = styled(Card)`
  width: 930px;
  margin: 0 auto;
  max-width: 100%;
`;

export const Auth: FC = () => {
  const [urlSearchParams] = useSearchParams();

  const hasException = !!urlSearchParams.get("exception");

  useEffect(() => {
    if (hasException) {
      errorNotification();
    }
  }, [hasException]);

  return (
    <MinimalLayout>
      <StyledCard>
        <Inner>
          <Outlet />
        </Inner>
      </StyledCard>
    </MinimalLayout>
  );
};
