import { FC } from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { Text, Button, Card } from "@/components/common";
import { ReactComponent as Icon } from "@/assets/icons/404.svg";
import { useMediaQuery } from "react-responsive";
import { Breakpoints } from "@/utilities/theme";

const Wrap = styled(Card)`
  text-align: center;
  margin-top: 50px;
  padding: 100px 15px;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      padding: 66px 15px;
      margin-top: 15px;
    }
  `}
`;

const Heading = styled.h1`
  margin: 28px 0;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      margin: 50px 0;
    }
  `}
`;

const StyledIcon = styled(Icon)`
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      width: 260px;
      height: auto;
    }
  `}
`;

export const NotFound: FC = () => {
  const isMobile = useMediaQuery({
    maxWidth: Breakpoints.max.phone,
  });

  return (
    <>
      <Helmet>
        <title>404 | MyPoint.tv</title>
        <meta name="prerender-status-code" content="404" />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Wrap>
        <StyledIcon />
        <Heading>
          <Text $type="heading1" as="span">
            Oops!{" "}
          </Text>
          <Text
            $type={isMobile ? "textPoppins" : "heading1"}
            as={isMobile ? "div" : "span"}
          >
            Page not found
          </Text>
        </Heading>
        <Button as={Link} to="/" small={!isMobile}>
          Back to Homepage
        </Button>
      </Wrap>
    </>
  );
};
