import { ChangeEvent, FC, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

import { ReactComponent as SearchIcon } from "@/assets/icons/search.svg";
import { Modal } from "@/components/Modal";
import { CloseButton, Dialog } from "@/components/Modal/Dialog";
import useDebounce from "@/hooks/useDebounce";
import { VideoResource } from "@/types/api";
import { errorNotification } from "@/utilities/alerts";
import { api } from "@/utilities/api";
import { PaginatedResponse } from "@/types";
import { Loader, Text } from "@/components/common";
import { pxToRem } from "@/utilities/helpers";

const StyledModal = styled(Modal)`
  z-index: ${({ theme }) => theme.zIndex.onTop};
  justify-content: flex-start;
`;

const StyledDialog = styled(Dialog)`
  height: calc(100vh - 90px);
  padding: 0;
  display: flex;
  flex-direction: column;
  ${CloseButton} {
    width: 44px;
    height: 44px;
    top: 11px;
    right: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: ${({ theme }) => theme.zIndex.onTop};
    svg {
      width: 12px;
      path {
        stroke-width: 4px !important;
      }
    }
  }
`;

const InputWrap = styled.div`
  position: relative;
  height: 70px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
`;

const InputContainer = styled.div`
  padding: 0 20px;
`;

const StyledSearchIcon = styled(SearchIcon)`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;
  color: ${({ theme }) => theme.colors.red};
`;

const Input = styled.input`
  padding: 0 30px;
  border: 0;
  height: 23px;
  outline: none !important;
  width: 100%;
  ${({ theme }) => css`
    color: ${theme.colors.black};
    font-family: ${theme.typography.textPoppins.fontFamily};
    font-size: 16px;
    &::placeholder {
      color: ${theme.colors.black};
    }
  `}
`;

const Results = styled.div`
  padding: 0 20px;
  flex: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
`;

const ResultLink = styled(Link)`
  padding: 22px 30px;
  width: 100%;
`;

const NoResultsHeadline = styled(Text)`
  font-size: ${pxToRem(27)}rem;
  line-height: 1;
  align-self: center;
`;

const StyledLoader = styled(Loader)`
  align-self: center;
`;

interface Props {
  onClose: () => void;
}

export const SearchModal: FC<Props> = ({ onClose }) => {
  const [value, setValue] = useState("");

  const [videos, setVideos] = useState<VideoResource[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  const debouncedValue = useDebounce<string>(value, 500);

  const onChange = (e: ChangeEvent<HTMLInputElement>) =>
    setValue(e.target.value);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const res = await api.get<never, PaginatedResponse<VideoResource[]>>(
          "/v1/search",
          {
            params: {
              s: debouncedValue,
              per_page: 20,
            },
          }
        );
        setVideos(res.data);
        setIsLoading(false);
      } catch (err) {
        errorNotification();
      }
    })();
  }, [debouncedValue]);

  return (
    <StyledModal>
      <StyledDialog onClose={onClose}>
        <InputContainer>
          <InputWrap>
            <StyledSearchIcon />
            <Input
              type="text"
              placeholder="Search"
              value={value}
              onChange={onChange}
            />
          </InputWrap>
        </InputContainer>
        <Results>
          {!!debouncedValue &&
            (isLoading ? (
              <StyledLoader />
            ) : (
              <>
                {!!!videos.length ? (
                  <NoResultsHeadline $type="label" $color="gray">
                    No results found.
                  </NoResultsHeadline>
                ) : (
                  videos.map((video) => (
                    <ResultLink
                      key={video.id}
                      to={`/videos/${video.slug}`}
                      onClick={onClose}
                    >
                      <Text as="span" $type="textPoppins">
                        {video.name}
                      </Text>
                    </ResultLink>
                  ))
                )}
              </>
            ))}
        </Results>
      </StyledDialog>
    </StyledModal>
  );
};
