import { PaginationLinks, PaginationMeta } from "@/types/api";

export interface ValidationErrorResponse<T> {
  message: string;
  errors: {
    [Key in keyof T]: string[];
  };
}

export interface PaginatedResponse<T> {
  links: PaginationLinks;
  meta: PaginationMeta;
  data: T;
}

export enum FilterableReactionTypes {
  ALL = "all",
  SUPPORTING = "supporting",
  NEUTRAL = "neutral",
  OPPOSING = "opposing",
}
