import { FC } from "react";
import styled, { css } from "styled-components";
import { useForm } from "react-hook-form";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Button, Checkbox, Text } from "@/components/common";
import { FormGroup } from "@/components/common/FormGroup";
import { handleFormErrors } from "@/utilities/helpers";
import { CompleteSignUpRequest } from "@/types/api";
import { Dispatch, RootState } from "@/utilities/store";
import { successNotification } from "@/utilities/alerts";
import { fbq } from "@/utilities/tracking";

const Footer = styled.div`
  text-align: center;
  margin: 50px 0 0;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      margin: 39px 0 0;
    }
  `}
`;

const Form = styled.form`
  display: flex;
  align-items: center;
  flex-direction: column;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      padding-top: 68px;
    }
  `}
`;

const Heading = styled(Text)`
  margin: 0 0 36px;
  text-transform: uppercase;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-column-gap: 30px;
  grid-row-gap: 20px;
  width: 690px;
  max-width: 100%;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      grid-template-columns: 100%;
    }
  `}
`;

const Notices = styled.div`
  padding-left: 54px;
  margin: 41px 0 0;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      padding: 0;
    }
  `}
`;

const NoticesText = styled(Text)`
  letter-spacing: 0.33px;
  margin-left: 30px;
  margin-top: 17px;
  a {
    transition: 0.2s ease-out font-weight;
    &:hover {
      font-weight: 700;
    }
  }
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      margin-top: 22px;
      margin-left: 25px;
    }
  `}
`;

export const CompleteSignUp: FC = () => {
  const user = useSelector((state: RootState) => state.authentication.user);

  const dispatch = useDispatch<Dispatch>();

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<CompleteSignUpRequest>({
    defaultValues: {
      first_name: user!.first_name || "",
      last_name: user!.last_name || "",
      location: user!.contributor!.location || "",
      subscribe: false,
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      await dispatch.authentication.completeSignup(data);
      fbq(
        "track",
        "Lead",
        {
          content_name: "Sign up",
        },
        { eventID: `SIGN_UP_${user!.id}` }
      );
      navigate("/", { replace: true });
      successNotification(
        "Welcome!",
        "Your profile has been created successfully"
      );
    } catch (error) {
      handleFormErrors<CompleteSignUpRequest>(error, setError);
    }
  });

  return (
    <>
      <Helmet>
        <title>Complete Sign Up | MyPoint.tv</title>
      </Helmet>
      <Form onSubmit={onSubmit}>
        <Heading as="h1" $type="textPoppinsBold">
          Complete Your Sign Up
        </Heading>
        <Grid>
          <FormGroup
            // required
            placeholder="First Name"
            autoComplete="first-name"
            error={errors.first_name}
            {...register("first_name")}
          />
          <FormGroup
            required
            placeholder="Last Name"
            autoComplete="last-name"
            error={errors.last_name}
            {...register("last_name")}
          />
          <FormGroup
            placeholder="Location (Optional)"
            autoComplete="location"
            error={errors.location}
            {...register("location")}
          />
        </Grid>
        <Notices>
          <Checkbox id="subscribe_checkbox" {...register("subscribe")}>
            Subscribe to our newsletter for news updates and storytelling
            training material.
          </Checkbox>
          <NoticesText $type="labelNormal">
            By creating your account, you agree to our{" "}
            <Link to="/pages/terms-conditions">Terms {"&"} Conditions</Link> and{" "}
            <Link to="/pages/privacy-policy">Privacy Policy</Link>.
          </NoticesText>
        </Notices>
        <Footer>
          <Button type="submit" disabled={isSubmitting} loading={isSubmitting}>
            Done
          </Button>
        </Footer>
      </Form>
    </>
  );
};
