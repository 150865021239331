import { FC } from "react";
import styled, { css } from "styled-components";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";

import { Socials } from "@/pages/Auth/components/Socials";
import { Separator } from "@/pages/Auth/components/Separator";
import { Button, Checkbox, Text } from "@/components/common";
import { FormGroup } from "@/components/common/FormGroup";
import { Dispatch, RootState } from "@/utilities/store";
import { handleFormErrors } from "@/utilities/helpers";
import { Navigation } from "@/pages/Auth/components/Navigation";
import { fbq } from "@/utilities/tracking";

const Notices = styled.div`
  padding-left: 54px;
  margin: 41px 0 0;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      padding: 0;
      margin: 0;
    }
  `}
`;

const NoticesText = styled(Text)`
  letter-spacing: 0.33px;
  margin-left: 30px;
  margin-top: 17px;
  a {
    transition: 0.2s ease-out font-weight;
    &:hover {
      font-weight: 700;
    }
  }
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      margin-top: 22px;
      margin-left: 25px;
    }
  `}
`;

const Footer = styled.div`
  text-align: center;
  margin: 44px 0 0;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      margin: 39px 0 0;
    }
  `}
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 20px;

  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      grid-gap: 20px;
      grid-template-columns: 1fr;
      margin: 0 0 38px;
    }
  `}
`;

type FormData = {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  password_confirmation: string;
  location: string;
  subscribe: string | undefined;
};

export const SignUpForm: FC = () => {
  const dispatch = useDispatch<Dispatch>();

  const isLoading = useSelector(
    (rootState: RootState) => rootState.loading.effects.authentication.register
  );

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      password_confirmation: "",
      location: "",
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      const userId = await dispatch.authentication.register(data);
      fbq(
        "track",
        "Lead",
        {
          content_name: "Sign up",
        },
        { eventID: `SIGN_UP_${userId}` }
      );
    } catch (error) {
      handleFormErrors<FormData>(error, setError);
    }
  });

  return (
    <>
      <Helmet>
        <title>Sign Up | MyPoint.tv</title>
      </Helmet>
      <Navigation />
      <Socials />
      <Separator>Or sign up with email</Separator>
      <form onSubmit={onSubmit}>
        <Grid>
          <FormGroup
            type="text"
            required
            placeholder="First Name"
            error={errors.first_name}
            {...register("first_name")}
          />
          <FormGroup
            type="text"
            required
            placeholder="Last Name"
            error={errors.last_name}
            {...register("last_name")}
          />
          <FormGroup
            type="email"
            required
            placeholder="Email Address"
            autoComplete="email"
            error={errors.email}
            {...register("email")}
          />
          <FormGroup
            type="text"
            placeholder="Location (Optional)"
            autoComplete="location"
            error={errors.location}
            {...register("location")}
          />
          <FormGroup
            type="password"
            required
            placeholder="Password"
            autoComplete="new-password"
            error={errors.password}
            {...register("password")}
          />
          <FormGroup
            type="password"
            required
            placeholder="Password Confirmation"
            autoComplete="new-password"
            error={errors.password_confirmation}
            {...register("password_confirmation")}
          />
        </Grid>
        <Notices>
          <Checkbox
            id="subscribe_checkbox"
            {...register("subscribe")}
            value="1"
          >
            Subscribe to our newsletter for news updates and storytelling
            training material.
          </Checkbox>
          <NoticesText $type="labelNormal">
            By creating your account, you agree to our{" "}
            <Link to="/pages/terms-conditions">Terms {"&"} Conditions</Link> and{" "}
            <Link to="/pages/privacy-policy">Privacy Policy</Link>.
          </NoticesText>
        </Notices>
        <Footer>
          <Button type="submit" disabled={isLoading} loading={isLoading}>
            Sign Up
          </Button>
        </Footer>
      </form>
    </>
  );
};
