import { FC, useCallback, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Helmet } from "react-helmet-async";
import { useSearchParams } from "react-router-dom";

import { Text } from "@/components/common/Text";
import { VideoResource, PaginationLinks } from "@/types/api";
import { VideoCard } from "@/pages/Home/components/VideoCard";
import { PaginatedResponse } from "@/types";
import { api } from "@/utilities/api";
import { captureException } from "@sentry/browser";
import { errorNotification } from "@/utilities/alerts";
import useInfiniteLoader from "@/hooks/useInfiniteLoader";
import { Loader } from "@/components/common/Loader";
import { ReactComponent as SearchIcon } from "@/assets/icons/search.svg";
import { pxToRem } from "@/utilities/helpers";

const Wrap = styled.form`
  padding: 0 0 120px;
  min-height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
`;

const Heading = styled.h1`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0 25px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 30px;
  ${({ theme }) => css`
    ${theme.breakpoints.mediumDesktop} {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    ${theme.breakpoints.tablet} {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    ${theme.breakpoints.phone} {
      grid-template-columns: 100%;
      grid-gap: 15px;
    }
  `}
`;

const Empty = styled.div`
  border-top: 2px solid ${({ theme }) => theme.colors.white};
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const StyledSearchIcon = styled(SearchIcon)`
  color: ${({ theme }) => theme.colors.red};
  margin-right: 18px;
`;

const NoResultsHeadline = styled(Text)`
  font-size: ${pxToRem(27)}rem;
  line-height: 1;
`;

interface State {
  videos: VideoResource[];
  links?: PaginationLinks;
  isLoading: boolean;
}

export const Search: FC = () => {
  const [urlSearchParams] = useSearchParams();

  const s = urlSearchParams.get("s") || "";

  const [state, setState] = useState<State>({
    videos: [],
    isLoading: true,
  });

  const fetchPaginatedVideos = useCallback(
    (link = "/v1/search") => {
      return api.get<never, PaginatedResponse<VideoResource[]>>(link, {
        params: {
          s,
          per_page: 12,
        },
      });
    },
    [s]
  );

  useEffect(() => {
    (async () => {
      try {
        setState((prevState) => ({ ...prevState, isLoading: true }));
        const { data, links } = await fetchPaginatedVideos();
        setState({
          videos: data,
          isLoading: false,
          links,
        });
      } catch (err) {
        captureException(err);
        errorNotification();
      }
    })();
  }, [fetchPaginatedVideos]);

  const loader = useCallback(async () => {
    if (!state.links?.next) return;
    const { data: videos, links } = await fetchPaginatedVideos(
      state.links.next
    );
    setState((prevState) => ({
      videos: [...prevState.videos, ...videos],
      isLoading: false,
      links,
    }));
  }, [state.links?.next, fetchPaginatedVideos]);

  const { isLoading: isInfiniteLoading, ref } = useInfiniteLoader(loader);

  return (
    <>
      <Helmet>
        <title>Search | MyPoint.tv</title>
      </Helmet>
      <Wrap>
        <Heading>
          {!!state.videos.length && <StyledSearchIcon />}
          <Text as="span" $type="heading1">
            Your Search Results
          </Text>
        </Heading>
        {!!s && (
          <>
            {state.isLoading ? (
              <Loader />
            ) : !!state.videos.length ? (
              <>
                <Grid>
                  {state.videos.map((video) => (
                    <VideoCard video={video} key={video.id} />
                  ))}
                </Grid>
                {!!state.links?.next && (
                  <div ref={ref}>{isInfiniteLoading && <Loader />}</div>
                )}
              </>
            ) : (
              <Empty>
                <NoResultsHeadline $type="label" $color="gray">
                  No results found.
                </NoResultsHeadline>
              </Empty>
            )}
          </>
        )}
      </Wrap>
    </>
  );
};
