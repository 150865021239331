import {
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import styled, { css } from "styled-components";
import MediaQuery, { useMediaQuery } from "react-responsive";

import { Breakpoints } from "@/utilities/theme";
import { Loader, Text } from "@/components/common";
import { ReactComponent as SortIcon } from "@/assets/icons/sort.svg";
import useInfiniteLoader from "@/hooks/useInfiniteLoader";
import { errorNotification } from "@/utilities/alerts";
import { PaginatedResponse } from "@/types";
import { PaginationLinks, VideoResource } from "@/types/api";
import { api } from "@/utilities/api";
import { ListableVideoCard } from "@/components/ListableVideoCard";
import { Dropdown, DropdownItem } from "@/components/Dropdown";

const Heading = styled(Text)`
  text-transform: uppercase;
`;

const Header = styled.div`
  margin: 0 0 27px;
  display: flex;
  align-items: center;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      justify-content: center;
    }
  `}
`;

const SortButton = styled.button`
  display: inline-flex;
  align-items: center;
`;

const StyledSortIcon = styled(SortIcon)<{ $active: boolean }>`
  ${({ theme, $active }) => css`
    stroke: ${$active ? theme.colors.red : theme.colors.gray};
    &:hover {
      stroke: ${theme.colors.black};
    }
    ${theme.breakpoints.tablet} {
      margin-right: 10px;
      margin-top: -2px;
    }
  `}
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const VideosContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  margin: -15px -15px 0;
  padding: 15px 15px 0;
  ${({ theme }) => css`
    ${theme.breakpoints.tablet} {
      overflow-y: visible;
      margin: 0;
      padding: 0;
    }
  `}
`;

const SortWrap = styled.div`
  position: relative;
  margin-left: 17px;
  ${({ theme }) => css`
    ${theme.breakpoints.tablet} {
      margin-left: auto;
    }
    ${theme.breakpoints.phone} {
      margin-left: 17px;
    }
  `}
`;

enum Sort {
  "MOST RECENT",
  "MOST VIEWED",
  "MOST LIKED",
  "MOST COMMENTED",
  "MOST BOOKMARKED",
}

interface State {
  showSortDropdown: boolean;
  sort: Sort;
  videos: VideoResource[];
  links?: PaginationLinks;
  isLoading: boolean;
}

const INITIAL_STATE = {
  showSortDropdown: false,
  sort: Sort["MOST RECENT"],
  videos: [],
  isLoading: true,
};

interface Props {
  contributorId: number;
}

export const Videos: FC<Props> = ({ contributorId }) => {
  const isSmallerThanDesktop = useMediaQuery({
    maxWidth: Breakpoints.max.tablet,
  });

  const [state, setState] = useState<State>(INITIAL_STATE);
  const rootRef = useRef(null);

  const fetchPaginatedVideos = useCallback(
    (link = "/v1/videos") => {
      const include = [
        ...(state.sort === Sort["MOST BOOKMARKED"] ? ["bookmarks_count"] : []),
        ...(state.sort === Sort["MOST COMMENTED"] ? ["reactions_count"] : []),
        ...(state.sort === Sort["MOST LIKED"] ? ["likes_count"] : []),
        "media",
        "contributor",
        "contributor.user",
        "meta",
        "viewsCount",
      ].join(",");

      const sort = [
        ...(state.sort === Sort["MOST BOOKMARKED"] ? ["-bookmarks_count"] : []),
        ...(state.sort === Sort["MOST COMMENTED"] ? ["-reactions_count"] : []),
        ...(state.sort === Sort["MOST LIKED"] ? ["-likes_count"] : []),
        ...(state.sort === Sort["MOST VIEWED"] ? ["-views_count"] : []),
        "-published_at",
      ].join(",");

      return api.get<never, PaginatedResponse<VideoResource[]>>(link, {
        params: {
          sort,
          include,
          per_page: 8,
          "filter[contributor_id]": contributorId,
        },
      });
    },
    [contributorId, state.sort]
  );

  useEffect(() => {
    (async () => {
      try {
        const { data, links } = await fetchPaginatedVideos();
        setState((prevState) => ({
          ...prevState,
          videos: data,
          isLoading: false,
          links,
        }));
      } catch (err) {
        errorNotification();
      }
    })();
  }, [fetchPaginatedVideos]);

  const loader = useCallback(async () => {
    if (!state.links?.next) return;
    const { data: videos, links } = await fetchPaginatedVideos(
      state.links.next
    );
    setState((prevState) => ({
      ...prevState,
      videos: [...prevState.videos, ...videos],
      isLoading: false,
      links,
    }));
  }, [state.links?.next, fetchPaginatedVideos]);

  const { isLoading, ref } = useInfiniteLoader(
    loader,
    isSmallerThanDesktop ? null : rootRef.current
  );

  const toggleSortDropdown = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setState((prevState) => ({
      ...prevState,
      showSortDropdown: !prevState.showSortDropdown,
    }));
  };

  const setSortValue = (sort: Sort) => {
    if (sort !== state.sort) {
      setState({
        ...INITIAL_STATE,
        sort,
      });
    }
  };

  return (
    <Wrap>
      <Header>
        <Heading as="h3" $type="textPoppinsBold" $color="gray">
          Videos from this contributor
        </Heading>
        <SortWrap>
          <SortButton onClick={toggleSortDropdown}>
            <StyledSortIcon $active={state.showSortDropdown} />
            {isSmallerThanDesktop && (
              <MediaQuery minWidth={Breakpoints.min.tablet}>
                <Text
                  $type="textPoppinsBold"
                  $color={state.showSortDropdown ? "red" : "gray"}
                >
                  SORT BY
                </Text>
              </MediaQuery>
            )}
          </SortButton>
          {state.showSortDropdown && (
            <Dropdown
              onClose={() =>
                setState((prevState) => ({
                  ...prevState,
                  showSortDropdown: false,
                }))
              }
            >
              {(Object.keys(Sort) as Array<keyof typeof Sort>)
                .filter((key) => !isNaN(Number(Sort[key])))
                .map((key, index) => (
                  <DropdownItem
                    key={index}
                    onClick={() => setSortValue(Sort[key])}
                    active={state.sort === Sort[key]}
                  >
                    {key}
                  </DropdownItem>
                ))}
            </Dropdown>
          )}
        </SortWrap>
      </Header>
      <VideosContainer ref={rootRef}>
        {state.isLoading ? (
          <Loader />
        ) : !!state.videos.length ? (
          state.videos.map((video) => (
            <ListableVideoCard key={video.id} video={video} />
          ))
        ) : (
          <Text>Contributor has no videos.</Text>
        )}
        {!!state.links?.next && <div ref={ref}>{isLoading && <Loader />}</div>}
      </VideosContainer>
    </Wrap>
  );
};
