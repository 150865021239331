import { FC } from "react";
import styled, { css } from "styled-components";

import { TextBlockWithLeftTitle } from "@/types/api";
import { WysiwygContent, Text } from "@/components/common";
import { pxToRem } from "@/utilities/helpers";

const Block = styled.div`
  display: flex;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      flex-wrap: wrap;
    }
  `}
`;

const Header = styled.div`
  width: 105px;
  margin-right: 20px;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      width: 100%;
      margin: 0 0 10px;
    }
  `}
`;

const StyledContent = styled(WysiwygContent)`
  flex: 1;
  width: 100%;
  figure.table {
    margin: 0 0 ${pxToRem(30)}rem;
    ${({ theme }) => css`
      ${theme.breakpoints.phone} {
        margin: 0 -15px ${pxToRem(30)}rem;
      }
    `}
  }
`;

const Subtitle = styled(Text)`
  margin-top: 4px;
`;

interface Props {
  block: TextBlockWithLeftTitle;
}

export const TitledTextBlock: FC<Props> = ({ block }) => {
  return (
    <Block>
      <Header>
        <Text $type="textPoppinsSemiBold" as="h3">
          {block.title}
        </Text>
        {block.subtitle && (
          <Subtitle $type="label" $color="gray" as="span">
            {block.subtitle}
          </Subtitle>
        )}
      </Header>
      <StyledContent
        dangerouslySetInnerHTML={{
          __html: block.text,
        }}
      />
    </Block>
  );
};
