import { FC, useState } from "react";
import styled, { css } from "styled-components";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { MinimalLayout } from "@/components/Layouts/MinimalLayout";
import { UploadCard } from "@/pages/VideoUpload/components/UploadCard";
import { Container, Text } from "@/components/common";
import { UploadForm } from "@/pages/VideoUpload/components/UploadForm";
import { SuccessCard } from "@/pages/VideoUpload/components/SuccessCard";

const Footer = styled.div`
  margin-top: auto;
  text-align: center;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      text-align: left;
    }
  `}
  a {
    text-decoration: underline;
  }
`;

interface State {
  step: 1 | 2 | 3;
  cloudflareId: string;
  originalVideoFilename: string;
}

export const VideoUpload: FC = () => {
  const { slug } = useParams();

  const [state, setState] = useState<State>({
    step: 1,
    cloudflareId: "",
    originalVideoFilename: "",
  });

  const onUploadFinished = (
    cloudflareId: string,
    originalVideoFilename: string
  ) => {
    setState({ step: 2, cloudflareId, originalVideoFilename });
  };

  const onSuccessfulSubmit = () => {
    setState((prevState) => ({ ...prevState, step: 3 }));
  };

  const renderStep = () => {
    switch (state.step) {
      case 1:
        return <UploadCard onUploadFinished={onUploadFinished} />;
      case 2:
        return (
          <UploadForm
            parentSlug={slug}
            cloudflareId={state.cloudflareId}
            originalVideoFilename={state.originalVideoFilename}
            onSuccessfulSubmit={onSuccessfulSubmit}
          />
        );
      case 3:
        return <SuccessCard />;
      default:
        return null;
    }
  };

  return (
    <MinimalLayout>
      <Helmet>
        <title>Upload Video | MyPoint.tv</title>
      </Helmet>
      {renderStep()}
      <Footer>
        <Container>
          <Text $type="labelNormal">
            By uploading a video, you agree to our{" "}
            <a href="/pages/terms-conditions" target="_blank">
              Terms and Conditions
            </a>{" "}
            and{" "}
            <a href="/pages/privacy-policy" target="_blank">
              Privacy policy
            </a>
            .
          </Text>
        </Container>
      </Footer>
    </MinimalLayout>
  );
};
