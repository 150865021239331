import { FC } from "react";
import styled, { css } from "styled-components";

import { ReactComponent as GoogleIcon } from "@/assets/icons/google.svg";
import { ReactComponent as FacebookIcon } from "@/assets/icons/facebook.svg";
import { Text } from "@/components/common";

const Button = styled.a`
  position: relative;
  box-shadow: 0 3px 6px rgba(32, 37, 44, 0.15);
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.white};
  text-transform: uppercase;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s ease-out box-shadow;
  letter-spacing: 0.38px;
  > svg {
    position: absolute;
    left: 15px;
    top: 0;
    bottom: 0;
    margin: auto 0;
  }
  &:hover {
    box-shadow: 0 0 6px rgba(32, 37, 44, 0.15);
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      grid-gap: 20px;
      grid-template-columns: 1fr;
      margin: 0 0 38px;
    }
  `}
`;

interface Props {
  intendedPathname?: string;
}

export const Socials: FC<Props> = ({ intendedPathname }) => {
  return (
    <Grid>
      <Button
        href={`${process.env.REACT_APP_API_ENDPOINT}/socialite/facebook${
          intendedPathname ? `?intended=${intendedPathname}` : ""
        }`}
        rel="noreferrer"
      >
        <FacebookIcon />
        <Text $type="textPoppinsBold">Facebook</Text>
      </Button>
      <Button
        href={`${process.env.REACT_APP_API_ENDPOINT}/socialite/google${
          intendedPathname ? `?intended=${intendedPathname}` : ""
        }`}
        rel="noreferrer"
      >
        <GoogleIcon />
        <Text $type="textPoppinsBold">Google</Text>
      </Button>
    </Grid>
  );
};
