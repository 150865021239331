import { FC } from "react";
import styled, { css } from "styled-components";

import { Text } from "@/components/common";
import { CategoryResource } from "@/types/api";

const Wrap = styled.div`
  margin: 22px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      overflow-x: auto;
      margin: 14px -15px;
      justify-content: flex-start;
    }
  `}
`;

const Button = styled(Text)<{ $active?: boolean }>`
  display: inline-flex;
  align-items: center;
  height: 27px;
  border: 0;
  border-radius: 14px;
  letter-spacing: 0.75px;
  cursor: pointer;
  margin: 0 20px;
  padding: 0 20px;
  background-color: transparent;
  text-transform: uppercase;
  transition: 0.2s ease-out background-color, 0.2s ease-out color;
  font-weight: 700;
  ${({ theme }) => css`
    &:hover {
      background-color: ${theme.colors.red};
      color: ${theme.colors.white};
    }
    ${theme.breakpoints.phone} {
      &:first-of-type {
        margin-left: 15px;
      }
      margin: 0 10px;
    }
  `}
  ${({ theme, $active }) =>
    $active &&
    css`
      background-color: ${theme.colors.red};
      color: ${theme.colors.white};
    `}
`;

interface Props {
  categories: CategoryResource[];
  activeCategory: number;
  setActiveCategory: (categoryID: number) => void;
}

export const Navbar: FC<Props> = ({
  categories,
  activeCategory,
  setActiveCategory,
}) => {
  return (
    <Wrap>
      {categories.map((category) => (
        <Button
          key={category.id}
          $active={category.id === activeCategory}
          $type="textPoppins"
          as="button"
          onClick={() => setActiveCategory(category.id)}
        >
          {category.name}
        </Button>
      ))}
    </Wrap>
  );
};
