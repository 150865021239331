import { FC } from "react";
import styled, { css } from "styled-components";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { AxiosError } from "axios";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { Socials } from "@/pages/Auth/components/Socials";
import { Separator } from "@/pages/Auth/components/Separator";
import { Button, Text } from "@/components/common";
import { FormGroup } from "@/components/common/FormGroup";
import { Dispatch } from "@/utilities/store";
import { UnauthorizedResponse } from "@/types/api";
import { errorNotification } from "@/utilities/alerts";
import { Navigation } from "@/pages/Auth/components/Navigation";
import { Link } from "react-router-dom";

const Footer = styled.div`
  text-align: center;
  margin: 48px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      grid-gap: 20px;
      grid-template-columns: 1fr;
      margin: 0 0 46px;
    }
  `}
`;

const ForgotPasswordLink = styled(Text)`
  transition: 0.2s ease-out opacity;
  margin-top: 40px;
  &:hover {
    opacity: 0.75;
  }
`;

type FormData = {
  email: string;
  password: string;
};

export const SignInForm: FC = () => {
  const dispatch = useDispatch<Dispatch>();

  const location = useLocation();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = handleSubmit(async (credentials) => {
    try {
      await dispatch.authentication.login(credentials);
    } catch (error) {
      const err = error as AxiosError<UnauthorizedResponse>;
      if (err.response?.status === 401) {
        return setError("email", {
          type: "manual",
          message: err.response?.data?.message,
        });
      }
      errorNotification();
    }
  });

  return (
    <>
      <Helmet>
        <title>Sign In | MyPoint.tv</title>
      </Helmet>
      <Navigation />
      <Socials intendedPathname={location.state?.from?.pathname} />
      <Separator>Or sign in with email</Separator>
      <form onSubmit={onSubmit}>
        <Grid>
          <FormGroup
            type="email"
            required
            placeholder="Email Address"
            autoComplete="email"
            error={errors.email}
            {...register("email")}
          />
          <FormGroup
            type="password"
            required
            placeholder="Password"
            autoComplete="current-password"
            error={errors.password}
            {...register("password")}
          />
        </Grid>
        <Footer>
          <Button type="submit" disabled={isSubmitting} loading={isSubmitting}>
            Sign In
          </Button>
          <ForgotPasswordLink
            $type="labelSemiBold"
            as={Link}
            to="/forgot-password"
          >
            Reset Password
          </ForgotPasswordLink>
        </Footer>
      </form>
    </>
  );
};
