import { FC, FormEvent, useState } from "react";
import styled, { css } from "styled-components";
import { useForm } from "react-hook-form";
import { Helmet } from "react-helmet-async";
import { Link, useSearchParams } from "react-router-dom";

import { Button, Text, ValidationError } from "@/components/common";
import { FormGroup } from "@/components/common/FormGroup";
import { handleFormErrors } from "@/utilities/helpers";
import { api } from "@/utilities/api";
import { errorNotification } from "../../../utilities/alerts";

const Footer = styled.div`
  text-align: center;
  margin: 50px 0 0;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      margin: 39px 0 0;
    }
  `}
`;

const Form = styled.form`
  display: flex;
  align-items: center;
  flex-direction: column;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      padding-top: 68px;
    }
  `}
`;

const Heading = styled(Text)`
  margin: 0 0 36px;
  text-transform: uppercase;
`;

const Description = styled(Text)`
  text-align: center;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 30px;
  width: 690px;
  max-width: 100%;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      grid-gap: 20px;
      grid-template-columns: 100%;
    }
  `}
`;

const StyledValidationError = styled(ValidationError)`
  margin-top: 20px;
`;

type FormData = {
  email: string;
  token: string;
  password: string;
  password_confirmation: string;
};

export const ResetPassword: FC = () => {
  const [urlSearchParams] = useSearchParams();

  const token = urlSearchParams.get("token") || "";
  const email = urlSearchParams.get("email") || "";

  const [successMessage, setSuccessMessage] = useState("");

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({
    defaultValues: {
      token,
      email,
      password: "",
      password_confirmation: "",
    },
  });

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    clearErrors();
    handleSubmit(async (data) => {
      try {
        const response = await api.post<
          FormData,
          { success: boolean; message: string }
        >("/v1/auth/reset-password", data);
        if (!response.success) {
          return errorNotification(undefined, response.message);
        }
        setSuccessMessage(response.message);
      } catch (error) {
        handleFormErrors<FormData>(error, setError);
      }
    })(e);
  };

  return (
    <>
      <Helmet>
        <title>Reset Password | MyPoint.tv</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Form onSubmit={onSubmit}>
        <Heading as="h1" $type="textPoppinsBold">
          Reset Password
        </Heading>
        {!!successMessage ? (
          <Description $type="labelNormal">{successMessage}</Description>
        ) : (
          <Grid>
            <FormGroup
              type="password"
              required
              placeholder="New password"
              autoComplete="new-password"
              error={errors.password}
              {...register("password")}
            />
            <FormGroup
              type="password"
              required
              placeholder="New password"
              error={errors.password_confirmation}
              {...register("password_confirmation")}
            />
          </Grid>
        )}
        <Footer>
          {!!successMessage ? (
            <Button as={Link} to="/login">
              Back to Sign in
            </Button>
          ) : (
            <>
              <Button
                type="submit"
                disabled={isSubmitting}
                loading={isSubmitting}
              >
                Reset
              </Button>
              {!!(errors.token || errors.email) && (
                <StyledValidationError>
                  This password reset link is expired!
                </StyledValidationError>
              )}
            </>
          )}
        </Footer>
      </Form>
    </>
  );
};
