import { FC } from "react";
import styled, { css } from "styled-components";
import { useForm } from "react-hook-form";
import { Helmet } from "react-helmet-async";

import { Button, Text } from "@/components/common";
import { FormGroup } from "@/components/common/FormGroup";
import { handleFormErrors } from "@/utilities/helpers";
import { api } from "@/utilities/api";
import { Link } from "react-router-dom";

const Footer = styled.div`
  text-align: center;
  margin: 50px 0 0;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      margin: 39px 0 0;
    }
  `}
`;

const Form = styled.form`
  display: flex;
  align-items: center;
  flex-direction: column;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      padding-top: 68px;
    }
  `}
`;

const Heading = styled(Text)`
  margin: 0 0 36px;
  text-transform: uppercase;
`;

const Description = styled(Text)`
  list-style-type: decimal;
  list-style-position: inside;
  text-align: left;
  > li {
    margin: 0 0 10px;
    &:last-child {
      margin: 0;
    }
  }
`;

const StyledFormGroup = styled(FormGroup)`
  width: 330px;
  margin-top: 45px;
`;

type FormData = {
  email: string;
};

export const ForgotPassword: FC = () => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm<FormData>({
    defaultValues: {
      email: "",
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      const response = await api.post<
        FormData,
        { success: boolean; message: string }
      >("/v1/auth/forgot-password", data);
      if (!response.success) {
        return setError("email", response);
      }
    } catch (error) {
      handleFormErrors<FormData>(error, setError);
    }
  });

  return (
    <>
      <Helmet>
        <title>Forgot Password | MyPoint.tv</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Form onSubmit={onSubmit}>
        <Heading as="h1" $type="textPoppinsBold">
          Forgot your password?
        </Heading>
        {isSubmitSuccessful ? (
          <Description $type="labelNormal" as="ol">
            <li>
              You will receive an email with a link to reset your password.
            </li>
            <li>
              Open the password reset link that we have sent to you via email.
            </li>
            <li>Create a new password.</li>
            <li>Sign in again with your new password.</li>
          </Description>
        ) : (
          <>
            <Description $type="labelNormal">
              Enter your email address to reset your password.
            </Description>
            <StyledFormGroup
              type="email"
              required
              placeholder="Email Address"
              autoComplete="email"
              error={errors.email}
              {...register("email")}
            />
          </>
        )}
        <Footer>
          {isSubmitSuccessful ? (
            <Button as={Link} to="/login">
              Back to Sign In
            </Button>
          ) : (
            <Button
              type="submit"
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              Submit
            </Button>
          )}
        </Footer>
      </Form>
    </>
  );
};
