import axios, { AxiosError } from "axios";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { errorNotification } from "@/utilities/alerts";
import { api } from "@/utilities/api";

type ApiUrlResolver = (param: string) => string;

const INITIAL_STATE = {
  resource: null,
  isLoading: true,
};

interface LoaderState<T> {
  resource: T | null;
  isLoading: boolean;
}

const useResourceLoader = <T>(
  apiUrlResolver: ApiUrlResolver,
  requestParams?: {
    [key: string]: string;
  }
): [T | null, boolean, Dispatch<SetStateAction<LoaderState<T>>>] => {
  const { slug } = useParams();
  const navigate = useNavigate();

  const [state, setState] = useState<LoaderState<T>>(INITIAL_STATE);

  useEffect(() => {
    (async () => {
      try {
        setState({ ...INITIAL_STATE });
        const resource = await api.get<never, T>(apiUrlResolver(slug!), {
          params: requestParams || {},
        });
        setState({ resource, isLoading: false });
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const err = error as AxiosError;
          if (err.response?.status === 404) {
            return navigate("/404", { replace: true });
          }
        }
        errorNotification();
      }
    })();
    // eslint-disable-next-line
  }, [slug, navigate]);

  return [state.resource, state.isLoading, setState];
};

export default useResourceLoader;
