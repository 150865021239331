import {
  FC,
  useState,
  KeyboardEvent,
  MouseEvent,
  ChangeEvent,
  useRef,
  useEffect,
} from "react";
import styled from "styled-components";

import { Input, Text, SrOnly } from "@/components/common";
import { ReactComponent as RemoveIcon } from "@/assets/icons/close.svg";
import { Dropdown, DropdownItem } from "@/components/Dropdown";
import { api } from "@/utilities/api";
import { TagResource } from "@/types/api";

const Wrap = styled(Input).attrs({ as: "div" })`
  height: auto;
  display: flex;
  flex-wrap: wrap;
  min-height: 48px;
  padding: 9px 12px;
  position: relative;
`;

const StyledInput = styled(Input)`
  flex-grow: 1;
  background: transparent !important;
  border: 0 !important;
  padding: 0;
  width: auto;
  margin-left: 3px;
  height: 30px;
`;

const Tag = styled.div`
  border: 1px solid #ebebeb;
  padding: 3px 5px;
  border-radius: 4px;
  background: #ffffff;
  height: 22px;
  display: inline-flex;
  align-items: center;
  margin: 3px;
`;

const RemoveBtn = styled.button`
  margin-left: 5px;
  line-height: 0;
  > svg {
    width: 8px;
    stroke: ${({ theme }) => theme.colors.red};
  }
`;

interface Props {
  tags: string[];
  setTags: (tags: string[]) => void;
}

export const TagsInput: FC<Props> = ({ tags, setTags }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(true);

  useEffect(() => {
    (async () => {
      const tagsCollection = await api.get<never, TagResource[]>("/v1/tags");
      setSuggestions(tagsCollection.map((tagResource) => tagResource.name));
    })();
  }, [setSuggestions]);

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (tags.indexOf(value) === -1) {
        setTags([...tags, value]);
      }
      setValue("");
    }
    if ((event.key === "Delete" || event.key === "Backspace") && value === "") {
      setTags([...tags.filter((_, index, arr) => index < arr.length - 1)]);
    }
  };

  const onRemoveTag = (
    e: MouseEvent<HTMLButtonElement>,
    tagToRemove: string
  ) => {
    e.preventDefault();
    setTags([...tags.filter((tag) => tag !== tagToRemove)]);
  };

  const onAddSuggestion = (
    e: MouseEvent<HTMLButtonElement>,
    tagToAdd: string
  ) => {
    e.preventDefault();
    setValue("");
    setTags([...tags, tagToAdd]);
    inputRef.current?.focus();
  };

  const onValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    setShowSuggestions(true);
  };

  const filteredSuggestions = suggestions.filter(
    (suggestion) =>
      suggestion.toLowerCase().indexOf(value.toLowerCase()) > -1 &&
      tags.indexOf(suggestion) === -1
  );

  return (
    <Wrap>
      {tags.map((tag) => (
        <Tag key={tag}>
          <Text $type="label">{tag}</Text>
          <RemoveBtn onClick={(e) => onRemoveTag(e, tag)}>
            <SrOnly as="span">Remove tag</SrOnly>
            <RemoveIcon />
          </RemoveBtn>
        </Tag>
      ))}
      <StyledInput
        ref={inputRef}
        placeholder="Enter tag and press Enter"
        type="text"
        value={value}
        onChange={onValueChange}
        onKeyDown={onKeyDown}
      />
      {showSuggestions && value.length > 1 && !!filteredSuggestions.length && (
        <Dropdown onClose={() => setShowSuggestions(false)} select>
          {filteredSuggestions.map((tag) => (
            <DropdownItem
              select
              key={tag}
              onClick={(e: MouseEvent<HTMLButtonElement>) =>
                onAddSuggestion(e, tag)
              }
            >
              {tag}
            </DropdownItem>
          ))}
        </Dropdown>
      )}
    </Wrap>
  );
};
