import { FC, useCallback, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import MediaQuery from "react-responsive";
import { Helmet } from "react-helmet-async";

import { Breakpoints } from "@/utilities/theme";
import { Navbar } from "@/pages/Home/components/Navbar";
import { Contributors } from "@/pages/Home/components/Contributors";
import { VideoCard } from "@/pages/Home/components/VideoCard";
import { VideoSlider } from "@/pages/Home/components/VideoSlider";
import { CategoryResource, VideoResource, PaginationLinks } from "@/types/api";
import { api } from "@/utilities/api";
import { PaginatedResponse } from "@/types";
import { Loader } from "@/components/common";
import useInfiniteLoader from "@/hooks/useInfiniteLoader";
import { errorNotification } from "@/utilities/alerts";
import GoogleAd from "@/components/GoogleAd";

const Main = styled.main`
  margin-bottom: 85px;
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 30px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) 330px;
  grid-gap: 30px;
  ${({ theme }) => css`
    ${theme.breakpoints.tablet} {
      grid-template-columns: 100%;
    }
    ${theme.breakpoints.phone} {
      grid-gap: 15px;
    }
  `}
`;

const Sidebar = styled(Main)`
  margin: 0;
  display: block;
  > * {
    margin: 0 0 30px;
    &:last-child {
      margin: 0;
    }
  }
`;

const SmallGrid = styled(Grid)`
  grid-template-columns: repeat(3, 1fr);
  ${({ theme }) => css`
    ${theme.breakpoints.mediumDesktop} {
      grid-template-columns: repeat(2, 1fr);
    }
    ${theme.breakpoints.phone} {
      grid-template-columns: 100%;
    }
  `}
`;

const StyledLoader = styled(Loader)`
  margin-top: 40px;
`;

interface GenericParams {
  include: string;
  "filter[category_id]"?: number;
  "filter[visible]": number;
}

interface VideosState {
  isLoading: boolean;
  editorsChoice: VideoResource[];
  other: {
    videos: VideoResource[];
    links?: PaginationLinks;
  };
}

export const Home: FC = () => {
  const [categories, setCategories] = useState<CategoryResource[]>([]);
  const [categoriesLoaded, setCategoriesLoaded] = useState(false);

  const [videosState, setVideosState] = useState<VideosState>({
    isLoading: true,
    editorsChoice: [],
    other: {
      videos: [],
    },
  });
  const [activeCategory, setActiveCategory] = useState<number>(0);

  useEffect(() => {
    (async () => {
      try {
        const categories = await api.get<never, CategoryResource[]>(
          "/v1/categories"
        );
        setCategories([{ id: 0, name: "All" }, ...categories]);
        setCategoriesLoaded(true);
      } catch (err) {
        errorNotification();
      }
    })();
  }, []);

  const fetchPaginatedVideos = useCallback(
    async (link = "/v1/videos") => {
      return api.get<never, PaginatedResponse<VideoResource[]>>(link, {
        params: {
          include: [
            "media",
            "reactions_count",
            "contributor",
            "contributor.user",
            "contributor.media",
            "meta",
            "views_count",
          ].join(","),
          per_page: 12,
          "filter[category_id]": activeCategory || "",
          "filter[editors_choice]": 0,
          "filter[parent]": 1,
          "filter[visible]": 1,
        },
      });
    },
    [activeCategory]
  );

  useEffect(() => {
    (async () => {
      try {
        setVideosState((prevState) => ({ ...prevState, isLoading: true }));
        const genericParams: GenericParams = {
          include: [
            "media",
            "reactions_count",
            "contributor",
            "contributor.user",
            "contributor.media",
            "meta",
            "views_count",
          ].join(","),
          "filter[visible]": 1,
        };
        if (activeCategory) {
          genericParams["filter[category_id]"] = activeCategory;
        }
        const { data: editorsChoice } = await api.get<
          never,
          PaginatedResponse<VideoResource[]>
        >("/v1/videos", {
          params: {
            ...genericParams,
            per_page: 10,
            "filter[editors_choice]": 1,
          },
        });
        const { data: videos, links } = await fetchPaginatedVideos();
        setVideosState({
          isLoading: false,
          editorsChoice,
          other: { videos, links },
        });
      } catch (err) {
        errorNotification();
      }
    })();
  }, [activeCategory, fetchPaginatedVideos]);

  const loader = useCallback(async () => {
    if (!videosState.other.links?.next) return;
    const { data: videos, links } = await fetchPaginatedVideos(
      videosState.other.links.next
    );
    setVideosState((prevState) => ({
      ...prevState,
      other: { videos: [...prevState.other.videos, ...videos], links },
    }));
  }, [videosState.other.links?.next, fetchPaginatedVideos]);

  const { isLoading, ref } = useInfiniteLoader(loader);

  return (
    <>
      <Helmet>
        <title>
          MyPoint.tv | Eye-witness reports &amp; stories by, and for, everyone
        </title>
        <meta
          name="description"
          content="A media network of news videos and stories by, and for, everyone. Watch genuine eye-witness reports for your area and worldwide – or contribute your own story."
        />
        <meta
          property="og:title"
          content="MyPoint.tv | Eye-witness reports &amp; stories by, and for, everyone"
        />
        <meta
          property="og:description"
          content="A media network of news videos and stories by, and for, everyone. Watch genuine eye-witness reports for your area and worldwide – or contribute your own story."
        />
      </Helmet>
      {categoriesLoaded ? (
        <>
          <Navbar
            categories={categories}
            activeCategory={activeCategory}
            setActiveCategory={setActiveCategory}
          />
          <Grid>
            {videosState.isLoading ? (
              <StyledLoader />
            ) : (
              <Main>
                <VideoSlider videos={videosState.editorsChoice} />
                <GoogleAd adSlot="1666453239" />
                {!!videosState.other.videos?.length && (
                  <>
                    <SmallGrid>
                      {videosState.other.videos.map((video) => (
                        <VideoCard video={video} key={video.id} />
                      ))}
                    </SmallGrid>
                    {!!videosState.other.links?.next && (
                      <div ref={ref}>
                        {isLoading && <Loader>Loading more stories</Loader>}
                      </div>
                    )}
                  </>
                )}
              </Main>
            )}
            <MediaQuery minWidth={Breakpoints.min.smallDesktop}>
              <Sidebar as="aside">
                <Contributors />
                <GoogleAd adSlot="4707936589" />
              </Sidebar>
            </MediaQuery>
          </Grid>
        </>
      ) : (
        <StyledLoader />
      )}
    </>
  );
};
