import { RefObject, useEffect } from "react";

type ClickableEvent = MouseEvent | TouchEvent;

const useDetectOutsideClick = <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: () => void
): void => {
  useEffect(() => {
    const listener = (event: ClickableEvent) => {
      const el = ref?.current;

      if (!el || el.contains(event.target as Node)) {
        return;
      }
      handler();
    };

    document.addEventListener("click", listener);

    return () => {
      document.removeEventListener("click", listener);
    };
  }, [ref, handler]);
};

export default useDetectOutsideClick;
