import { FC, useCallback, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

import { ReactComponent as BookmarkIcon } from "@/assets/icons/bookmark.svg";
import { Text } from "@/components/common/Text";
import { VideoResource, PaginationLinks } from "@/types/api";
import { RootState } from "@/utilities/store";
import { VideoCard } from "@/pages/Home/components/VideoCard";
import { PaginatedResponse } from "@/types";
import { api } from "@/utilities/api";
import { errorNotification } from "@/utilities/alerts";
import useInfiniteLoader from "@/hooks/useInfiniteLoader";
import { Loader } from "@/components/common/Loader";
import { Button } from "@/components/common";

const Wrap = styled.form`
  padding: 0 0 120px;
`;

const Heading = styled.h1`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0 25px;
`;

const StyledBookmarkIcon = styled(BookmarkIcon)`
  stroke: ${({ theme }) => theme.colors.red};
  fill: ${({ theme }) => theme.colors.red};
  margin-right: 19px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 30px;
  ${({ theme }) => css`
    ${theme.breakpoints.mediumDesktop} {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    ${theme.breakpoints.tablet} {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    ${theme.breakpoints.phone} {
      grid-template-columns: 100%;
      grid-gap: 15px;
    }
  `}
`;

const Empty = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 20px;
  text-align: center;
  margin-top: 10px;
`;

interface State {
  videos: VideoResource[];
  links?: PaginationLinks;
  isLoading: boolean;
}

export const Boomarks: FC = () => {
  const bookmarkedVideos = useSelector(
    (state: RootState) => state.authentication.user?.bookmarked_videos_ids || []
  );

  const [state, setState] = useState<State>({
    videos: [],
    isLoading: false,
  });

  const fetchPaginatedVideos = useCallback(
    (link = "/v1/videos") => {
      return api.get<never, PaginatedResponse<VideoResource[]>>(link, {
        params: {
          include: [
            "media",
            "contributor",
            "contributor.user",
            "meta",
            "reactions_count",
            "views_count",
          ].join(","),
          "filter[id]": bookmarkedVideos.join(","),
          sort: "-bookmark_creation_date",
          per_page: 12,
        },
      });
    },
    [bookmarkedVideos]
  );

  useEffect(() => {
    if (bookmarkedVideos.length === 0) return;
    (async () => {
      try {
        setState((prevState) => ({ ...prevState, isLoading: true }));
        const { data, links } = await fetchPaginatedVideos();
        setState({
          videos: data,
          isLoading: false,
          links,
        });
      } catch (err) {
        errorNotification();
      }
    })();
  }, [fetchPaginatedVideos, bookmarkedVideos]);

  const loader = useCallback(async () => {
    if (!state.links?.next) return;
    const { data: videos, links } = await fetchPaginatedVideos(
      state.links.next
    );
    setState((prevState) => ({
      videos: [...prevState.videos, ...videos],
      isLoading: false,
      links,
    }));
  }, [state.links?.next, fetchPaginatedVideos]);

  const { isLoading: isInfiniteLoading, ref } = useInfiniteLoader(loader);

  return (
    <>
      <Helmet>
        <title>Bookmarks | MyPoint.tv</title>
      </Helmet>
      <Wrap>
        <Heading>
          <StyledBookmarkIcon />
          <Text as="span" $type="heading1">
            My Bookmarks
          </Text>
        </Heading>
        {bookmarkedVideos.length === 0 ? (
          <Empty>
            <Text $type="heading2">Your bookmarks list is empty.</Text>
            <div>
              <Button small as={Link} to="/">
                Back to Homepage
              </Button>
            </div>
          </Empty>
        ) : (
          <>
            {state.isLoading ? (
              <Loader />
            ) : (
              <>
                <Grid>
                  {state.videos.map((video) => (
                    <VideoCard video={video} key={video.id} />
                  ))}
                </Grid>
                {!!state.links?.next && (
                  <div ref={ref}>{isInfiniteLoading && <Loader />}</div>
                )}
              </>
            )}
          </>
        )}
      </Wrap>
    </>
  );
};
