import { ComponentProps, FC, useRef } from "react";
import styled, { css } from "styled-components";

import { Card } from "@/components/common";
import useDetectOutsideClick from "@/hooks/useDetectOutsideClick";
import { ReactComponent as CloseIcon } from "@/assets/icons/close.svg";

const Wrap = styled(Card)`
  width: 600px;
  padding: 50px;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      padding: 30px 15px;
    }
  `}
`;

export const CloseButton = styled.button`
  position: absolute;
  right: 15px;
  top: 15px;
  display: inline-block;
  padding: 10px;
  svg {
    transition: 0.2s ease-out stroke;
    stroke: ${({ theme }) => theme.colors.gray};
  }
  &:hover {
    svg {
      stroke: ${({ theme }) => theme.colors.red};
    }
  }
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      top: 0;
      right: 0;
    }
  `}
`;

interface Props extends Pick<ComponentProps<"div">, "className"> {
  onClose: () => void;
}

export const Dialog: FC<Props> = ({ onClose, children, className }) => {
  const ref = useRef<HTMLDivElement>(null);

  useDetectOutsideClick(ref, onClose);

  return (
    <Wrap as="div" ref={ref} className={className}>
      <CloseButton onClick={onClose}>
        <CloseIcon />
      </CloseButton>
      {children}
    </Wrap>
  );
};
