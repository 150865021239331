import { FC, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { HelmetProvider } from "react-helmet-async";

import { Auth } from "@/pages/Auth";
import { Home } from "@/pages/Home";
import { GlobalStyles } from "@/utilities/theme";
import { Dispatch } from "@/utilities/store";
import { Video } from "@/pages/Video";
import { TextPage } from "@/pages/Text";
import { NotFound } from "@/pages/NotFound";
import { VideoUpload } from "@/pages/VideoUpload";
import { PrivateRoute } from "@/components/PrivateRoute";
import { Contributor } from "@/pages/Contributor/index";
import { AboutPage } from "@/pages/About";
import { SignUpForm } from "@/pages/Auth/components/SignUpForm";
import { SignInForm } from "@/pages/Auth/components/SignInForm";
import { Profile } from "@/pages/Profile";
import { Boomarks } from "@/pages/Bookmarks";
import { ForgotPassword } from "@/pages/Auth/components/ForgotPassword";
import { ResetPassword } from "@/pages/Auth/components/ResetPassword";
import { CompleteSignUp } from "@/pages/Auth/components/CompleteSignUp";
import { GuestRoute } from "@/components/GuestRoute";
import { Contributors } from "@/pages/Contributors";
import { Search } from "@/pages/Search";
import { AppLayout } from "./components/Layouts/AppLayout";
import { NotAllowed } from "@/pages/NotAllowed";
import { Tracker } from "@/components/Tracker";

const App: FC = () => {
  const dispatch = useDispatch<Dispatch>();
  const [userLoaded, setUserLoaded] = useState(false);

  useEffect(() => {
    dispatch.authentication.fetchCurrentUser().then(() => setUserLoaded(true));
  }, [dispatch.authentication]);

  if (!userLoaded) return null;

  return (
    <HelmetProvider>
      <BrowserRouter>
        <GlobalStyles />
        <Tracker>
          <Routes>
            <Route element={<AppLayout />}>
              <Route path="/" element={<Home />} />
              <Route path="/search" element={<Search />} />
              <Route path="/videos/:slug" element={<Video />} />
              <Route path="/pages/:slug" element={<TextPage />} />
              <Route path="/about-us" element={<AboutPage />} />
              <Route path="/contributors" element={<Contributors />} />
              <Route path="/contributors/:slug" element={<Contributor />} />
              <Route
                path="/profile"
                element={
                  <PrivateRoute forContributorsOnly>
                    <Profile />
                  </PrivateRoute>
                }
              />
              <Route
                path="/bookmarks"
                element={
                  <PrivateRoute>
                    <Boomarks />
                  </PrivateRoute>
                }
              />
              <Route path="/403" element={<NotAllowed />} />
              <Route path="/404" element={<NotFound />} />
            </Route>
            <Route element={<Auth />}>
              <Route
                path="login"
                element={
                  <GuestRoute>
                    <SignInForm />
                  </GuestRoute>
                }
              />
              <Route
                path="register"
                element={
                  <GuestRoute>
                    <SignUpForm />
                  </GuestRoute>
                }
              />
              <Route
                path="forgot-password"
                element={
                  <GuestRoute>
                    <ForgotPassword />
                  </GuestRoute>
                }
              />
              <Route
                path="reset-password"
                element={
                  <GuestRoute>
                    <ResetPassword />
                  </GuestRoute>
                }
              />
              <Route
                path="complete-signup"
                element={
                  <PrivateRoute forContributorsOnly>
                    <CompleteSignUp />
                  </PrivateRoute>
                }
              />
            </Route>
            <Route
              path="/upload"
              element={
                <PrivateRoute forContributorsOnly>
                  <VideoUpload />
                </PrivateRoute>
              }
            />
            <Route
              path="/videos/:slug/comment"
              element={
                <PrivateRoute forContributorsOnly>
                  <VideoUpload />
                </PrivateRoute>
              }
            />
            <Route path="*" element={<Navigate to="/404" replace />} />
          </Routes>
        </Tracker>
      </BrowserRouter>
    </HelmetProvider>
  );
};

export default App;
