import { FC, useState, useEffect } from "react";
import styled from "styled-components";

import { Input } from "@/components/common";
import { CategoryResource } from "@/types/api";
import { api } from "@/utilities/api";
import { Dropdown, DropdownItem } from "@/components/Dropdown";

const Wrap = styled.div`
  position: relative;
`;

const StyledInput = styled(Input)`
  cursor: pointer;
`;

interface Props {
  setCategory: (categoryId: number) => void;
  hasError: boolean;
}

export const CategoryDropdown: FC<Props> = ({ setCategory, hasError }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [categories, setCategories] = useState<CategoryResource[]>([]);
  const [value, setValue] = useState<CategoryResource | null>(null);

  useEffect(() => {
    (async () => {
      const categories = await api.get<never, CategoryResource[]>(
        "/v1/categories"
      );
      setCategories(categories);
    })();
  }, []);

  const onSelect = (category: CategoryResource) => {
    setValue(category);
    setCategory(category.id);
    setShowDropdown(false);
  };

  return (
    <Wrap>
      <StyledInput
        readOnly
        value={value?.name || ""}
        placeholder="Choose category"
        onClick={() => setShowDropdown(true)}
        $hasError={hasError}
      />
      {showDropdown && (
        <Dropdown onClose={() => setShowDropdown(false)} select>
          {categories.map((category) => (
            <DropdownItem
              select
              key={category.id}
              active={category.id === value?.id}
              onClick={() => onSelect(category)}
            >
              {category.name}
            </DropdownItem>
          ))}
        </Dropdown>
      )}
    </Wrap>
  );
};
