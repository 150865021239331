import { FC, memo } from "react";
import styled, { css } from "styled-components";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";

import { Card, Text } from "@/components/common";
import { VideoResource } from "@/types/api";
import { ResponsiveImage } from "@/components/ResponsiveImage";
import { Duration, PlayButton } from "@/components/Video";
import useLikes from "@/hooks/useLikes";
import { ReactComponent as LikeIcon } from "@/assets/icons/like.svg";

const Thumbnail = styled.div`
  width: 100%;
  height: 190px;
  position: relative;
  > img {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 0;
    left: 0;
    position: absolute;
  }
  &:hover {
    --play-btn-opacity: 1;
  }
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      height: 195px;
    }
  `};
`;

const Badge = styled(Text).attrs({
  $type: "labelBold",
  as: "div",
  $color: "white",
})`
  background: ${({ theme }) => theme.colors.red};
  height: 22px;
  line-height: 0;
  left: 0;
  top: 10px;
  padding: 0 14px;
  display: inline-flex;
  align-items: center;
  border-radius: 0px 11px 11px 0px;
  text-transform: uppercase;
  position: absolute;
  z-index: 2;
`;

const Content = styled.div`
  background: ${({ theme }) => theme.colors.white};
  padding: 20px 25px;
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 0 0 10px 10px;
`;

const Header = styled.div`
  display: flex;
  margin-bottom: 13px;
  align-items: flex-start;
`;

const Footer = styled.div`
  margin-top: auto;
  justify-content: space-between;
  align-items: center;
  display: flex;
`;

const Likes = styled.div`
  margin-left: 30px;
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      margin-left: 20px;
    }
  `}
`;

const StyledLikeIcon = styled(LikeIcon)<{ $filled: boolean }>`
  fill: transparent;
  stroke: ${({ theme }) => theme.colors.black};
  ${({ theme, $filled }) =>
    $filled &&
    css`
      fill: ${theme.colors.black};
    `}
`;

const LikeButton = styled.button`
  margin-left: 14px;
`;

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
`;

const Heading = styled(Text).attrs({ $type: "heading2", as: "h3" })`
  flex: 1;
  transition: 0.2s ease-out opacity;
  &:hover {
    opacity: 0.75;
  }
`;

const Date = styled(Text)`
  margin-left: 15px;
`;

interface Props {
  video: VideoResource;
  onVideoLikesChange: (increased: boolean, videoId: number) => void;
}

export const CommentCard: FC<Props> = memo(({ video, onVideoLikesChange }) => {
  const { isLiked, toggleLike, isLikeLoading } = useLikes(
    video.id,
    (increased) => onVideoLikesChange(increased, video.id)
  );

  return (
    <StyledCard>
      <Thumbnail>
        {!!video.reaction && <Badge>{video.reaction}</Badge>}
        <ResponsiveImage
          src={video.thumbnail!.media?.url || video.thumbnail!.default}
          srcSet={video.thumbnail!.media?.srcSet}
          alt={video.name}
        />
        <PlayButton to={`/videos/${video.slug}`} />
        <Duration>{video.meta!.duration}</Duration>
      </Thumbnail>
      <Content>
        <Header>
          <Heading>
            <Link to={`/videos/${video.slug}`}>{video.name}</Link>
          </Heading>
          <Likes>
            <Text $type="labelSemiBold" as="span">
              {video.likes_count}
            </Text>
            <LikeButton onClick={toggleLike} disabled={isLikeLoading}>
              <StyledLikeIcon $filled={isLiked} />
            </LikeButton>
          </Likes>
        </Header>
        <Footer>
          <Text $type="label">{video.contributor?.name}</Text>
          <Date $type="label">
            {DateTime.fromISO(video.published_at).toRelativeCalendar()}
          </Date>
        </Footer>
      </Content>
    </StyledCard>
  );
});
