import { FC } from "react";
import styled from "styled-components";

import { Button, Text } from "@/components/common";
import { ReactComponent as SpinnerIcon } from "@/assets/icons/process-spinner.svg";

const Circle = styled.div`
  position: relative;
`;

const Progress = styled(Text)`
  font-family: ${({ theme }) => theme.typography.heading1.fontFamily};
  font-size: 38px;
  font-weight: 700;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 120px;
  height: 33px;
  text-align: center;
  color: ${({ theme }) => theme.colors.black};
`;

const CircleSvg = styled.svg`
  transition: stroke-dasharray 0.15s linear;
`;

const Spinner = styled(SpinnerIcon)`
  display: block;
  shape-rendering: auto;
  margin-bottom: 40px;
`;

const Wrapper = styled.div`
  margin: 40px 0 52px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const UploadLabel = styled(Text)`
  margin: 20px 0;
`;

interface Props {
  percentage: number;
  isProccessing: boolean;
  isUploadingToBucket: boolean;
  isUploading: boolean;
  onCancel: () => void;
}

export const UploadProgress: FC<Props> = ({
  percentage,
  onCancel,
  isProccessing,
  isUploadingToBucket,
  isUploading,
}) => {
  const circumference = 92 * Math.PI * 2;
  const dash = (percentage * circumference) / 100;

  return (
    <>
      {!isUploading ? (
        <Wrapper>
          <Spinner />
          {isUploadingToBucket && (
            <Text $type="textPoppinsSemiBold">Uploading to our servers...</Text>
          )}
          {isProccessing && (
            <Text $type="textPoppinsSemiBold">
              Processing uploaded video...
            </Text>
          )}
        </Wrapper>
      ) : (
        <>
          <Circle>
            <CircleSvg viewBox="0 0 192 192" width="192" height="192">
              <circle
                cx="96"
                cy="96"
                r="92"
                fill="none"
                stroke="#dfdfe2"
                strokeWidth="8px"
              />
              <circle
                cx="96"
                cy="96"
                r="92"
                fill="none"
                stroke="#ff1919"
                strokeWidth="8px"
                transform="rotate(-90 96 96)"
                strokeDasharray={`${dash},${circumference - dash}`}
              />
            </CircleSvg>
            <Progress as="span">{`${percentage}%`}</Progress>
          </Circle>
          <UploadLabel $type="textPoppinsSemiBold">
            Uploading file to our video platform...
          </UploadLabel>
        </>
      )}
      <Button color="gray" onClick={onCancel}>
        Cancel
      </Button>
    </>
  );
};
