import { MediaResource } from "@/types/api";
import { useState } from "react";

interface State {
  src: string;
  srcSet?: string;
}

const useAnimatedThumbnail = (
  cloudflareVideoId: string,
  media?: MediaResource
): [State, () => void, () => void] => {
  const [state, setState] = useState<State>({
    src:
      media?.url ||
      `https://videodelivery.net/${cloudflareVideoId}/thumbnails/thumbnail.jpg`,
    srcSet: media?.srcSet,
  });

  const onMouseEnter = () => {
    setState({
      src: `https://videodelivery.net/${cloudflareVideoId}/thumbnails/thumbnail.gif?duration=4s`,
    });
  };

  const onMouseLeave = () => {
    setState({
      src:
        media?.url ||
        `https://videodelivery.net/${cloudflareVideoId}/thumbnails/thumbnail.jpg`,
      srcSet: media?.srcSet,
    });
  };

  return [state, onMouseEnter, onMouseLeave];
};

export default useAnimatedThumbnail;
