import { FC } from "react";
import styled, { css } from "styled-components";

import { ContributorResource } from "@/types/api";
import { Text } from "@/components/common";
import { ContributorCard } from "@/pages/Contributors/components/ContributorCard";

const Wrap = styled.section`
  margin: 0 0 36px;
`;

const Headline = styled(Text)`
  margin: 0 0 36px;
  display: flex;
  align-items: baseline;
`;

const Border = styled.div`
  flex: 1;
  margin-left: 30px;
  height: 2px;
  background: ${({ theme }) => theme.colors.white};
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  ${({ theme }) => css`
    ${theme.breakpoints.mediumDesktop} {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    ${theme.breakpoints.tablet} {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    ${theme.breakpoints.phone} {
      grid-template-columns: 100%;
    }
  `}
`;

interface Props {
  letter: string;
  contributors: ContributorResource[];
}

export const Section: FC<Props> = ({ letter, contributors }) => {
  return (
    <Wrap>
      <Headline as="h2" $type="heading1" $color="gray">
        {letter}
        <Border />
      </Headline>
      <Grid>
        {contributors.map((contributor) => (
          <ContributorCard key={contributor.id} contributor={contributor} />
        ))}
      </Grid>
    </Wrap>
  );
};
