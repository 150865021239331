import { FC, useState } from "react";
import styled, { css } from "styled-components";

import { ContributorResource } from "@/types/api";
import { Card, Text, Button } from "@/components/common";
import { ReactComponent as Checkmark } from "@/assets/icons/checkmark.svg";
import { SocialsIcon } from "@/components/SocialsIcon";
import { numberWithCommas } from "@/utilities/helpers";
import useFollow from "@/hooks/useFollow";
import CoverPlaceholderSrc from "@/assets/img/cover-placeholder.svg";
import { ContributorAvatar } from "@/pages/Home/components/ContributorAvatar";
import { ContributorSupportDialog } from "@/components/ContributorSupportDialog";
import { useSelector } from "react-redux";
import { RootState } from "@/utilities/store";
import { Link } from "react-router-dom";

const Cover = styled.div`
  border-radius: 10px 10px 0 0;
  line-height: 0;
  position: relative;
`;

const CoverImg = styled.img`
  border-radius: 10px 10px 0 0;
  height: 290px;
  width: 100%;
  object-fit: cover;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      height: 190px;
    }
  `}
`;

const Content = styled.article`
  background: ${({ theme }) => theme.colors.white};
  padding: 0 30px 42px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0 0 10px 10px;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      padding: 0 15px 37px;
    }
  `}
`;

const Avatar = styled(ContributorAvatar)`
  margin: -148px 0 24px;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      margin: -153px 0 19px;
    }
  `}
`;

const Location = styled(Text).attrs({
  $type: "labelBold",
  $color: "white",
  as: "div",
})`
  background: ${({ theme }) => theme.colors.red};
  padding: 0 12px;
  height: 26px;
  display: inline-flex;
  align-items: center;
  position: absolute;
  bottom: -12px;
  left: calc(50% + 118px);
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      left: auto;
      right: 0;
      bottom: auto;
      top: 0;
    }
  `}
`;

const Headline = styled(Text).attrs({
  $type: "heading1",
  as: "h1",
})`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 20px;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      margin: 0 0 10px;
    }
  `}
`;

const StyledCheckmark = styled(Checkmark)`
  fill: ${({ theme }) => theme.colors.red};
  stroke: ${({ theme }) => theme.colors.white};
  margin-right: 12px;
`;

const Stats = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 0 20px;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      margin: 0 -20px 10px;
    }
  `}
`;

const TopicsLabel = styled(Text)`
  margin-right: 6px;
`;

const Buttons = styled.div`
  display: grid;
  justify-content: center;
  grid-column-gap: 30px;
  grid-template-columns: 104px 104px;
  margin: 27px 0 44px;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      margin: 15px 0 19px;
    }
  `}
`;

const StatsBlock = styled.div<{ $bordered?: boolean }>`
  padding: 0 20px;
  text-align: center;
  ${({ theme, $bordered }) =>
    $bordered &&
    css`
      position: relative;
      &::before,
      &::after {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 1px;
        height: 70%;
        margin: auto 0;
        background: ${theme.colors.gray};
        content: "";
      }
      &::before {
        left: 0;
      }
      &::after {
        right: 0;
      }
    `}
`;

const Description = styled(Text)`
  width: 690px;
  max-width: 100%;
  margin: 0 0 32px;
  text-align: center;
`;

const SocialMedias = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SocialLink = styled("a").attrs({
  target: "_blank",
  rel: "noreferrer noopener",
})`
  margin: 0 15px;
  line-height: 0;
  padding: 5px;
  &:hover {
    transition: 0.2s ease-out color;
    color: ${({ theme }) => theme.colors.red};
  }
`;

const EditProfileButton = styled(Button)`
  margin: 27px auto 44px;
  width: 120px;
`;

interface Props {
  contributor: ContributorResource;
}

export const ContributorCard: FC<Props> = ({ contributor }) => {
  const { isFollowed, toggleFollow, isFollowLoading } = useFollow(
    contributor.id
  );

  const isMyProfile = useSelector(
    (state: RootState) =>
      state.authentication.user?.contributor?.slug === contributor.slug
  );

  const [showSupportDialog, setShowSupportDialog] = useState(false);

  return (
    <Card>
      {showSupportDialog && (
        <ContributorSupportDialog
          contributorId={contributor.id}
          contributorName={contributor.name!}
          onClose={() => setShowSupportDialog(false)}
        />
      )}
      <Cover>
        <CoverImg
          src={contributor.cover?.url || CoverPlaceholderSrc}
          srcSet={contributor.cover?.srcSet}
        />
        {!!contributor.location && <Location>{contributor.location}</Location>}
      </Cover>
      <Content>
        <Avatar
          avatar={contributor.avatar}
          size="large"
          name={contributor.name!}
        />
        <Headline>
          {contributor.verified && <StyledCheckmark />}
          {contributor.name}
        </Headline>
        <Stats>
          <StatsBlock>
            <Text as="span" $type="labelBold">
              {numberWithCommas(contributor.followers_count!)}
            </Text>{" "}
            <Text as="span" $type="label">
              Followers
            </Text>
          </StatsBlock>
          <StatsBlock $bordered>
            <Text as="span" $type="labelBold">
              {contributor.videos_count}
            </Text>{" "}
            <Text as="span" $type="label">
              Uploads
            </Text>
          </StatsBlock>
          <StatsBlock>
            <Text as="span" $type="labelBold">
              {numberWithCommas(contributor.videos_views || 0)}
            </Text>{" "}
            <Text as="span" $type="label">
              Views
            </Text>
          </StatsBlock>
        </Stats>
        {!!contributor.topics && (
          <Text $type="label">
            <TopicsLabel $type="labelBold" as="strong">
              Topics
            </TopicsLabel>
            {contributor.topics}
          </Text>
        )}
        {isMyProfile ? (
          <EditProfileButton small forwardedAs={Link} to="/profile">
            Edit Profile
          </EditProfileButton>
        ) : (
          <Buttons>
            <Button
              small
              onClick={toggleFollow}
              loading={isFollowLoading}
              disabled={isFollowLoading}
            >
              {isFollowed ? "Unfollow" : "Follow"}
            </Button>
            <Button small onClick={() => setShowSupportDialog(true)}>
              Support
            </Button>
          </Buttons>
        )}
        <Description>{contributor.description}</Description>
        {!!contributor.social_medias!.length && (
          <SocialMedias>
            {contributor.social_medias!.map((media, index) => (
              <SocialLink href={media.url} key={index}>
                <SocialsIcon type={media.type} />
              </SocialLink>
            ))}
          </SocialMedias>
        )}
      </Content>
    </Card>
  );
};
