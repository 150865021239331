import { FC } from "react";
import styled, { css } from "styled-components";

import { VideoResource } from "@/types/api";
import { Card } from "@/components/common";
import { VideoCardContent } from "@/pages/Video/components/VideoCardContent";
import { CommentContent } from "@/pages/Video/components/CommentContent";
import { VideoPlayer } from "@/pages/Video/components/VideoPlayer";

const StyledCard = styled(Card)`
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      box-shadow: none;
      border-radius: 0;
      margin: 0 -15px;
    }
  `}
`;

interface Props {
  video: VideoResource;
}

export const VideoCard: FC<Props> = ({ video }) => {
  return (
    <StyledCard>
      <VideoPlayer video={video} />
      {!!video.parent ? (
        <CommentContent video={video} />
      ) : (
        <VideoCardContent video={video} />
      )}
    </StyledCard>
  );
};
