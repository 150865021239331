import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import styled from "styled-components";

import { Text } from "@/components/common";
import { ReactComponent as VideoIcon } from "@/assets/icons/video.svg";
import { ContributorResource, PaginationLinks } from "@/types/api";
import { PaginatedResponse } from "@/types";
import { api } from "@/utilities/api";
import { errorNotification } from "@/utilities/alerts";
import useInfiniteLoader from "@/hooks/useInfiniteLoader";
import { Loader } from "@/components/common/Loader";
import { Section } from "@/pages/Contributors/components/Section";

const Wrap = styled.form`
  padding: 0 0 120px;
`;

const Heading = styled.h1`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0 25px;
`;

const StyledVideoIcon = styled(VideoIcon)`
  color: ${({ theme }) => theme.colors.red};
  margin-right: 19px;
`;

interface State {
  contributors: ContributorResource[];
  links?: PaginationLinks;
  isLoading: boolean;
}

export const Contributors: FC = () => {
  const [state, setState] = useState<State>({
    contributors: [],
    isLoading: false,
  });

  const fetchPaginatedContributors = useCallback(
    (link = "/v1/contributors") => {
      return api.get<never, PaginatedResponse<ContributorResource[]>>(link, {
        params: {
          include: ["media", "user", "followers_count"].join(","),
          sort: "first_name,-followers_count",
          per_page: 12,
        },
      });
    },
    []
  );

  useEffect(() => {
    (async () => {
      try {
        setState((prevState) => ({ ...prevState, isLoading: true }));
        const { data, links } = await fetchPaginatedContributors();
        setState({
          contributors: data,
          isLoading: false,
          links,
        });
      } catch (err) {
        errorNotification();
      }
    })();
  }, [fetchPaginatedContributors]);

  const loader = useCallback(async () => {
    if (!state.links?.next) return;
    const { data: contributors, links } = await fetchPaginatedContributors(
      state.links.next
    );
    setState((prevState) => ({
      contributors: [...prevState.contributors, ...contributors],
      isLoading: false,
      links,
    }));
  }, [state.links?.next, fetchPaginatedContributors]);

  const { isLoading: isInfiniteLoading, ref } = useInfiniteLoader(loader);

  const sections = useMemo(() => {
    return state.contributors.reduce<{ [k: string]: ContributorResource[] }>(
      (acc, curr) => {
        const firstLetter = (curr.name?.[0] || "Z").toUpperCase();
        if (acc[firstLetter]) {
          acc[firstLetter].push(curr);
        } else {
          acc[firstLetter] = [curr];
        }
        return acc;
      },
      {}
    );
  }, [state.contributors]);

  return (
    <>
      <Helmet>
        <title>Contributors | MyPoint.tv</title>
      </Helmet>
      <Wrap>
        <Heading>
          <StyledVideoIcon />
          <Text as="span" $type="heading1">
            Contributors
          </Text>
        </Heading>
        {state.isLoading ? (
          <Loader />
        ) : (
          <>
            {Object.keys(sections).map((letter) => (
              <Section
                key={letter}
                letter={letter}
                contributors={sections[letter]}
              />
            ))}
            {!!state.links?.next && (
              <div ref={ref}>{isInfiniteLoading && <Loader />}</div>
            )}
          </>
        )}
      </Wrap>
    </>
  );
};
