import { FC, useEffect, useCallback, useState, FormEvent } from "react";
import styled, { css } from "styled-components";
import { useForm, useWatch } from "react-hook-form";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import {
  Card,
  Label,
  Textarea,
  ValidationError,
  Button,
  Text,
  Row,
  Column,
  SrOnly,
} from "@/components/common";
import { ReactComponent as ImageIcon } from "@/assets/icons/image.svg";
import { FormGroup as InputFormGroup } from "@/components/common/FormGroup";
import { TagsInput } from "@/pages/VideoUpload/components/TagsInput";
import { LocationInput } from "@/pages/VideoUpload/components/LocationInput";
import { VideoResource } from "@/types/api";
import { api } from "@/utilities/api";
import { handleFormErrors } from "@/utilities/helpers";
import { Breakpoints } from "@/utilities/theme";
import { CategoryDropdown } from "@/pages/VideoUpload/components/CategoryDropdown";
import { ReactionDropdown } from "@/pages/VideoUpload/components/ReactionDropdown";
import { fbq } from "@/utilities/tracking";

const StyledCard = styled(Card)`
  padding: 24px 48px 47px;
  width: 570px;
  max-width: calc(100% - 30px);
  margin: 0 auto 50px;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      margin: 0 auto 40px;
      padding: 25px 15px 40px;
    }
  `}
`;

const FormGroup = styled.div`
  margin: 0 0 15px;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 40px;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      justify-content: space-evenly;
    }
  `}
`;

const Cancel = styled(Text)`
  margin-right: 30px;
  transition: opacity 0.2s ease-out;
  text-transform: uppercase;
  &:hover {
    opacity: 0.75;
  }
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      margin: 0;
    }
  `}
`;

const ThumbnailLabel = styled.label`
  display: flex;
  height: 125px;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.bg};
  border: 4px dashed #ffffff;
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    border-color: #c7c7c7;
    border-width: 2px;
    ${Text} {
      font-weight: 700;
    }
  }
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      height: 94px;
    }
  `}

  small {
    font-size: 0.8em;
  }
`;

const StyledImageIcon = styled(ImageIcon)`
  margin-bottom: 12px;
  stroke: #c7c7c7;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      width: 28px;
      height: 28px;
      margin-bottom: 4px;
    }
  `}
`;

const ThumbnailPreview = styled.div`
  position: relative;
  line-height: 0;
  &::before {
    position: absolute;
    content: "";
    border: 4px dashed #ffffff;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
`;

const Thumbnail = styled.img`
  height: 125px;
  border-radius: 10px;
  width: 100%;
  object-fit: cover;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      height: 94px;
    }
  `}
`;

interface FormData {
  name: string;
  description: string;
  city_name: string;
  state: string;
  tags: string[];
  category_id: number | null;
  thumbnail?: FileList;
  reaction?: VideoResource["reaction"];
}

interface Props {
  cloudflareId: string;
  originalVideoFilename: string;
  onSuccessfulSubmit: () => void;
  parentSlug?: string;
}

export const UploadForm: FC<Props> = ({
  cloudflareId,
  originalVideoFilename,
  onSuccessfulSubmit,
  parentSlug,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const isMobile = useMediaQuery({
    maxWidth: Breakpoints.max.phone,
  });

  const {
    register,
    control,
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      name: "",
      description: "",
      city_name: "",
      state: "",
      category_id: null,
      tags: [],
    },
  });

  useEffect(() => {
    register("tags");
  }, [register]);

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    clearErrors();
    handleSubmit(async (data) => {
      try {
        setIsLoading(true);
        const formData = new FormData();
        formData.append("name", data.name);
        formData.append("description", data.description);
        formData.append("city_name", data.city_name);
        formData.append("state", data.state);
        data.tags.forEach((tag) => formData.append("tags[]", tag));
        if (data.category_id) {
          formData.append("category_id", data.category_id.toString());
        }
        if (data.reaction) {
          formData.append("reaction", data.reaction);
        }
        formData.append("cloudflare_id", cloudflareId);
        formData.append("original_video_filename", originalVideoFilename);
        if (parentSlug) {
          formData.append("parent_slug", parentSlug);
        }
        if (data.thumbnail?.[0]) {
          formData.append("thumbnail", data.thumbnail[0]);
        }
        const video = await api.post<typeof formData, VideoResource>(
          "/v1/videos",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        fbq(
          "track",
          "Lead",
          {
            content_name: parentSlug ? "Video comment upload" : "Video upload",
          },
          { eventID: `VIDEO_UPLOAD_${video.id}` }
        );
        onSuccessfulSubmit();
      } catch (error) {
        setIsLoading(false);
        handleFormErrors<FormData>(error, setError);
      }
    })(e);
  };

  const thumbnail = useWatch({ control, name: "thumbnail" });

  const tags = useWatch({ control, name: "tags" });

  const setTags = useCallback(
    (tags: string[]) => setValue("tags", tags, { shouldDirty: true }),
    [setValue]
  );

  const setCategory = useCallback(
    (categoryId: number) =>
      setValue("category_id", categoryId, { shouldDirty: true }),
    [setValue]
  );

  const setReaction = useCallback(
    (reaction: VideoResource["reaction"]) =>
      setValue("reaction", reaction, { shouldDirty: true }),
    [setValue]
  );

  const setLocation = useCallback(
    (city: string, state: string) => {
      setValue("city_name", city, { shouldDirty: true });
      setValue("state", state, { shouldDirty: true });
    },
    [setValue]
  );

  const thumbnailPreviewUrl = thumbnail?.[0]
    ? URL.createObjectURL(thumbnail[0])
    : `https://videodelivery.net/${cloudflareId}/thumbnails/thumbnail.jpg`;

  return (
    <StyledCard as="form" onSubmit={onSubmit}>
      <FormGroup>
        <Label as="div">Choose a custom thumbnail or leave the default</Label>
        <Row $gutter={isMobile ? 7 : 19}>
          <Column $cols={6} $gutter={isMobile ? 7 : 19}>
            <ThumbnailLabel htmlFor="thumbnailInput">
              <StyledImageIcon />
              <Text $type="labelNormal" as="span">
                Upload Thumbnail
                <br />
                <small>JPG, PNG or GIF</small>
              </Text>
            </ThumbnailLabel>
            <SrOnly
              id="thumbnailInput"
              as="input"
              type="file"
              accept="image/gif, image/jpeg, image/jpg, image/png"
              {...register("thumbnail")}
            />
          </Column>
          <Column $cols={6} $gutter={isMobile ? 7 : 19}>
            <ThumbnailPreview>
              <Thumbnail src={thumbnailPreviewUrl} />
            </ThumbnailPreview>
          </Column>
        </Row>
        {errors.thumbnail && (
          <ValidationError>{errors.thumbnail.message}</ValidationError>
        )}
      </FormGroup>
      {!!parentSlug && (
        <FormGroup>
          <ReactionDropdown error={errors.reaction} setReaction={setReaction} />
        </FormGroup>
      )}
      <FormGroup>
        <InputFormGroup
          label="Title"
          type="text"
          required
          error={errors.name}
          {...register("name")}
        />
      </FormGroup>
      {!!!parentSlug && (
        <FormGroup>
          <Label>Description</Label>
          <Textarea
            required
            $hasError={!!errors.description}
            {...register("description")}
          />
          {errors.description && (
            <ValidationError>{errors.description.message}</ValidationError>
          )}
        </FormGroup>
      )}
      {!!!parentSlug && (
        <FormGroup>
          <Label>Category</Label>
          <CategoryDropdown
            setCategory={setCategory}
            hasError={!!errors.category_id}
          />
          {errors.category_id && (
            <ValidationError>{errors.category_id.message}</ValidationError>
          )}
        </FormGroup>
      )}
      <FormGroup>
        <Label>Location</Label>
        <LocationInput
          setLocation={setLocation}
          hasError={!!(errors.state || errors.city_name)}
        />
        {(errors.state || errors.city_name) && (
          <ValidationError>
            {errors.state?.message || errors.city_name?.message}
          </ValidationError>
        )}
      </FormGroup>
      {!!!parentSlug && (
        <FormGroup>
          <Label>Tags</Label>
          <TagsInput tags={tags!} setTags={setTags} />
        </FormGroup>
      )}
      <Footer>
        <Cancel as={Link} to="/" $type="heading2">
          Cancel
        </Cancel>
        <Button type="submit" loading={isLoading} disabled={isLoading}>
          Submit
        </Button>
      </Footer>
    </StyledCard>
  );
};
