import { FC, memo, useState } from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

import { Button, Card, Text } from "@/components/common";
import { ContributorResource } from "@/types/api";
import {
  Checkmark as AvatarCheckmark,
  ContributorAvatar,
} from "@/pages/Home/components/ContributorAvatar";
import { numberWithCommas } from "@/utilities/helpers";
import useFollow from "@/hooks/useFollow";
import { ResponsiveImage } from "@/components/ResponsiveImage";
import CoverPlaceholderSrc from "@/assets/img/cover-placeholder-small.jpg";

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const Avatar = styled(ContributorAvatar)`
  width: 94px;
  height: 94px;
  margin: -84px auto 6px;
  ${AvatarCheckmark} {
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    > svg {
      position: static;
      height: 9px;
      width: auto;
    }
  }
  ${Text} {
    font-size: 18px;
  }
`;

const Name = styled(Text)`
  text-transform: capitalize;
  margin-bottom: 4px;
`;

const Followers = styled(Text)`
  margin: 0 0 13px;
`;

const Description = styled.p`
  margin: 0 0 19px;
`;

const Buttons = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: center;
`;

const FollowButton = styled(Button)<{ $followed: boolean }>`
  padding: 0 22px;
  margin-right: 20px;
  ${({ $followed, theme }) =>
    $followed &&
    css`
      background: ${theme.colors.white};
      color: ${theme.colors.gray};
      box-shadow: 0px 0px 15px rgba(32, 37, 44, 0.1);
      &:hover {
        color: ${theme.colors.gray};
        opacity: 0.75;
      }
    `}
`;

const ProfileLink = styled(Button)`
  padding: 0 22px;
  ${({ theme }) => css`
    background: ${theme.colors.lightGray};
    color: ${theme.colors.black};
    &:hover {
      background: ${theme.colors.gray};
      color: ${theme.colors.white};
    }
  `}
`;

const Cover = styled(ResponsiveImage)`
  width: 100%;
  height: 106px;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
`;

const Content = styled.div`
  padding: 0 30px 24px;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

interface Props {
  contributor: ContributorResource;
}

export const ContributorCard: FC<Props> = memo(({ contributor }) => {
  const [followersCount, setFollowersCount] = useState(
    contributor.followers_count!
  );

  const { isFollowed, toggleFollow, isFollowLoading } = useFollow(
    contributor.id,
    (increment) => {
      setFollowersCount((prevCount) =>
        increment ? prevCount + 1 : prevCount - 1
      );
    }
  );

  return (
    <StyledCard>
      <Cover
        src={contributor.cover?.url || CoverPlaceholderSrc}
        srcSet={contributor.cover?.srcSet}
        alt={`${contributor.name} profile cover image`}
      />
      <Avatar
        name={contributor.name!}
        avatar={
          contributor.avatar_thumbnail
            ? { url: contributor.avatar_thumbnail }
            : undefined
        }
        verified={contributor.verified}
      />
      <Content>
        <Name $type="heading2">{contributor.name}</Name>
        <Followers $type="label">
          {numberWithCommas(followersCount)} followers
        </Followers>
        {!!contributor.topics && (
          <Description>
            <Text as="span" $type="labelSemiBold">
              Reporting on:{" "}
            </Text>
            <Text as="span" $type="label">
              {contributor.topics}
            </Text>
          </Description>
        )}
        <Buttons>
          <FollowButton
            small
            onClick={toggleFollow}
            loading={isFollowLoading}
            disabled={isFollowLoading}
            $followed={isFollowed}
          >
            {isFollowed ? "Followed" : "Follow"}
          </FollowButton>
          <ProfileLink
            small
            forwardedAs={Link}
            to={`/contributors/${contributor.slug}`}
          >
            See Profile
          </ProfileLink>
        </Buttons>
      </Content>
    </StyledCard>
  );
});
