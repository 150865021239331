import { FC } from "react";
import styled, { css } from "styled-components";
import { useNavigate } from "react-router-dom";

import { ResponsiveImage } from "@/components/ResponsiveImage";
import { Duration, PlayButton } from "@/components/Video";
import { VideoResource } from "@/types/api";
import useAnimatedThumbnail from "@/hooks/useAnimatedThumbnail";

const Thumbnail = styled.div<{ $large: boolean }>`
  width: 100%;
  height: 187px;
  position: relative;
  > img {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 0;
    left: 0;
    position: absolute;
  }
  &:hover {
    --play-btn-opacity: 1;
  }
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      height: 195px;
    }
  `};
  ${({ theme, $large }) =>
    $large &&
    css`
      width: 690px;
      min-height: 388px;
      height: 100%;
      > img {
        border-radius: 0;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
      ${theme.breakpoints.mediumDesktop} {
        width: 100%;
        height: 300px;
        min-height: 0;
        > img {
          border-radius: 0;
          border-top-right-radius: 10px;
          border-top-left-radius: 10px;
        }
      }
    `};
`;

interface Props {
  video: VideoResource;
  large: boolean;
}

export const VideoThumbnail: FC<Props> = ({ video, large }) => {
  const [state, onMouseEnter, onMouseLeave] = useAnimatedThumbnail(
    video.cloudflare_id,
    video.thumbnail!.media
  );

  const navigate = useNavigate();

  return (
    <Thumbnail
      $large={large}
      onMouseEnter={onMouseEnter}
      onTouchStart={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onTouchEnd={onMouseLeave}
    >
      <ResponsiveImage
        onClick={() => navigate(`/videos/${video.slug}`)}
        src={state.src}
        srcSet={state.srcSet}
        alt={video.name}
      />
      <PlayButton to={`/videos/${video.slug}`} />
      <Duration>{video.meta!.duration}</Duration>
    </Thumbnail>
  );
};
