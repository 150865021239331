import { FC } from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

import { Button, Card, Text } from "@/components/common";

const StyledCard = styled(Card)`
  width: 570px;
  max-width: calc(100% - 30px);
  height: 438px;
  margin: auto;
  border: 1px dashed #707070;
  padding: 23px;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      padding: 15px;
      margin: 0 auto 40px;
    }
  `}
`;

const Inner = styled.div`
  height: 100%;
  border: 5px dashed #f8f8f8;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Heading = styled(Text)`
  margin: 0 0 17px;
  font-size: 38px;
  text-transform: uppercase;
  line-height: 1;
`;

const Description = styled(Text)`
  width: 330px;
  max-width: 100%;
  letter-spacing: 0.38px;
  text-align: center;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      width: 270px;
    }
  `}
`;

const StyledButton = styled(Button)`
  margin-top: 30px;
`;

export const SuccessCard: FC = () => {
  return (
    <StyledCard>
      <Inner>
        <Heading as="h1" $type="heading1">
          Done!
        </Heading>
        <Description $type="textPoppinsLight">
          Your story is on its way to the world.
          <br />
          It will be published after a quick review by our team. While you wait,
          explore amazing videos on our platform!
        </Description>
        <StyledButton forwardedAs={Link} to="/">
          Explore videos
        </StyledButton>
      </Inner>
    </StyledCard>
  );
};
