import { FC, useCallback, useEffect, useState } from "react";

import { PaginatedResponse } from "@/types";
import { PaginationLinks, VideoResource } from "@/types/api";
import { api } from "@/utilities/api";
import { errorNotification } from "@/utilities/alerts";
import useInfiniteLoader from "@/hooks/useInfiniteLoader";
import { Loader } from "@/components/common";
import { ListableVideoCard } from "@/components/ListableVideoCard";

interface State {
  videos: VideoResource[];
  links?: PaginationLinks;
  isLoading: boolean;
}

const INITIAL_STATE = {
  videos: [],
  isLoading: true,
};

interface Props {
  video: VideoResource;
}

export const MobileTrendingVideos: FC<Props> = ({ video }) => {
  const [state, setState] = useState<State>(INITIAL_STATE);

  const fetchPaginatedVideos = useCallback(
    (link = "/v1/videos") => {
      const include = ["media", "contributor", "contributor.user", "meta"].join(
        ","
      );
      return api.get<never, PaginatedResponse<VideoResource[]>>(link, {
        params: {
          sort: ["-related_tags_count", "-published_at"].join(","),
          include,
          "filter[with_related_tags_count]": video.id,
          per_page: 8,
          "filter[category_id]": video.categories!.map((c) => c.id).join(","),
          "filter[parent]": 1,
          "filter[exclude]": video.id,
          "filter[visible]": 1,
        },
      });
    },
    [video]
  );

  useEffect(() => {
    (async () => {
      try {
        setState((prevState) => ({
          ...prevState,
          isLoading: true,
        }));
        const { data, links } = await fetchPaginatedVideos();
        setState({ videos: data, isLoading: false, links });
      } catch (err) {
        errorNotification();
      }
    })();
  }, [fetchPaginatedVideos]);

  const loader = useCallback(async () => {
    if (!state.links?.next) return;
    const { data: videos, links } = await fetchPaginatedVideos(
      state.links.next
    );
    setState((prevState) => ({
      videos: [...prevState.videos, ...videos],
      isLoading: false,
      links,
    }));
  }, [state.links?.next, fetchPaginatedVideos]);

  const { isLoading, ref } = useInfiniteLoader(loader, null);

  return (
    <div>
      {state.isLoading ? (
        <Loader />
      ) : (
        state.videos.map((video) => (
          <ListableVideoCard key={video.id} video={video} />
        ))
      )}
      {!!state.links?.next && <div ref={ref}>{isLoading && <Loader />}</div>}
    </div>
  );
};
