import { FC, useEffect, useState, memo, useCallback } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { Text } from "@/components/common";
import { ContributorRow } from "@/pages/Home/components/ContributorRow";
import { api } from "@/utilities/api";
import { ContributorResource } from "@/types/api";
import { PaginatedResponse } from "@/types";
import { errorNotification } from "@/utilities/alerts";

const ArchiveLink = styled(Text)`
  text-decoration: none;
  display: inline-block;
  margin-top: 14px;
  margin-left: 15px;
  transition: 0.2s ease-out color;
  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.red};
  }
`;

const Heading = styled(Text)`
  text-transform: uppercase;
  margin: 0 0 10px;
  padding: 0 10px;
  letter-spacing: 0.33px;
`;

export const Contributors: FC = memo(() => {
  const [contributors, setContributors] = useState<ContributorResource[]>([]);

  const onFollowersChange = useCallback(
    async (contributorSlug: string) => {
      try {
        const updatedContributor = await api.get<never, ContributorResource>(
          `/v1/contributors/${contributorSlug}`,
          {
            params: {
              include: ["media", "followers_count", "user"].join(","),
            },
          }
        );
        setContributors((prevContributors) =>
          prevContributors.map((contributor) =>
            contributor.id === updatedContributor.id
              ? updatedContributor
              : contributor
          )
        );
      } catch (err) {
        errorNotification();
      }
    },
    [setContributors]
  );

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get<
          never,
          PaginatedResponse<ContributorResource[]>
        >("/v1/contributors", {
          params: {
            per_page: 5,
            include: ["user", "media", "followers_count"].join(","),
            sort: "-followers_count",
          },
        });
        setContributors(response.data);
      } catch (err) {
        errorNotification();
      }
    })();
  }, []);

  return (
    <section>
      <Heading $color="gray" $type="labelBold" as="h4">
        Top 5 Contributors
      </Heading>
      {contributors.map((contributor) => (
        <ContributorRow
          key={contributor.id}
          contributor={contributor}
          onFollowersChange={onFollowersChange}
        />
      ))}
      <ArchiveLink as={Link} to="/contributors" $color="gray" $type="label">
        See More Contributors
      </ArchiveLink>
    </section>
  );
});
