import { FC, useEffect, memo } from "react";
import styled from "styled-components";

const Ins = styled.ins`
  display: block;
`;

interface Props {
  adSlot: string;
  className?: string;
}

const GoogleAd: FC<Props> = memo(({ adSlot, className = "" }) => {
  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT !== "production") return;
    const global = window as any;
    (global.adsbygoogle = global.adsbygoogle || []).push({});
  }, [adSlot]);

  if (process.env.REACT_APP_ENVIRONMENT !== "production") return null;

  return (
    <Ins
      className={`adsbygoogle ${className}`}
      data-ad-client={process.env.REACT_APP_GOOGLE_ADSENSE_CLIENT}
      data-ad-slot={adSlot}
      data-ad-format="auto"
      data-full-width-responsive="true"
    />
  );
});

export default GoogleAd;
