import { FC } from "react";
import { Outlet } from "react-router-dom";

import { Container } from "@/components/common";
import { Header } from "@/components/Header";
import { MobileNav } from "@/components/MobileNav";

export const AppLayout: FC = () => {
  return (
    <>
      <Header />
      <Container>
        <Outlet />
      </Container>
      <MobileNav />
    </>
  );
};
