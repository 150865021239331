import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import { RootState } from "@/utilities/store";

type GenericFunction = (...args: any[]) => any;

const useAuthGuard = <F extends GenericFunction>(protectedCallback: F) => {
  const navigate = useNavigate();
  const location = useLocation();

  const isAuthenticated = useSelector(
    (state: RootState) => !!state.authentication.user
  );

  const guardedFunction = (...args: Parameters<F>): ReturnType<F> | void => {
    if (!isAuthenticated)
      return navigate("/login", { state: { from: location } });
    return protectedCallback(...args);
  };

  return guardedFunction;
};

export default useAuthGuard;
