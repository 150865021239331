import { FC, SyntheticEvent, useState, ComponentProps } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

type Props = ComponentProps<typeof LazyLoadImage>;

export const ResponsiveImage: FC<Props> = ({
  src,
  srcSet,
  alt = "",
  ...props
}) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const onLoad = (e: SyntheticEvent<HTMLImageElement>) => {
    if (isLoaded) return;
    const target = e.target as HTMLImageElement;
    window.requestAnimationFrame(() => {
      const size = target.getBoundingClientRect().width;
      if (!size) return;
      target.sizes = Math.ceil((size / window.innerWidth) * 100) + "vw";
      setIsLoaded(true);
    });
  };

  return (
    <LazyLoadImage
      src={src}
      srcSet={srcSet}
      sizes="1px"
      alt={alt}
      onLoad={onLoad}
      {...props}
    />
  );
};
