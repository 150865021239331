import { useEffect, useState, useRef } from "react";

const useInfiniteLoader = <T extends HTMLElement = HTMLDivElement>(
  loader: () => Promise<any>,
  root?: Document | Element | null
) => {
  const [isLoading, setIsLoading] = useState(false);

  const ref = useRef<T | null>(null);

  useEffect(() => {
    const { current } = ref;

    if (!current) return;

    const handleObserver = async (entries: IntersectionObserverEntry[]) => {
      const target = entries[0];
      if (target.isIntersecting && !isLoading) {
        setIsLoading(true);
        await loader();
        setIsLoading(false);
      }
    };

    const observer = new IntersectionObserver(handleObserver, {
      root,
      rootMargin: "0px 0px 20px 0px",
      threshold: 1.0,
    });

    observer.observe(current);

    return () => observer.unobserve(current);
  }, [loader, root, isLoading]);

  return { ref, isLoading };
};

export default useInfiniteLoader;
