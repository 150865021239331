import { FC, useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import { Button, Loader, Text } from "@/components/common";
import { PaginationLinks, VideoResource } from "@/types/api";
import { ReactComponent as SortIcon } from "@/assets/icons/sort.svg";
import { ReactComponent as FilterIcon } from "@/assets/icons/filter.svg";
import { PaginatedResponse } from "@/types";
import { api } from "@/utilities/api";
import useInfiniteLoader from "@/hooks/useInfiniteLoader";
import { Dropdown, DropdownItem } from "@/components/Dropdown";
import { ReactComponent as Respond } from "@/assets/icons/respond.svg";
import { Link, useLocation } from "react-router-dom";
import { ListableVideoCard } from "@/components/ListableVideoCard";

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 20px;
`;

const SortButton = styled(Text).attrs({ as: "button" })`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  transition: 0.2s ease-out color;
  text-transform: uppercase;
  > svg {
    fill: transparent;
    margin-right: 10px;
  }
  &:hover {
    color: ${({ theme }) => theme.colors.black};
  }
`;

const SortWrap = styled.div`
  position: relative;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 15px;
`;

const ReactionsTypeDropdown = styled(Dropdown)`
  right: auto;
  left: 0;
`;

const Filter = styled(SortWrap)`
  margin-top: 2px;
`;

const RespondIcon = styled(Respond)`
  fill: transparent;
  stroke: #ffffff;
`;

const RespondCta = styled.div`
  text-align: center;
  margin: 0 0 20px;
`;

const VideoCard = styled(ListableVideoCard)`
  margin: 0;
`;

const StyledSortIcon = styled(SortIcon)<{ $red: boolean }>`
  width: 14px;
  margin-top: -2px;
  stroke: ${({ theme, $red }) => ($red ? theme.colors.red : theme.colors.gray)};
`;

const StyledFilterIcon = styled(FilterIcon)<{ $red: boolean }>`
  width: 18px;
  margin-top: -3px;
  stroke: ${({ theme, $red }) => ($red ? theme.colors.red : theme.colors.gray)};
`;

const StyledRespondIcon = styled(RespondIcon)`
  position: relative;
  top: -2px;
`;

type ReactionType = "all" | "supporting" | "neutral" | "opposing";

const reactionTypes: Array<ReactionType> = [
  "all",
  "supporting",
  "neutral",
  "opposing",
];

enum Sort {
  LATEST = "-published_at",
  OLDEST = "published_at",
  "MOST LIKED" = "-likes_count",
}

interface Props {
  video: VideoResource;
}

interface State {
  loaded: boolean;
  sort: Sort;
  activeType: ReactionType;
  links?: PaginationLinks;
  videos: VideoResource[];
  sortOpened: boolean;
  filterOpened: boolean;
}

export const MobileReactions: FC<Props> = ({ video }) => {
  const location = useLocation();

  const [state, setState] = useState<State>({
    loaded: false,
    sortOpened: false,
    filterOpened: false,
    sort: Sort.LATEST,
    activeType: "all",
    videos: [],
  });

  const fetchPaginatedVideos = useCallback(
    async (link = "/v1/videos") => {
      return api.get<never, PaginatedResponse<VideoResource[]>>(link, {
        params: {
          sort: state.sort,
          include: [
            ...(state.sort === Sort["MOST LIKED"] ? ["likes_count"] : []),
            "media",
            "contributor",
            "contributor.user",
            "meta",
          ].join(","),
          per_page: 8,
          "filter[parent_id]": video.parent?.id || video.id,
          "filter[reaction]": state.activeType,
          "filter[visible]": 1,
        },
      });
    },
    [video, state.sort, state.activeType]
  );

  useEffect(() => {
    (async () => {
      const response = await fetchPaginatedVideos();
      setState((prevState) => ({
        ...prevState,
        loaded: true,
        videos: response.data,
        links: response.links,
      }));
    })();
  }, [fetchPaginatedVideos]);

  const loader = useCallback(async () => {
    if (!state.links?.next) return;
    const { data: videos, links } = await fetchPaginatedVideos(
      state.links.next
    );
    setState((prevState) => ({
      ...prevState,
      videos: [...prevState.videos, ...videos],
      links,
    }));
  }, [state.links?.next, fetchPaginatedVideos]);

  const { isLoading, ref } = useInfiniteLoader(loader);

  const onReactTypeChange = (activeType: ReactionType) => {
    setState((prevState) => ({
      ...prevState,
      activeType,
      filterOpened: false,
    }));
  };

  const onSortChange = (sort: Sort) => {
    setState((prevState) => ({
      ...prevState,
      sortOpened: false,
      sort,
    }));
  };

  return (
    <section>
      <RespondCta>
        <Button
          as={Link}
          to={`/videos/${video.parent?.slug || video.slug}/comment`}
          state={{ intended: location }}
        >
          <StyledRespondIcon />
          UPLOAD VIDEO COMMENT
        </Button>
      </RespondCta>
      {state.loaded ? (
        !!state.videos.length && (
          <>
            <Header>
              <Filter>
                <SortButton
                  $type="labelBold"
                  $color={"black"}
                  onClick={() =>
                    setState((prevState) => ({
                      ...prevState,
                      filterOpened: !prevState.filterOpened,
                    }))
                  }
                >
                  <StyledFilterIcon $red={state.filterOpened} />
                  {state.activeType} COMMENTS
                </SortButton>
                {state.filterOpened && (
                  <ReactionsTypeDropdown
                    onClose={() =>
                      setState((prevState) => ({
                        ...prevState,
                        filterOpened: false,
                      }))
                    }
                  >
                    {reactionTypes.map((type) => (
                      <DropdownItem
                        key={type}
                        onClick={() => onReactTypeChange(type)}
                        active={state.activeType === type}
                      >
                        {type}
                      </DropdownItem>
                    ))}
                  </ReactionsTypeDropdown>
                )}
              </Filter>
              <SortWrap>
                <SortButton
                  $type="labelBold"
                  $color={state.sortOpened ? "black" : "gray"}
                  onClick={() =>
                    setState((prevState) => ({
                      ...prevState,
                      sortOpened: !prevState.sortOpened,
                    }))
                  }
                >
                  <StyledSortIcon $red={state.sortOpened} />
                  SORT BY
                </SortButton>
                {state.sortOpened && (
                  <Dropdown
                    onClose={() =>
                      setState((prevState) => ({
                        ...prevState,
                        sortOpened: false,
                      }))
                    }
                  >
                    {(Object.keys(Sort) as Array<keyof typeof Sort>).map(
                      (key) => (
                        <DropdownItem
                          key={key}
                          onClick={() => onSortChange(Sort[key])}
                          active={state.sort === Sort[key]}
                        >
                          {key}
                        </DropdownItem>
                      )
                    )}
                  </Dropdown>
                )}
              </SortWrap>
            </Header>
            <Grid>
              {state.videos.map((video) => (
                <VideoCard key={video.id} video={video} />
              ))}
            </Grid>
            {!!state.links?.next && (
              <div ref={ref}>{isLoading && <Loader />}</div>
            )}
          </>
        )
      ) : (
        <Loader />
      )}
    </section>
  );
};
