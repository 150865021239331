import { FC, useEffect, useRef } from "react";
import styled from "styled-components";
import { Loader } from "@googlemaps/js-api-loader";

import { Input } from "@/components/common";

const Wrap = styled.div`
  position: relative;
`;

interface Props {
  setLocation: (city: string, state: string) => void;
  hasError: boolean;
}

export const LocationInput: FC<Props> = ({ setLocation, hasError }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    (async () => {
      if (!inputRef.current) return;
      const loader = new Loader({
        apiKey: process.env.REACT_APP_GOOGLE_PLACES_KEY!,
        libraries: ["places"],
      });
      await loader.load();
      const autocomplete = new google.maps.places.Autocomplete(
        inputRef.current,
        {
          fields: ["address_components"],
          strictBounds: false,
          types: ["(cities)"],
        }
      );
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        const city = place.address_components?.find((item) =>
          item.types.includes("locality")
        );
        const state = place.address_components?.find((item) =>
          item.types.includes("administrative_area_level_1")
        );
        if (city && state) {
          setLocation(city.short_name, state.short_name);
        }
      });
    })();
  }, [setLocation]);

  return (
    <Wrap>
      <Input type="text" ref={inputRef} $hasError={hasError} />
    </Wrap>
  );
};
