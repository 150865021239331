import { FC, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { gtag } from "@/utilities/tracking";

export const Tracker: FC = ({ children }) => {
  const location = useLocation();

  // TODO: think of better solution
  useEffect(() => {
    setTimeout(() => {
      gtag("event", "page_view", {
        page_title: document.title,
        page_location: location.pathname + location.search,
        page_path: location.pathname,
      });
    }, 800);
  }, [location]);

  return <>{children}</>;
};
