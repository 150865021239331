import { FC } from "react";
import styled, { css } from "styled-components";
import { Helmet } from "react-helmet-async";

import { PageResource } from "@/types/api";
import { Card, Loader } from "@/components/common";
import { FlexibleContent } from "@/pages/Text/components/FlexibleContent";
import useResourceLoader from "@/hooks/useResourceLoader";

const Wrap = styled.div`
  padding: 32px 0 120px;
`;

const StyledCard = styled(Card)`
  padding: 63px 120px 120px;
  ${({ theme }) => css`
    ${theme.breakpoints.mediumDesktop} {
      padding-left: 80px;
      padding-right: 80px;
    }
    ${theme.breakpoints.tablet} {
      padding: 45px 30px;
    }
    ${theme.breakpoints.phone} {
      padding: 30px 15px;
    }
  `}
`;

export const TextPage: FC = () => {
  const [page, isLoading] = useResourceLoader<PageResource>(
    (slug) => `/v1/pages/${slug}`
  );

  return (
    <Wrap>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Helmet>
            <title>{page!.title} | MyPoint.tv</title>
            <meta property="og:title" content={page!.title} />
          </Helmet>
          <StyledCard>
            <FlexibleContent contentBlocks={page!.content} />
          </StyledCard>
        </>
      )}
    </Wrap>
  );
};
