import { FC } from "react";
import styled, { css, DefaultTheme, StyledComponent } from "styled-components";
import { NavLink } from "react-router-dom";

import { Text } from "@/components/common";
import { TextProps } from "@/components/common/Text";

const NavButton = styled(Text)`
  height: 39px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  transition: 0.2s ease-out all;
  text-transform: uppercase;
  letter-spacing: 0.75px;
  padding: 0;
  border: 0;
  background: transparent;
  cursor: pointer;
  ${({ theme }) => css`
    border-bottom: 1px solid #e8e8e8;
    &.active {
      border-color: ${theme.colors.red};
      font-weight: ${theme.typography.textPoppinsBold.fontWeight};
    }
    ${theme.breakpoints.phone} {
      height: auto;
      padding: 18px 0 14px;
      align-items: center;
    }
  `}
  &:hover {
    border-color: ${({ theme }) => theme.colors.red};
  }
` as StyledComponent<typeof NavLink, DefaultTheme, TextProps, never>;

const Nav = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  margin: 0 0 48px;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      grid-gap: 15px;
      margin: 0 0 32px;
    }
  `}
`;

export const Navigation: FC = () => {
  return (
    <Nav>
      <NavButton
        as={NavLink}
        to="/register"
        $type={"textPoppins"}
        className={({ isActive }: { isActive: boolean }) =>
          isActive ? "active" : ""
        }
      >
        Sign Up
      </NavButton>
      <NavButton
        as={NavLink}
        to="/login"
        $type={"textPoppins"}
        className={({ isActive }: { isActive: boolean }) =>
          isActive ? "active" : ""
        }
      >
        Sign In
      </NavButton>
    </Nav>
  );
};
