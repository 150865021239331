import { FC, Fragment, useEffect, useMemo } from "react";
import styled, { css } from "styled-components";
import { useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import MediaQuery, { useMediaQuery } from "react-responsive";

import { Dispatch, RootState } from "@/utilities/store";
import { handleFormErrors } from "@/utilities/helpers";
import {
  Button,
  Card,
  Column,
  Input,
  Row,
  SrOnly,
  Text,
  Textarea,
  ValidationError,
} from "@/components/common";
import { ResponsiveImage } from "@/components/ResponsiveImage";
import CoverPlaceholderSrc from "@/assets/img/cover-placeholder.svg";
import { ReactComponent as EditIcon } from "@/assets/icons/edit.svg";
import { ReactComponent as SmileIcon } from "@/assets/icons/smile.svg";
import { ContributorAvatar } from "@/pages/Home/components/ContributorAvatar";
import { FormGroup } from "@/components/common/FormGroup";
import { SocialMediaResource } from "@/types/api";
import { SocialsIcon } from "@/components/SocialsIcon";
import { successNotification } from "@/utilities/alerts";
import { Breakpoints } from "@/utilities/theme";
import { fbq } from "@/utilities/tracking";

const Wrap = styled.form`
  padding: 32px 0 120px;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      padding-top: 22px;
    }
  `}
`;

const CoverWrap = styled.div`
  position: relative;
  border-radius: 10px 10px 0 0;
`;

const Cover = styled(ResponsiveImage)`
  height: 290px;
  width: 100%;
  border-radius: 10px 10px 0 0;
  object-fit: cover;

  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      height: 190px;
    }
  `}
`;

const EditButton = styled(Text).attrs({ $type: "labelBold" })`
  display: inline-flex;
  align-items: center;
  background: ${({ theme }) => theme.colors.lightGray};
  border: 1px solid ${({ theme }) => theme.colors.white};
  box-shadow: 0px 2px 4px rgba(32, 37, 44, 0.1);
  border-radius: 5px;
  padding: 6px 14px;
  text-transform: uppercase;
  cursor: pointer;
  position: absolute;
  transition: 0.2s ease-out color;
  svg {
    margin-right: 8px;
  }
  &:hover {
    color: ${({ theme }) => theme.colors.red};
  }
`;

const CoverEditButton = styled(EditButton)`
  right: 20px;
  bottom: 20px;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      top: 20px;
      bottom: auto;
    }
  `}
`;

const Content = styled.div`
  padding: 0 60px 68px;
  border-radius: 0 0 10px 10px;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      padding: 0 15px 44px;
    }
  `}
`;

const StyledContributorAvatar = styled(ContributorAvatar)`
  margin: 0 auto;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      width: 180px;
      height: 180px;
    }
  `}
`;

const Avatar = styled.div`
  margin: -148px 0 28px;
  position: relative;
`;

const AvatarEditButton = styled(EditButton)`
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
`;

const Name = styled(Text).attrs({ as: "h1", $type: "heading1" })`
  text-align: center;
  margin: 0 0 22px;
`;

const Label = styled(Text).attrs({ $type: "labelBold" })`
  padding: 0 15px;
  display: block;
  margin-bottom: 14px;
`;

const ContentInputs = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 26px;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      grid-gap: 20px;
    }
  `}
`;

const StyledTextarea = styled(Textarea)`
  min-height: 156px;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      min-height: 228px;
    }
  `}
`;

const StyledSmileIcon = styled(SmileIcon)`
  width: 16px;
  margin-right: 19px;
  fill: ${({ theme }) => theme.colors.red};
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      margin-right: 15px;
    }
  `}
`;

const FormHeading = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 28px;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      margin: 0 0 20px;
      justify-content: center;
    }
  `}
`;

const FormCard = styled(Card)`
  min-height: 100%;
  padding: 50px 60px;
  ${({ theme }) => css`
    ${theme.breakpoints.mediumDesktop} {
      padding: 50px 30px;
    }
    ${theme.breakpoints.tablet} {
      margin-top: 30px;
      padding: 50px 60px;
      min-height: auto;
    }
  `}
`;

const BasicFields = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 10px;
  margin: 0 0 27px;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      grid-gap: 15px;
      margin: 0 0 20px;
    }
  `}
`;

const SocialFieldsHeading = styled(Text).attrs({
  $type: "labelBold",
  as: "h2",
})`
  padding-left: 60px;
  margin: 0 0 14px;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      padding-left: 15px;
      margin-top: 20px;
    }
  `}
`;

const SocialFields = styled.div`
  display: grid;
  grid-template-columns: 34px minmax(0, 1fr);
  grid-gap: 10px;
  align-items: center;
  ${Input} {
    height: 40px;
  }
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      grid-template-columns: 35px minmax(0, 1fr);
      grid-column-gap: 0;
      grid-row-gap: 15px;
    }
  `}
`;

const FormCardFooter = styled.div`
  text-align: right;
  margin-top: 44px;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      text-align: center;
      margin-top: 25px;
    }
  `}
`;

const SubmitButton = styled(Button)`
  width: 150px;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      height: 32px;
    }
  `}
`;

const ProfileCard = styled(Card)`
  min-height: 100%;
  ${({ theme }) => css`
    ${theme.breakpoints.tablet} {
      min-height: auto;
    }
  `}
`;

const mediaTypes: Array<SocialMediaResource["type"]> = [
  "facebook",
  "twitter",
  "instagram",
  "linkedin",
  "youtube",
];

type Previewable = { url: string; srcSet?: string };

export type FormData = {
  first_name: string;
  last_name: string;
  paypal_email: string;
  location: string;
  avatar?: FileList;
  cover?: FileList;
  topics: string;
  description: string;
  socials: { [key in SocialMediaResource["type"]]: string };
};

export const Profile: FC = () => {
  const isSmallDesktop = useMediaQuery({
    maxWidth: Breakpoints.max.smallDesktop,
  });

  const isTabletOrMobile = useMediaQuery({
    maxWidth: Breakpoints.max.tablet,
  });

  const dispatch = useDispatch<Dispatch>();

  const user = useSelector(
    (rootState: RootState) => rootState.authentication.user!
  );

  const defaultValues = useMemo(
    () => ({
      first_name: user.first_name,
      last_name: user.last_name,
      location: user.contributor!.location || "",
      topics: user.contributor!.topics || "",
      description: user.contributor!.description || "",
      paypal_email: user.contributor!.paypal_email || "",
      socials: {
        facebook:
          user.contributor!.social_medias?.find(
            (media) => media.type === "facebook"
          )?.url || "",
        twitter:
          user.contributor!.social_medias?.find(
            (media) => media.type === "twitter"
          )?.url || "",
        instagram:
          user.contributor!.social_medias?.find(
            (media) => media.type === "instagram"
          )?.url || "",
        linkedin:
          user.contributor!.social_medias?.find(
            (media) => media.type === "linkedin"
          )?.url || "",
        youtube:
          user.contributor!.social_medias?.find(
            (media) => media.type === "youtube"
          )?.url || "",
      },
    }),
    [user]
  );

  const {
    register,
    reset,
    handleSubmit,
    control,
    setError,
    formState: { errors, isSubmitSuccessful, isSubmitting },
  } = useForm<FormData>({
    defaultValues,
  });

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(defaultValues);
    }
  }, [defaultValues, reset, isSubmitSuccessful]);

  const cover = useWatch({ control, name: "cover" });

  const avatar = useWatch({ control, name: "avatar" });

  const onSubmit = handleSubmit(async (data) => {
    try {
      await dispatch.authentication.updateProfile(data);
      fbq(
        "track",
        "Lead",
        {
          content_name: "Filled up profile form",
        },
        { eventID: `FILLED_UP_PROFILE_${user.id}` }
      );
      return successNotification("Profile successfully updated!");
    } catch (err) {
      handleFormErrors<FormData>(err, setError);
    }
  });

  const getCoverPreviewObj = (): Previewable => {
    if (cover?.[0]) {
      return {
        url: URL.createObjectURL(cover[0]),
      };
    }
    return user.contributor!.cover || { url: CoverPlaceholderSrc };
  };

  const getAvatarPreviewObj = (): Previewable | undefined => {
    if (avatar?.[0]) {
      return {
        url: URL.createObjectURL(avatar[0]),
      };
    }
    return user.contributor!.avatar;
  };

  const renderSocialFields = () => {
    return mediaTypes.map((type: SocialMediaResource["type"]) => (
      <Fragment key={type}>
        <SocialsIcon type={type} />
        <FormGroup
          type="text"
          placeholder="Link"
          error={errors?.socials?.[type]}
          {...register(`socials.${type}`)}
        />
      </Fragment>
    ));
  };

  return (
    <>
      <Wrap onSubmit={onSubmit}>
        <Row>
          <Column $cols={isTabletOrMobile ? 12 : isSmallDesktop ? 7 : 8}>
            <MediaQuery maxWidth={Breakpoints.max.phone}>
              <FormHeading>
                <StyledSmileIcon />
                <Text $type="heading1" as="h1">
                  Edit Profile
                </Text>
              </FormHeading>
            </MediaQuery>
            <ProfileCard>
              <CoverWrap>
                <Cover
                  src={getCoverPreviewObj().url}
                  srcSet={getCoverPreviewObj().srcSet}
                />
                <CoverEditButton as="label" htmlFor="coverInput">
                  <EditIcon />
                  Edit
                </CoverEditButton>
                <SrOnly
                  as="input"
                  type="file"
                  accept="image/*"
                  id="coverInput"
                  {...register("cover")}
                />
              </CoverWrap>
              <Content>
                <Avatar>
                  <StyledContributorAvatar
                    size="large"
                    name={user.name}
                    avatar={getAvatarPreviewObj()}
                  />
                  <AvatarEditButton as="label" htmlFor="avatarInput">
                    <EditIcon />
                    Edit
                  </AvatarEditButton>
                  <SrOnly
                    as="input"
                    type="file"
                    accept="image/*"
                    id="avatarInput"
                    {...register("avatar")}
                  />
                </Avatar>
                <Name>{user.name}</Name>
                <MediaQuery maxWidth={Breakpoints.max.phone}>
                  <BasicFields>
                    <FormGroup
                      type="text"
                      error={errors.first_name}
                      placeholder="First Name"
                      {...register("first_name")}
                    />
                    <FormGroup
                      type="text"
                      error={errors.last_name}
                      placeholder="Last Name"
                      {...register("last_name")}
                    />
                    <FormGroup disabled value={user.email} />
                    <FormGroup
                      type="email"
                      error={errors.paypal_email}
                      placeholder="Stripe Account Email Address"
                      {...register("paypal_email")}
                    />
                    <FormGroup
                      type="text"
                      error={errors.location}
                      placeholder="Location (Optional)"
                      {...register("location")}
                    />
                  </BasicFields>
                </MediaQuery>
                <ContentInputs>
                  <div>
                    <Label htmlFor="topicsInput" as="label">
                      Topics I Report on:
                    </Label>
                    <FormGroup
                      id="topicsInput"
                      type="text"
                      error={errors.topics}
                      placeholder="e.g. environmental issues, politics, music..."
                      {...register("topics")}
                    />
                  </div>
                  <div>
                    <Label htmlFor="descriptionInput" as="label">
                      About Me:
                    </Label>
                    <StyledTextarea
                      id="descriptionInput"
                      type="text"
                      placeholder="Tell us more about yourself."
                      {...register("description")}
                    />
                    {!!errors.description && (
                      <ValidationError>{errors.description}</ValidationError>
                    )}
                  </div>
                </ContentInputs>
                <MediaQuery maxWidth={Breakpoints.max.phone}>
                  <SocialFieldsHeading>
                    My Social Media Links:
                  </SocialFieldsHeading>
                  <SocialFields>{renderSocialFields()}</SocialFields>
                  <FormCardFooter>
                    <SubmitButton
                      small
                      type="submit"
                      disabled={isSubmitting}
                      loading={isSubmitting}
                    >
                      Submit
                    </SubmitButton>
                  </FormCardFooter>
                </MediaQuery>
              </Content>
            </ProfileCard>
          </Column>
          <MediaQuery minWidth={Breakpoints.min.tablet}>
            <Column $cols={isTabletOrMobile ? 12 : isSmallDesktop ? 5 : 4}>
              <FormCard>
                <FormHeading>
                  <StyledSmileIcon />
                  <Text $type="heading1" as="h1">
                    Edit Profile
                  </Text>
                </FormHeading>
                <BasicFields>
                  <FormGroup
                    type="text"
                    error={errors.first_name}
                    placeholder="First Name"
                    {...register("first_name")}
                  />
                  <FormGroup
                    type="text"
                    error={errors.last_name}
                    placeholder="Last Name"
                    {...register("last_name")}
                  />
                  <FormGroup disabled value={user.email} />
                  <FormGroup
                    type="email"
                    error={errors.paypal_email}
                    placeholder="Stripe Account Email Address"
                    {...register("paypal_email")}
                  />
                  <FormGroup
                    type="text"
                    error={errors.location}
                    placeholder="Location (Optional)"
                    {...register("location")}
                  />
                </BasicFields>
                <SocialFieldsHeading>
                  My Social Media Links:
                </SocialFieldsHeading>
                <SocialFields>{renderSocialFields()}</SocialFields>
                <FormCardFooter>
                  <SubmitButton
                    small
                    type="submit"
                    disabled={isSubmitting}
                    loading={isSubmitting}
                  >
                    Submit
                  </SubmitButton>
                </FormCardFooter>
              </FormCard>
            </Column>
          </MediaQuery>
        </Row>
      </Wrap>
    </>
  );
};
