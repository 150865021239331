import { FC } from "react";
import styled, { css } from "styled-components";

import { Text } from "@/components/common";
import { VideoResource } from "@/types/api";
import { ReactComponent as LikeIcon } from "@/assets/icons/like.svg";
import useLikes from "@/hooks/useLikes";
import { DateTime } from "luxon";

const Content = styled.div`
  padding: 0 30px 46px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 0 0 10px 10px;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      padding: 0 15px 30px;
    }
  `}
`;

const Header = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
  padding: 25px 0;
  display: flex;
  align-items: center;
`;

const Badge = styled(Text).attrs({
  $type: "labelBold",
  as: "div",
  $color: "white",
})`
  background: ${({ theme }) => theme.colors.red};
  height: 22px;
  line-height: 0;
  padding: 0 14px 0 30px;
  display: inline-flex;
  align-items: center;
  border-radius: 0px 11px 11px 0px;
  margin-left: -30px;
  margin-right: 30px;
  flex-shrink: 0;
  text-transform: uppercase;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      margin-right: 20px;
    }
  `}
`;

const Title = styled.p`
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      white-space: initial;
    }
  `}
`;

const Likes = styled.div`
  margin-left: 30px;
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      margin-left: 20px;
    }
  `}
`;

const StyledLikeIcon = styled(LikeIcon)<{ $filled: boolean }>`
  fill: transparent;
  stroke: ${({ theme }) => theme.colors.black};
  ${({ theme, $filled }) =>
    $filled &&
    css`
      fill: ${theme.colors.black};
    `}
`;

const LikeButton = styled.button`
  margin-left: 14px;
`;

const Heading = styled(Text).attrs({ as: "h1", $type: "heading1" })`
  margin: 21px 0 15px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Date = styled(Text)`
  margin-left: 15px;
`;

interface Props {
  video: VideoResource;
}

export const CommentContent: FC<Props> = ({ video }) => {
  const { isLiked, toggleLike, isLikeLoading } = useLikes(video.id);

  return (
    <Content>
      <Header>
        <Badge>{video.reaction}</Badge>
        <Title>
          <Text as="span" $type="labelNormal">
            This video is a comment on:{" "}
          </Text>
          <Text as="strong" $type="labelSemiBold">
            {video.parent!.name}
          </Text>
        </Title>
        <Likes>
          <Text $type="labelSemiBold" as="span">
            {video.likes_count}
          </Text>
          <LikeButton onClick={toggleLike} disabled={isLikeLoading}>
            <StyledLikeIcon $filled={isLiked} />
          </LikeButton>
        </Likes>
      </Header>
      <Heading>{video.name}</Heading>
      <Footer>
        <Text $type="labelSemiBold">{video.contributor!.name}</Text>
        <Date $type="label">
          {DateTime.fromISO(video.published_at).toRelativeCalendar()}
        </Date>
      </Footer>
    </Content>
  );
};
