import { FC, useState } from "react";
import styled, { css } from "styled-components";
import { useMediaQuery } from "react-responsive";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";

import { VideoResource } from "@/types/api";
import { Text } from "@/components/common/Text";
import { ContributorAvatar } from "@/pages/Home/components/ContributorAvatar";
import { VideoCardFooter } from "@/pages/Video/components/VideoCardFooter";
import { Breakpoints } from "@/utilities/theme";
import { ReactComponent as ArrowIcon } from "@/assets/icons/arrow-bottom.svg";
import { Button } from "@/components/common";
import { ContributorSupportDialog } from "@/components/ContributorSupportDialog";

const Content = styled.div`
  padding: 0 30px 32px;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      padding: 15px 15px 0;
      display: flex;
      flex-direction: column;
    }
  `}
`;

const Author = styled.div<{ $hideBorderBottom: boolean }>`
  display: flex;
  align-items: center;
  padding: 14px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};

  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      padding: 10px 0;
      border-top: 1px solid ${({ theme }) => theme.colors.lightGray};
      margin: 0;
    }
  `}
  ${({ $hideBorderBottom }) =>
    $hideBorderBottom &&
    css`
      border-bottom: 0;
    `}
`;

const AuthorName = styled(Text)`
  text-decoration: none;
  margin: 0 0 2px;
  display: inline-block;
  &:hover {
    opacity: 0.8;
    transition: 0.3s ease-out opacity;
  }
`;

const Heading = styled(Text)`
  margin: 15px 0 10px;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      order: -1;
      margin: 0 0 15px;
      padding: 5px 0;
      display: flex;
      align-items: flex-start;
      > span {
        flex: 1;
      }
    }
  `}
`;

const SupportButton = styled(Button)`
  margin-left: auto;
`;

const DescriptionToggle = styled.button<{ $rotate: boolean }>`
  margin-left: 22px;
  svg {
    stroke: ${({ theme }) => theme.colors.gray};
    fill: transparent;
    transition: 0.2s ease-out transform;
    ${({ $rotate }) =>
      $rotate &&
      css`
        transform: rotate(180deg);
      `}
  }
`;

const Description = styled(Text)`
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      padding: 15px 0;
    }
  `}
`;

const StyledContributorAvatar = styled(ContributorAvatar)`
  margin-right: 15px;
`;

interface Props {
  video: VideoResource;
}

export const VideoCardContent: FC<Props> = ({ video }) => {
  const [isContentOpen, setIsContentOpen] = useState(false);
  const [showSupportDialog, setShowSupportDialog] = useState(false);

  const isMobile = useMediaQuery({ maxWidth: Breakpoints.max.phone });

  return (
    <>
      <VideoCardFooter video={video} />
      <Content>
        <Author $hideBorderBottom={!isContentOpen}>
          <StyledContributorAvatar
            name={video.contributor!.name!}
            avatar={
              video.contributor!.avatar_thumbnail
                ? { url: video.contributor!.avatar_thumbnail }
                : undefined
            }
            verified={video.contributor!.verified}
          />
          <div>
            <AuthorName
              $type="labelSemiBold"
              as={Link}
              to={`/contributors/${video.contributor!.slug}`}
            >
              {video.contributor!.name}
            </AuthorName>
            <Text $color="gray" $type="label">
              {DateTime.fromISO(video.published_at).toLocaleString({
                month: "long",
                day: "numeric",
                year: "numeric",
              })}
            </Text>
          </div>
          {!!!video.hide_support_button && (
            <>
              <SupportButton small onClick={() => setShowSupportDialog(true)}>
                Support
              </SupportButton>
              {showSupportDialog && (
                <ContributorSupportDialog
                  contributorId={video.contributor!.id}
                  contributorName={video.contributor!.name!}
                  onClose={() => setShowSupportDialog(false)}
                />
              )}
            </>
          )}
        </Author>
        <Heading $type="heading1" as="h1">
          <span>{video.name}</span>
          {isMobile && (
            <DescriptionToggle
              $rotate={isContentOpen}
              onClick={() => setIsContentOpen((prev) => !prev)}
            >
              <ArrowIcon />
            </DescriptionToggle>
          )}
        </Heading>
        {video.description && (!isMobile || isContentOpen) && (
          <Description $type="textOpenSans">{video.description}</Description>
        )}
      </Content>
    </>
  );
};
