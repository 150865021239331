import { FC } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

import { RootState } from "@/utilities/store";

interface Props {
  forContributorsOnly?: boolean;
}

export const PrivateRoute: FC<Props> = ({
  children,
  forContributorsOnly = false,
}) => {
  const location = useLocation();

  const isAuthenticated = useSelector(
    (state: RootState) => !!state.authentication.user
  );

  const isContributor = useSelector(
    (state: RootState) => !!state.authentication.user?.contributor
  );

  if (!isAuthenticated) {
    return (
      <Navigate
        to="/login"
        state={{ from: location.state?.intended || location }}
        replace
      />
    );
  }

  if (forContributorsOnly && !isContributor) {
    return (
      <Navigate
        to="/403"
        state={{ from: location.state?.intended || location }}
        replace
      />
    );
  }

  return <>{children}</>;
};
