import { FC } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";

import { Card, Button } from "@/components/common";
import { ReactComponent as Icon } from "@/assets/icons/video-comment.svg";

const StyledCard = styled(Card)`
  background-color: ${({ theme }) => theme.colors.black};
  margin: 0 0 29px;
  padding: 30px 15px 42px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledIcon = styled(Icon)`
  margin: 0 0 29px;
  fill: ${({ theme }) => theme.colors.white};
`;

interface Props {
  videoSlug: string;
}

export const UploadReactionCta: FC<Props> = ({ videoSlug }) => {
  const location = useLocation();

  return (
    <StyledCard>
      <StyledIcon />
      <Button
        as={Link}
        small
        to={`/videos/${videoSlug}/comment`}
        state={{ intended: location }}
      >
        Upload video comment
      </Button>
    </StyledCard>
  );
};
