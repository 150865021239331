import { FC, useRef, useState } from "react";
import styled, { css } from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import { ReactComponent as ArrowIcon } from "@/assets/icons/arrow.svg";
import { CardSizes, VideoCard } from "@/pages/Home/components/VideoCard";
import { useMediaQuery } from "react-responsive";
import { Breakpoints } from "@/utilities/theme";
import { VideoResource } from "@/types/api";

const Arrow = styled.button<{ $rotate?: boolean }>`
  width: 24px;
  height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s ease-out background-color;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
  border: 0;
  padding: 0;
  ${({ theme, $rotate }) => css`
    transform: ${$rotate ? "rotate(-180deg)" : "none"};
    background: ${theme.colors.white};
    svg {
      transition: 0.2s ease-out stroke;
      stroke: ${theme.colors.gray};
    }
    &:hover {
      background: ${theme.colors.red};
      svg {
        stroke: ${theme.colors.white};
      }
    }
  `}
`;

const Dot = styled.button<{ $active: boolean }>`
  width: 8px;
  height: 8px;
  font-size: 0;
  padding: 0;
  margin: 0 5px;
  border-radius: 50%;
  border: 0;
  cursor: pointer;
  transition: 0.2s ease-out all;
  ${({ theme, $active }) => css`
    background-color: ${$active ? theme.colors.red : "#dfdfe2"};
    &:hover,
    &:focus {
      background-color: ${theme.colors.red};
    }
    ${theme.breakpoints.phone} {
      width: 6px;
      height: 6px;
    }
  `}
`;

const Dots = styled.div`
  margin: 0 25px;
  display: flex;
  align-items: center;
`;

const Line = styled.div`
  flex: 1;
  height: 2px;
  background: ${({ theme }) => theme.colors.white};
`;

const SliderNav = styled.div`
  display: flex;
  align-items: center;
  padding: 0 15px;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      justify-content: center;
    }
  `}
`;

const Slide = styled.div`
  padding: 15px;
  margin-right: 30px;
`;

const StyledSlider = styled.div`
  overflow-x: hidden;
  margin: -15px;
  .slick-list {
    transition: all 0.4s ease-in-out;
  }
`;

interface Props {
  videos: VideoResource[];
}

export const VideoSlider: FC<Props> = ({ videos }) => {
  const slider = useRef<Slider>(null);

  const [activeSlide, setActiveSlide] = useState(0);

  const isMobile = useMediaQuery({ maxWidth: Breakpoints.max.phone });

  const sliderSettings = {
    dots: false,
    infinite: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    adaptiveHeight: true,
    autoplaySpeed: 4000,
    beforeChange: (_: number, next: number) => setActiveSlide(next),
  };

  if (!videos.length) return null;

  return (
    <StyledSlider>
      <Slider {...sliderSettings} ref={slider}>
        {videos.map((video) => (
          <Slide key={video.id}>
            <VideoCard
              size={CardSizes.Large}
              ribbon="Editor's choice"
              video={video}
            />
          </Slide>
        ))}
      </Slider>
      <SliderNav>
        {!isMobile && <Line />}
        <Dots>
          {videos.map((video, index) => (
            <Dot
              key={video.id}
              $active={activeSlide === index}
              onClick={() => slider.current?.slickGoTo(index)}
            >
              {index + 1}
            </Dot>
          ))}
        </Dots>
        {!isMobile && (
          <>
            <Arrow $rotate onClick={() => slider.current?.slickPrev()}>
              <ArrowIcon />
            </Arrow>
            <Arrow onClick={() => slider.current?.slickNext()}>
              <ArrowIcon />
            </Arrow>
          </>
        )}
      </SliderNav>
    </StyledSlider>
  );
};
