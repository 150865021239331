import { FC } from "react";
import styled, { createGlobalStyle, css } from "styled-components";
import MediaQuery, { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { VideoResource } from "@/types/api";
import { Column, Loader, Row, Text } from "@/components/common";
import { VideoCard } from "@/pages/Video/components/VideoCard";
import { SidebarVideos } from "@/pages/Video/components/SidebarVideos";
import { Breakpoints } from "@/utilities/theme";
import { MobileTabs } from "@/pages/Video/components/MobileTabs";
import { UploadReactionCta } from "@/pages/Video/components/UploadReactionCta";
import useResourceLoader from "@/hooks/useResourceLoader";
import { Reactions } from "@/pages/Video/components/Reactions";
import { ReactComponent as BackArrow } from "@/assets/icons/back-arrow.svg";
import { Comments } from "@/pages/Video/components/Comments";
import GoogleAd from "@/components/GoogleAd";

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

const Wrap = styled.div`
  padding: 32px 0 120px;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      padding-top: 0;
    }
  `}
`;

const Sidebar = styled(Column)`
  position: absolute;
  right: 0;
  height: 100%;
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.white};
  flex-direction: column;
  ${({ theme }) => css`
    ${theme.breakpoints.tablet} {
      border: 0;
    }
  `}
`;

const StyledRow = styled(Row)`
  position: relative;
`;

const StyledBackArrow = styled(BackArrow)`
  stroke: ${({ theme }) => theme.colors.red};
  transition: 0.2s ease-out stroke;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      stroke: ${theme.colors.white};
    }
  `}
`;

const BackArrowCircle = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.white};
  margin-right: 30px;
  transition: 0.2s ease-out background-color;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      background: ${theme.colors.red};
      margin-right: 15px;
    }
  `}
`;

const BackToParent = styled(Link)`
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  margin-bottom: 30px;
  &:hover {
    ${BackArrowCircle} {
      background: ${({ theme }) => theme.colors.red};
    }
    ${StyledBackArrow} {
      stroke: ${({ theme }) => theme.colors.white};
    }
  }
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      margin: 15px 0;
    }
  `}
`;

const StyledGoogleAd = styled(GoogleAd)`
  margin-top: 30px;
`;

export const Video: FC = () => {
  const isLargeDesktop = useMediaQuery({
    minWidth: Breakpoints.min.largeDesktop,
  });

  const isNotDesktop = useMediaQuery({
    maxWidth: Breakpoints.max.tablet,
  });

  const [video, isLoading] = useResourceLoader<VideoResource>(
    (slug) => `/v1/videos/${slug}`,
    {
      include: [
        "media",
        "parent",
        "contributor",
        "categories",
        "contributor.user",
        "contributor.media",
        "reactions_count",
        "reactions_count_by_type",
        "likes_count",
        "meta",
        "views_count",
      ].join(","),
    }
  );

  return (
    <>
      <Wrap>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Helmet>
              <title>{video!.name} | MyPoint.tv</title>
              <meta name="description" content={video!.description} />
              <meta property="og:title" content={video!.name} />
              <meta property="og:description" content={video!.description} />
              <meta
                property="og:image"
                content={
                  video!.thumbnail!.media?.url || video!.thumbnail!.default
                }
              />
            </Helmet>
            {!!video!.parent && (
              <BackToParent to={`/videos/${video!.parent!.slug}`}>
                <BackArrowCircle>
                  <StyledBackArrow />
                </BackArrowCircle>
                <Text $type="textPoppinsBold" as="span">
                  Back to original story
                </Text>
              </BackToParent>
            )}
            <StyledRow>
              <Column $cols={isNotDesktop ? 12 : isLargeDesktop ? 8 : 7}>
                <VideoCard video={video!} />
                <MediaQuery minWidth={Breakpoints.min.smallDesktop}>
                  <StyledGoogleAd adSlot="4023316978" />
                </MediaQuery>
              </Column>
              <MediaQuery minWidth={Breakpoints.min.smallDesktop}>
                <Sidebar $cols={isLargeDesktop ? 4 : 5} as="aside">
                  <UploadReactionCta
                    videoSlug={video!.parent?.slug || video!.slug}
                  />
                  <SidebarVideos video={video!} />
                </Sidebar>
              </MediaQuery>
            </StyledRow>
            <MediaQuery minWidth={Breakpoints.min.smallDesktop}>
              {!!video!.parent ? (
                <Comments parentVideoId={video!.parent!.id} />
              ) : (
                <Reactions contributorId={video!.contributor!.id} />
              )}
            </MediaQuery>
            <MediaQuery maxWidth={Breakpoints.max.tablet}>
              <GlobalStyle />
              <MobileTabs video={video!} />
            </MediaQuery>
          </>
        )}
      </Wrap>
    </>
  );
};
