import { FC } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { Text } from "@/components/common";
import { numberWithCommas } from "@/utilities/helpers";
import { ContributorAvatar } from "@/pages/Home/components/ContributorAvatar";
import { ContributorResource } from "@/types/api";
import useFollow from "../../../hooks/useFollow";

const Plus = styled.div`
  width: 8px;
  height: 8px;
  transition: 0.3s ease-out width;
  position: relative;
  &::before,
  &::after {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    content: "";
    background-color: ${({ theme }) => theme.colors.black};
  }
  &::before {
    width: 100%;
    height: 2px;
  }
  &::after {
    height: 100%;
    width: 2px;
  }
`;

const Categories = styled.div`
  padding-left: 58px;
  padding-right: 10px;
  padding-top: 10px;
  display: none;
`;

const Content = styled.div`
  flex: 1;
`;

const Button = styled.button`
  box-shadow: 0 2px 4px rgba(32, 37, 44, 0.07);
  border-radius: 16px;
  background-color: #ffffff;
  transition: 0.3s ease-out all;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  padding: 0;
  border: 0;
  margin-left: 15px;
  cursor: pointer;
  ${Text} {
    overflow: hidden;
    width: 0;
    transition: 0.3s ease-out all;
    letter-spacing: 0.28px;
    text-transform: uppercase;
    display: inline-block;
  }
  &:hover {
    ${Text} {
      opacity: 0.7;
    }
  }
`;

const Name = styled(Text)`
  text-decoration: none;
  margin: 0 0 2px;
  display: inline-block;
  &:hover {
    opacity: 0.8;
    transition: 0.3s ease-out opacity;
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const Inner = styled.div<{ $followed?: boolean }>`
  width: 100%;
  padding: 15px 10px;
  transition: 0.3s ease-out all;
  border-radius: 10px;
  background-color: transparent;
  &:hover {
    background-color: #ffffff;
    box-shadow: 0 0 15px rgba(32, 37, 44, 0.1);
    border-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    ${Categories} {
      display: block;
    }
    ${Plus} {
      display: none;
      width: 0;
    }
    ${Button} {
      box-shadow: ${({ $followed }) =>
        $followed
          ? "0 -2px 4px rgba(32, 37, 44, 0.07)"
          : "0 -2px 4px rgba(255, 255, 255, 0.35)"};
      background: ${({ theme, $followed }) =>
        $followed ? theme.colors.white : theme.colors.red};
      width: auto;
      ${Text} {
        width: ${({ $followed }) => ($followed ? "84px" : "70px")};
      }
    }
  }
`;

const Card = styled.article`
  display: flex;
  align-items: center;
  position: relative;
  height: 72px;
  border-bottom: 1px solid #ffffff;
`;

const StyledContributorAvatar = styled(ContributorAvatar)`
  margin-right: 15px;
`;

interface Props {
  contributor: ContributorResource;
  onFollowersChange: (contributorSlug: string) => Promise<void>;
}

export const ContributorRow: FC<Props> = ({
  contributor,
  onFollowersChange,
}) => {
  const { isFollowed, toggleFollow, isFollowLoading } = useFollow(
    contributor.id,
    () => onFollowersChange(contributor.slug)
  );

  return (
    <Card>
      <Inner $followed={isFollowed}>
        <Flex>
          <StyledContributorAvatar
            name={contributor.name!}
            avatar={
              contributor.avatar_thumbnail
                ? { url: contributor.avatar_thumbnail }
                : undefined
            }
            verified={contributor.verified}
            to={`/contributors/${contributor.slug}`}
          />
          <Content>
            <Name
              $type="labelSemiBold"
              as={Link}
              to={`/contributors/${contributor.slug}`}
            >
              {contributor.name}
            </Name>
            <Text $type="label">
              {numberWithCommas(contributor.followers_count || 0)}{" "}
              {contributor.followers_count!.toString()[0] === "1"
                ? "Follower"
                : "Followers"}
            </Text>
          </Content>
          <Button onClick={toggleFollow} disabled={isFollowLoading}>
            <Text
              $type="labelBold"
              $color={isFollowed ? "gray" : "white"}
              as="span"
            >
              {isFollowed ? "Unfollow" : "Follow"}
            </Text>
            <Plus />
          </Button>
        </Flex>
        {!!contributor.topics && (
          <Categories>
            <Text $type="labelSemiBold" as="span">
              Reporting on:
            </Text>{" "}
            <Text $type="label" as="span">
              {contributor.topics}
            </Text>
          </Categories>
        )}
      </Inner>
    </Card>
  );
};
