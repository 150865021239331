import { FC, MouseEvent } from "react";
import styled, { css } from "styled-components";
import { useForm, useWatch } from "react-hook-form";

import { Modal } from "@/components/Modal";
import { Dialog } from "@/components/Modal/Dialog";
import { Button, Input, Text } from "@/components/common";
import { api } from "@/utilities/api";
import { errorNotification } from "@/utilities/alerts";

const Heading = styled(Text)`
  margin: 0 0 20px;
  text-transform: uppercase;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      text-align: center;
      line-height: 22px;
      > span {
        display: block;
      }
    }
  `}
`;

const Inputs = styled.div`
  margin-top: 22px;
  display: grid;
  grid-template-columns: repeat(4, 60px) minmax(0, 1fr);
  grid-column-gap: 15px;
  align-items: center;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      grid-template-columns: repeat(4, minmax(0, 1fr));
      grid-row-gap: 30px;
    }
  `}
`;

const Option = styled(Button)<{ $selected: boolean }>`
  border-radius: 20px;
  padding: 0;
  height: 32px;
  border: 0;
  transition: 0.2s ease-out all;
  ${({ theme }) => css`
    background: ${theme.colors.gray};
    color: ${theme.colors.white};
    &:hover {
      opacity: 0.75;
    }
  `}
  ${({ theme, $selected }) =>
    $selected &&
    css`
      background: ${theme.colors.red};
      color: ${theme.colors.white} !important;
    `}
`;

const Footer = styled.div`
  display: flex;
  margin-top: 30px;
  align-items: center;
  justify-content: flex-end;
`;

const StyledInput = styled(Input)`
  height: 40px;
  padding: 0 25px;
  ${({ theme }) => css`
    border-color: ${theme.colors.bg};
    background: ${theme.colors.bg};
    ${theme.breakpoints.phone} {
      grid-column: 1 / span 4;
    }
  `}
`;

const SubmitButton = styled(Button)`
  ${({ loading, theme }) =>
    !loading &&
    css`
      &:disabled {
        cursor: not-allowed;
        background: ${theme.colors.gray};
      }
    `}
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      padding: 0px 50px;
    }
  `}
`;

const Cancel = styled(Text)`
  margin-right: 30px;
  transition: 0.2s ease-out opacity;
  &:hover {
    opacity: 0.75;
  }
`;

const Label = styled(Text)`
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      text-align: center;
      display: block;
      font-size: 13px;
    }
  `}
`;

type FormData = {
  ammount: number;
};

interface Props {
  onClose: () => void;
  contributorName: string;
  contributorId: number;
}

const options = [5, 10, 15, 25];

export const ContributorSupportDialog: FC<Props> = ({
  onClose,
  contributorName,
  contributorId,
}) => {
  const {
    register,
    setValue,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>();

  const ammount = useWatch({ control, name: "ammount" });

  const onSubmit = handleSubmit(async (data) => {
    try {
      const { url } = await api.post<FormData, { url: string }>(
        `/v1/contributors/${contributorId}/donate`,
        data
      );
      window.location.href = url;
    } catch (error) {
      errorNotification();
    }
  });

  return (
    <Modal>
      <Dialog onClose={onClose}>
        <Heading $type="heading2" as="h3">
          <span>Support contributor</span> {contributorName}
        </Heading>
        <form onSubmit={onSubmit}>
          <Label as="label" $type="labelNormal">
            Choose an amount
          </Label>
          <Inputs>
            {options.map((value, index) => (
              <Option
                key={index}
                small
                $selected={ammount === value}
                onClick={(e: MouseEvent<HTMLButtonElement>) => {
                  e.preventDefault();
                  setValue("ammount", value);
                }}
              >
                ${value}
              </Option>
            ))}
            <StyledInput
              {...register("ammount", { valueAsNumber: true })}
              type="number"
              step="0.01"
              min="0.50"
              placeholder="$ Other amount"
            />
          </Inputs>
          <Footer>
            <Cancel
              as="button"
              $color="gray"
              $type="textPoppinsBold"
              onClick={(e: MouseEvent<HTMLElement>) => {
                e.preventDefault();
                onClose();
              }}
            >
              Cancel
            </Cancel>
            <SubmitButton
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitting || isNaN(ammount)}
            >
              Donate
            </SubmitButton>
          </Footer>
        </form>
      </Dialog>
    </Modal>
  );
};
