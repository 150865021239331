import { FC } from "react";
import styled, { css } from "styled-components";

import { Text } from "@/components/common";
import { ReactComponent as TickIcon } from "@/assets/icons/tick.svg";
import { MediaResource } from "@/types/api";
import { ResponsiveImage } from "@/components/ResponsiveImage";
import { Link } from "react-router-dom";

const Initials = styled.div`
  width: 100%;
  height: 100%;
  box-shadow: 0 2px 4px rgba(32, 37, 44, 0.1);
  border: 2px solid ${({ theme }) => theme.colors.white};
  object-fit: cover;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.lightGray};
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrap = styled.div<{ $size: "small" | "large"; to?: string }>`
  position: relative;
  flex-shrink: 0;
  ${({ $size }) =>
    $size === "small" &&
    css`
      width: 42px;
      height: 42px;
    `}
  ${({ $size, theme }) =>
    $size === "large" &&
    css`
      width: 248px;
      height: 248px;
      ${Text} {
        font-size: 104px;
        line-height: 1;
      }
      ${theme.breakpoints.phone} {
        width: 180px;
        height: 180px;
        ${Text} {
          font-size: 76px;
        }
      }
    `}
`;

export const Checkmark = styled.span`
  width: 12px;
  height: 12px;
  box-shadow: 0 2px 4px rgba(32, 37, 44, 0.1);
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.red};
  > svg {
    position: relative;
    bottom: 3px;
    left: 2px;
  }
`;

const Avatar = styled(ResponsiveImage)<{ $size: "small" | "large" }>`
  width: 100%;
  height: 100%;
  box-shadow: 0 2px 4px rgba(32, 37, 44, 0.1);
  object-fit: cover;
  border-radius: 50%;
  ${({ $size }) =>
    $size === "small" &&
    css`
      border: 2px solid ${({ theme }) => theme.colors.white};
    `}
  ${({ $size }) =>
    $size === "large" &&
    css`
      border: 8px solid ${({ theme }) => theme.colors.white};
    `}
`;

const getInitialsFromName = (name: string) =>
  name
    .split(" ")
    .map((word) => word[0])
    .join("");

interface Props {
  name: string;
  avatar?: Pick<MediaResource, "url" | "srcSet">;
  verified?: boolean;
  size?: "small" | "large";
  className?: string;
  to?: string;
}

export const ContributorAvatar: FC<Props> = ({
  name,
  avatar,
  verified,
  size = "small",
  className,
  to,
}) => {
  return (
    <Wrap $size={size} className={className} as={!!to ? Link : "div"} to={to}>
      {avatar ? (
        <Avatar
          $size={size}
          loading="lazy"
          src={avatar.url}
          alt={name}
          srcSet={avatar.srcSet}
        />
      ) : (
        <Initials>
          <Text $color="gray" $type="labelBold">
            {getInitialsFromName(name)}
          </Text>
        </Initials>
      )}
      {verified && (
        <Checkmark>
          <TickIcon />
        </Checkmark>
      )}
    </Wrap>
  );
};
