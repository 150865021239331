import { FC } from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

import { ReactComponent as Logo } from "@/assets/icons/logo-black.svg";
import { ReactComponent as CloseIcon } from "@/assets/icons/close.svg";

const CloseButton = styled(Link)`
  position: absolute;
  right: 55px;
  top: 54px;
  display: inline-block;
  padding: 10px;
  svg {
    transition: 0.2s ease-out stroke;
    stroke: ${({ theme }) => theme.colors.gray};
  }
  &:hover {
    svg {
      stroke: ${({ theme }) => theme.colors.red};
    }
  }
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      top: 20px;
      right: 20px;
    }
  `}
`;

const Wrap = styled.div`
  position: relative;
  padding: 74px 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      padding: 38px 0;
    }
  `}
`;

const Header = styled.header`
  text-align: center;
  margin: 0 0 50px;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      margin: 0 0 42px;
      svg {
        width: 176px;
        height: 33px;
      }
    }
  `}
`;

export const MinimalLayout: FC = ({ children }) => {
  return (
    <Wrap>
      <CloseButton to="/">
        <CloseIcon />
      </CloseButton>
      <Header>
        <Link to="/">
          <Logo />
        </Link>
      </Header>
      {children}
    </Wrap>
  );
};
