import { DragEvent, ChangeEvent, FC, useState, useEffect } from "react";
import styled, { css } from "styled-components";
import * as tus from "tus-js-client";
import { useMediaQuery } from "react-responsive";

import { Button, Card, SrOnly, Text } from "@/components/common";
import { UploadProgress } from "@/pages/VideoUpload/components/UploadProgress";
import { isValidExtension } from "@/utilities/helpers";
import { errorNotification } from "@/utilities/alerts";
import { ReactComponent as UploadIcon } from "@/assets/icons/upload.svg";
import echo from "@/utilities/echo";
import { Breakpoints } from "@/utilities/theme";
import { api } from "@/utilities/api";
import axios from "axios";

const ALLOWED_EXTENSIONS = ["m4v", "avi", "mpg", "mp4", "mov"];

const IconContainer = styled.label<{ $red: boolean }>`
  width: 192px;
  height: 192px;
  background-color: #c7c7c7;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme, $red }) =>
    $red &&
    css`
      background-color: ${theme.colors.red};
    `}
`;

const Icon = styled(UploadIcon)`
  width: 53px;
  height: 56px;
`;

const StyledCard = styled(Card)<{ $highlighted: boolean }>`
  border: 1px dashed #707070;
  width: 570px;
  height: 438px;
  max-width: calc(100% - 30px);
  margin: 80px auto 50px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  ${({ theme, $highlighted }) =>
    $highlighted &&
    css`
      border-color: ${theme.colors.red};
      ${Icon} {
        opacity: 0.75;
      }
    `}
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      margin: 0 auto 40px;
      border: 0;
    }
  `}
`;

const Dropzone = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Label = styled.div`
  margin: 20px 0;
  text-align: center;
  strong {
    font-weight: 600;
  }
`;

const Disclaimer = styled(Text)`
  margin-top: 20px;
`;

const INITIAL_STATE = {
  isHighlighted: false,
  isUploadingToBucket: false,
  isUploading: false,
  isProccessing: false,
  uploadProgress: 0,
  cloudflareId: "",
  originalVideoFilename: "",
  tusUpload: null,
};

interface Props {
  onUploadFinished: (
    cloudflareId: string,
    originalVideoFilename: string
  ) => void;
}

interface State {
  isHighlighted: boolean;
  isUploading: boolean;
  isUploadingToBucket: boolean;
  isProccessing: boolean;
  uploadProgress: number;
  cloudflareId: string;
  originalVideoFilename: string;
  tusUpload: null | tus.Upload;
}

export const UploadCard: FC<Props> = ({ onUploadFinished }) => {
  const [state, setState] = useState<State>({ ...INITIAL_STATE });

  const isMobile = useMediaQuery({
    maxWidth: Breakpoints.max.phone,
  });

  useEffect(() => {
    if (!state.cloudflareId) return;

    const channelName = `cloudflare_videos.${state.cloudflareId}`;

    const eventName = "VideoProcessed";

    const channel = echo.private(channelName);

    channel.listen(eventName, () => {
      onUploadFinished(state.cloudflareId, state.originalVideoFilename);
    });

    return () => {
      channel.stopListening(eventName);
    };
  }, [state.cloudflareId, onUploadFinished, state.originalVideoFilename]);

  const onCancel = () => {
    if (state.tusUpload) {
      state.tusUpload.abort(true);
    }
    setState({ ...INITIAL_STATE });
  };

  const uploadFile = (file: File) => {
    const upload = new tus.Upload(file, {
      endpoint: `${process.env.REACT_APP_API_ENDPOINT}/api/v1/videos/upload`,
      chunkSize: 15 * 1024 * 1024,
      retryDelays: [0, 1000, 3000, 5000],
      parallelUploads: 1,
      metadata: {
        filename: file.name,
        filetype: file.type,
        watermark: process.env.REACT_APP_CLOUDFLARE_WATERMARK_UID || "",
      },
      headers: {
        Accept: "application/json",
      },
      uploadSize: file.size,
      onError: (error) => {
        errorNotification("An Error occurred...", "Please try again.");
        setState((prevState) => ({
          ...prevState,
          isUploading: false,
          uploadProgress: 0,
        }));
      },
      onProgress: (bytesUploaded, bytesTotal) => {
        setState((prevState) => ({
          ...prevState,
          uploadProgress: Math.floor((bytesUploaded / bytesTotal) * 100),
        }));
      },
      onSuccess: () => {
        setState((prevState) => ({
          ...prevState,
          isUploading: false,
          isProccessing: true,
          tusUpload: null,
        }));
      },
      onBeforeRequest: (req) => {
        const xhr = req.getUnderlyingObject();
        if (req.getURL().includes("videodelivery")) {
          return (xhr.withCredentials = false);
        }
        xhr.withCredentials = true;
      },
      onAfterResponse: (_, res) => {
        const mediaIdHeader = res.getHeader("stream-media-id");
        if (mediaIdHeader) {
          setState((prevState) => ({
            ...prevState,
            cloudflareId: mediaIdHeader,
          }));
        }
      },
    });
    setState((prevState) => ({
      ...prevState,
      tusUpload: upload,
    }));
    upload.start();
  };

  const uploadOriginal = async (file: File) => {
    try {
      setState((prevState) => ({ ...prevState, isUploadingToBucket: true }));

      const { filename, url } = await api.post<
        { extension: string },
        { filename: string; url: string }
      >("/v1/videos/original", { extension: file.name.split(".").pop()! });

      await axios.put(url, file, {
        headers: {
          "Content-Type": file.type,
        },
      });

      setState((prevState) => ({
        ...prevState,
        isUploadingToBucket: false,
        isUploading: true,
        originalVideoFilename: filename,
      }));
      uploadFile(file);
    } catch (err) {
      errorNotification();
    }
  };

  const onDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onDragEnter = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setState((prevState) => ({
      ...prevState,
      isHighlighted: true,
    }));
  };

  const onDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setState((prevState) => ({
      ...prevState,
      isHighlighted: false,
    }));
    const file = e.dataTransfer.files[0];
    if (!isValidExtension(file.name, ALLOWED_EXTENSIONS)) {
      return errorNotification(
        "Invalid file format",
        `Allowed extensions: ${ALLOWED_EXTENSIONS.join(", ")}`
      );
    }
    uploadOriginal(file);
  };

  const onDragLeave = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setState((prevState) => ({
      ...prevState,
      isHighlighted: false,
    }));
  };

  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target?.files?.[0];
    if (!file) return;
    if (!isValidExtension(file.name, ALLOWED_EXTENSIONS)) {
      return errorNotification(
        "Invalid file format",
        `Allowed extensions: ${ALLOWED_EXTENSIONS.join(", ")}`
      );
    }
    uploadOriginal(file);
  };

  return (
    <StyledCard $highlighted={state.isHighlighted}>
      {state.isUploading || state.isProccessing || state.isUploadingToBucket ? (
        <UploadProgress
          percentage={state.uploadProgress}
          onCancel={onCancel}
          isProccessing={state.isProccessing}
          isUploading={state.isUploading}
          isUploadingToBucket={state.isUploadingToBucket}
        />
      ) : (
        <Dropzone
          onDrop={onDrop}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDragOver={onDragOver}
        >
          <IconContainer $red={isMobile} htmlFor="videoFileInput">
            <Icon />
          </IconContainer>
          {isMobile ? (
            <Label>
              <Text $type="textPoppins" as="label" htmlFor="videoFileInput">
                SELECT A VIDEO FILE
              </Text>
            </Label>
          ) : (
            <>
              <Label>
                <Text
                  as="span"
                  $type={state.isHighlighted ? "labelBold" : "label"}
                >
                  <strong>{`Drag & drop`}</strong>
                </Text>
                <br />
                <Text $type="label" as="span">
                  a video file or…
                </Text>
              </Label>
              <Button as="label" htmlFor="videoFileInput">
                Select file
              </Button>
              <Disclaimer $type="label" as="span">
                <strong>Allowed formats:</strong>{" "}
                {ALLOWED_EXTENSIONS.join(", ")}
              </Disclaimer>
            </>
          )}
          <SrOnly
            type="file"
            accept="video/*"
            onChange={onFileChange}
            as="input"
            id="videoFileInput"
          />
        </Dropzone>
      )}
    </StyledCard>
  );
};
