import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";

import { Dispatch, RootState } from "@/utilities/store";
import useAuthGuard from "@/hooks/useAuthGuard";

const useLikes = (
  videoId: number,
  callback?: (countIncreased: boolean) => void
) => {
  const dispatch = useDispatch<Dispatch>();

  const [isLikeLoading, setIsLikeLoading] = useState(false);

  const isLiked = useSelector(
    (state: RootState) =>
      !!state.authentication.user?.liked_videos_ids?.includes(videoId)
  );

  const toggleLike = useAuthGuard(async () => {
    setIsLikeLoading(true);
    const increment = !isLiked;
    await (increment
      ? dispatch.authentication.likeVideo(videoId)
      : dispatch.authentication.unlikeVideo(videoId));
    setIsLikeLoading(false);
    callback?.(increment);
  });

  return { isLiked, toggleLike, isLikeLoading };
};

export default useLikes;
