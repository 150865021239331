import { FC, useCallback, useEffect, useState } from "react";
import styled, { css } from "styled-components";

import { ReactComponent as SortIcon } from "@/assets/icons/sort.svg";
import { Loader, Text } from "@/components/common";
import { Dropdown, DropdownItem } from "@/components/Dropdown";
import { FilterableReactionTypes, PaginatedResponse } from "@/types";
import useInfiniteLoader from "@/hooks/useInfiniteLoader";
import { errorNotification } from "@/utilities/alerts";
import { api } from "@/utilities/api";
import { VideoResource, PaginationLinks } from "@/types/api";
import { CommentCard } from "@/components/CommentCard";

const Wrap = styled.section`
  margin-top: 30px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 30px;
  ${({ theme }) => css`
    ${theme.breakpoints.mediumDesktop} {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  `}
`;

const Title = styled(Text).attrs({ $type: "textPoppinsBold", as: "h3" })`
  text-transform: uppercase;
  margin-right: 17px;
`;

const StyledSortIcon = styled(SortIcon)<{ $filled: boolean }>`
  stroke: ${({ theme }) => theme.colors.black};
  transition: 0.2s ease-out stroke;
  ${({ theme, $filled }) =>
    $filled &&
    css`
      stroke: ${theme.colors.red};
    `}
`;

const FilterButton = styled.button`
  &:hover {
    ${StyledSortIcon} {
      stroke: ${({ theme }) => theme.colors.red};
    }
  }
`;

const FiltersWrap = styled.div`
  position: relative;
`;

const StyledLoader = styled(Loader)`
  margin-top: 30px;
`;

interface State {
  isLoading: boolean;
  videos: VideoResource[];
  links?: PaginationLinks;
}

export const Comments: FC<{ parentVideoId: number }> = ({ parentVideoId }) => {
  const [showFilters, setShowFilters] = useState(false);

  const [reactionsType, setReactionsType] = useState(
    FilterableReactionTypes.ALL
  );

  const onTypeChange = (type: FilterableReactionTypes) => {
    setShowFilters(false);
    setReactionsType(type);
  };

  const [state, setState] = useState<State>({
    isLoading: true,
    videos: [],
  });

  const onVideoLikesChange = useCallback(
    (increased: boolean, videoId: number) => {
      setState((prevState) => ({
        ...prevState,
        videos: prevState.videos.map((video) => {
          if (video.id === videoId) {
            return {
              ...video,
              likes_count: video.likes_count! + (increased ? 1 : -1),
            };
          }
          return video;
        }),
      }));
    },
    [setState]
  );

  const fetchPaginatedVideos = useCallback(
    (link = "/v1/videos") => {
      return api.get<never, PaginatedResponse<VideoResource[]>>(link, {
        params: {
          include: [
            "media",
            "contributor",
            "contributor.user",
            "meta",
            "likes_count",
            "views_count",
          ].join(","),
          per_page: 12,
          "filter[reaction]": reactionsType,
          "filter[parent_id]": parentVideoId,
          "filter[visible]": 1,
        },
      });
    },
    [parentVideoId, reactionsType]
  );

  useEffect(() => {
    (async () => {
      try {
        setState({
          videos: [],
          isLoading: true,
        });
        const { data, links } = await fetchPaginatedVideos();
        setState({
          videos: data,
          isLoading: false,
          links,
        });
      } catch (err) {
        errorNotification();
      }
    })();
  }, [fetchPaginatedVideos]);

  const loader = useCallback(async () => {
    if (!state.links?.next) return;
    const { data: videos, links } = await fetchPaginatedVideos(
      state.links.next
    );
    setState((prevState) => ({
      videos: [...prevState.videos, ...videos],
      isLoading: false,
      links,
    }));
  }, [state.links?.next, fetchPaginatedVideos]);

  const { isLoading: isInfiniteLoading, ref } = useInfiniteLoader(loader);

  if (state.isLoading) return <StyledLoader />;

  return (
    <Wrap>
      <Header>
        <Title>{reactionsType} comments on the original story</Title>
        <FiltersWrap>
          <FilterButton onClick={() => setShowFilters((prev) => !prev)}>
            <StyledSortIcon $filled={showFilters} />
          </FilterButton>
          {showFilters && (
            <Dropdown onClose={() => setShowFilters(false)}>
              {Object.values(FilterableReactionTypes).map((type) => (
                <DropdownItem
                  key={type}
                  onClick={() => onTypeChange(type)}
                  active={type === reactionsType}
                >
                  {type}
                </DropdownItem>
              ))}
            </Dropdown>
          )}
        </FiltersWrap>
      </Header>
      {!state.isLoading && !!!state.videos.length ? (
        <Text $type="textPoppinsSemiBold">
          There are no {reactionsType} comments on the original story.
        </Text>
      ) : (
        <Grid>
          {state.videos.map((video) => (
            <CommentCard
              video={video}
              key={video.id}
              onVideoLikesChange={onVideoLikesChange}
            />
          ))}
        </Grid>
      )}
      {!!state.links?.next && (
        <div ref={ref}>{isInfiniteLoading && <Loader />}</div>
      )}
    </Wrap>
  );
};
