import { FC } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

import { RootState } from "@/utilities/store";

export const GuestRoute: FC = ({ children }) => {
  const location = useLocation();

  const { from } = location.state || { from: { pathname: "/" } };

  const isGuest = useSelector(
    (state: RootState) => !!!state.authentication.user
  );

  if (!isGuest) {
    return <Navigate to={from} replace />;
  }

  return <>{children}</>;
};
