import { FC, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import styled, { createGlobalStyle } from "styled-components";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${({ theme }) => theme.zIndex.modal};
  width: 100%;
  height: 100vh;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background: rgba(0, 0, 0, 0.55);
`;

const BodyStyle = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

const modalRoot = document.getElementById("modal-root");

interface Props {
  className?: string;
}

export const Modal: FC<Props> = ({ children, className }) => {
  const ref = useRef(document.createElement("div"));

  useEffect(() => {
    const el = ref.current;

    modalRoot!.appendChild(el);

    return () => {
      modalRoot!.removeChild(el);
    };
  }, []);

  return createPortal(
    <>
      <BodyStyle />
      <Overlay className={className}>{children}</Overlay>
    </>,
    ref.current
  );
};
