import React, { memo } from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { DateTime } from "luxon";

import { Text } from "@/components/common";
import { ReactComponent as BookmarkIcon } from "@/assets/icons/bookmark.svg";
import { ReactComponent as EyeIcon } from "@/assets/icons/eye.svg";
import { ReactComponent as ReactionIcon } from "@/assets/icons/reaction.svg";
import { Breakpoints } from "@/utilities/theme";
import { ContributorAvatar } from "@/pages/Home/components/ContributorAvatar";
import { VideoResource } from "@/types/api";
import useBookmarks from "@/hooks/useBookmarks";
import { VideoThumbnail } from "@/components/VideoThumbnail";
import { ReactComponent as Spinner } from "@/assets/icons/spinner.svg";

export enum CardSizes {
  Small,
  Medium,
  Large,
}

const Description = styled(Text)`
  margin: 0 0 17px;
  ${({ theme }) => css`
    ${theme.breakpoints.mediumDesktop} {
      order: 2;
    }
  `}
`;

const FooterInfo = styled.div`
  display: inline-flex;
  align-items: center;
  > svg {
    margin-right: 5px;
    stroke: ${({ theme }) => theme.colors.black};
  }
  &:last-of-type {
    margin-left: 30px;
    ${({ theme }) => css`
      ${theme.breakpoints.smallDesktop} {
        margin: 0;
      }
      ${theme.breakpoints.phone} {
        margin-left: 30px;
      }
    `}
  }
  ${Text} {
    &:first-of-type {
      margin-right: 3px;
    }
  }
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${({ theme }) => css`
    ${theme.breakpoints.mediumDesktop} {
      order: 3;
    }
    ${theme.breakpoints.smallDesktop} {
      justify-content: space-between;
    }
    ${theme.breakpoints.phone} {
      justify-content: flex-end;
    }
  `}
`;

const Heading = styled(Text)`
  margin: 11px 0;
  transition: 0.2s ease-out opacity;
  &:hover {
    opacity: 0.75;
  }
  ${({ theme }) => css`
    ${theme.breakpoints.tablet} {
      order: 1;
    }
  `}
`;

const BookmarkButton = styled.button<{ $filled: boolean }>`
  margin-left: 15px;
  align-self: flex-start;
  margin-top: 7px;
  cursor: pointer;
  width: 14px;
  flex-shrink: 0;
  border: 0;
  padding: 0;
  background: transparent;
  cursor: pointer;
  > svg {
    width: 100%;
    height: auto;
    stroke: ${({ theme }) => theme.colors.gray};
    transition: 0.2s ease-out fill, 0.2s ease-out opacity;
    fill: transparent;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.min.smallDesktop} {
      &:hover {
        > svg {
          opacity: 0.75;
        }
      }
    }
  `}

  ${(p) =>
    p.$filled &&
    css`
      > svg {
        fill: ${({ theme }) => theme.colors.gray};
      }
    `}
`;

const Name = styled(Text)`
  transition: 0.2s ease-out opacity;
  text-decoration: none;
  margin: 0 0 2px;
  &:hover,
  &:focus {
    opacity: 0.8;
  }
`;

const ContributorInfo = styled.div`
  flex: 1;
`;

const Contributor = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
  padding: 11px 0;
`;

const Content = styled.div`
  padding: 0 30px 22px;
  background: #ffffff;
  width: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  ${({ theme }) => css`
    ${theme.breakpoints.tablet} {
      display: flex;
      flex-direction: column;
    }
    ${theme.breakpoints.phone} {
      padding: 0 15px 21px;
    }
  `}
`;

const Ribbon = styled(Text)`
  padding: 0 30px;
  text-transform: uppercase;
  height: 23px;
  display: inline-flex;
  align-items: center;
  background: ${({ theme }) => theme.colors.red};
  position: absolute;
  left: 0;
  top: 15px;
  border-top-right-radius: 14px;
  border-bottom-right-radius: 14px;
  ${({ theme }) => css`
    ${theme.breakpoints.mediumDesktop} {
      top: 0;
      left: auto;
      right: 15px;
      border-radius: 0;
      border-bottom-left-radius: 14px;
      border-bottom-right-radius: 14px;
      width: 89px;
      height: 63px;
      padding: 0;
      justify-content: center;
      text-align: center;
    }
  `}
`;

const Card = styled.article<{ $size: CardSizes }>`
  box-shadow: 0 0 15px rgba(32, 37, 44, 0.1);
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  ${({ $size }) =>
    $size === CardSizes.Small &&
    css`
      flex-direction: column;
      ${Content} {
        flex: 1;
        display: flex;
        flex-direction: column;
      }
      ${Footer} {
        margin-top: auto;
      }
      ${Ribbon} {
        top: 0;
        left: auto;
        right: 15px;
        border-radius: 0;
        border-bottom-left-radius: 14px;
        border-bottom-right-radius: 14px;
        width: 89px;
        height: 63px;
        padding: 0;
        justify-content: center;
        text-align: center;
      }
    `}
  ${({ $size, theme }) =>
    $size === CardSizes.Large &&
    css`
      border-radius: 10px;
      flex-wrap: nowrap;
      ${theme.breakpoints.mediumDesktop} {
        flex-direction: column;
        position: relative;
      }
      ${Content} {
        flex: 1;
        border-radius: 0;
        border-top-right-radius: 10px;
        padding: 50px 30px 22px;
        border-bottom-right-radius: 10px;
        display: flex;
        flex-direction: column;
        position: relative;
        ${theme.breakpoints.mediumDesktop} {
          padding: 0 30px 22px;
          position: static;
          border-top-right-radius: 0;
          border-bottom-left-radius: 10px;
        }
      }
      ${Contributor} {
        margin-top: auto;
        margin-bottom: 10px;
        border: 0;
        ${theme.breakpoints.mediumDesktop} {
          border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
        }
      }
      ${Heading} {
        order: -1;
        margin: 0 0 20px;
        ${theme.breakpoints.mediumDesktop} {
          order: 0;
          margin: 11px 0;
        }
      }
    `}
`;

const StyledContributorAvatar = styled(ContributorAvatar)`
  margin-right: 15px;
`;

interface Props {
  size?: CardSizes;
  className?: string;
  ribbon?: string;
  video: VideoResource;
}

export const VideoCard: React.FC<Props> = memo(
  ({ size = CardSizes.Small, className, ribbon, video }) => {
    const { isBookmarked, toggleBookmark, isBookmarkLoading } = useBookmarks(
      video.id
    );

    const isTabletOrMobile = useMediaQuery({
      maxWidth: Breakpoints.max.tablet,
    });

    const { contributor } = video;

    const ribbonText = ribbon
      ? ribbon
      : video.is_sponsored
      ? "Sponsored content"
      : null;

    return (
      <Card
        $size={isTabletOrMobile ? CardSizes.Small : size}
        className={className}
      >
        <VideoThumbnail
          video={video}
          large={
            CardSizes.Large === (isTabletOrMobile ? CardSizes.Small : size)
          }
        />
        <Content>
          {!!ribbonText && (
            <Ribbon $color="white" $type="labelSemiBold">
              {ribbonText}
            </Ribbon>
          )}
          <Contributor>
            <StyledContributorAvatar
              avatar={
                contributor!.avatar_thumbnail
                  ? { url: contributor!.avatar_thumbnail }
                  : undefined
              }
              name={contributor!.name!}
              verified={contributor!.verified}
              to={`/contributors/${contributor!.slug}`}
            />
            <ContributorInfo>
              <Name
                $type="labelSemiBold"
                as={Link}
                to={`/contributors/${contributor!.slug}`}
              >
                {contributor!.name}
              </Name>
              <Text $type="label">
                {DateTime.fromISO(video.published_at).toLocaleString({
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                })}
              </Text>
            </ContributorInfo>
            <BookmarkButton
              onClick={toggleBookmark}
              $filled={isBookmarked}
              disabled={isBookmarkLoading}
            >
              {isBookmarkLoading ? <Spinner /> : <BookmarkIcon />}
            </BookmarkButton>
          </Contributor>
          {size === CardSizes.Large && (
            <Description>{video.excerpt}</Description>
          )}

          <Heading
            as="h3"
            $type={
              size === CardSizes.Small || isTabletOrMobile
                ? "heading2"
                : "heading1"
            }
          >
            <Link to={`/videos/${video.slug}`}>{video.name}</Link>
          </Heading>

          <Footer>
            <FooterInfo>
              <EyeIcon />
              <Text as="span" $type="labelBold">
                {video.views}
              </Text>
              <Text as="span" $type="label">
                views
              </Text>
            </FooterInfo>
            <FooterInfo>
              <ReactionIcon />
              <Text as="span" $type="labelBold">
                {video.reactions_count}
              </Text>
              <Text as="span" $type="label">
                comments
              </Text>
            </FooterInfo>
          </Footer>
        </Content>
      </Card>
    );
  }
);
