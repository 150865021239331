import { FC, memo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as Logo } from "@/assets/icons/logo.svg";
import { ReactComponent as UploadIcon } from "@/assets/icons/upload.svg";
import { ReactComponent as ProfileIcon } from "@/assets/icons/smile.svg";
import { ReactComponent as SearchIcon } from "@/assets/icons/search.svg";
import { ReactComponent as BookmarkIcon } from "@/assets/icons/bookmark.svg";
import { ReactComponent as MenuIcon } from "@/assets/icons/menu-dots.svg";
import { Container, Text } from "@/components/common";
import { Breakpoints } from "@/utilities/theme";
import { Dispatch, RootState } from "@/utilities/store";
import { Dropdown, DropdownItem } from "@/components/Dropdown";
import { SocialsIcon } from "@/components/SocialsIcon";
import { ContributorAvatar } from "../pages/Home/components/ContributorAvatar";
import { errorNotification } from "@/utilities/alerts";
import { Searchbar } from "@/components/Searchbar";

const StyledProfileIcon = styled(ProfileIcon)``;

const Button = styled.button<{ $withoutText?: boolean }>`
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 -2px 4px rgba(255, 255, 255, 0.15);
  border-radius: 16px;
  padding: 0 18px;
  text-decoration: none;
  letter-spacing: 0.38px;
  margin-left: 30px;
  &:hover,
  &:focus {
    box-shadow: 0 2px 4px rgba(255, 255, 255, 0.15);
  }
  ${({ $withoutText }) =>
    $withoutText &&
    css`
      width: 32px;
      padding: 0;
      border-radius: 50%;
    `}
  ${({ theme }) => css`
    color: ${theme.colors.white};
    ${theme.breakpoints.phone} {
      margin-left: 17px;
    }
  `}
  > svg {
    fill: transparent;
  }
  > ${StyledProfileIcon} {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

const ButtonText = styled(Text)`
  margin-left: 10px;
`;

const Inner = styled.div`
  height: 70px;
  display: flex;
  align-items: center;
  > a {
    line-height: 0;
  }
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      height: 60px;
    }
  `}
`;

const Wrap = styled.header`
  background: ${(p) => p.theme.colors.black};
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
`;

const Nav = styled.nav`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

const StyledBookmarkIcon = styled(BookmarkIcon)`
  stroke: #ffffff;
`;

const AuthButtonWrap = styled.div`
  position: relative;
  line-height: 0;
`;

const AuthDropdown = styled(Dropdown)`
  background: ${({ theme }) => theme.colors.red};
  width: 220px;
  padding: 18px 0;
`;

const AuthDropdownItem = styled(DropdownItem)`
  color: ${({ theme }) => theme.colors.white};
  transition: font-weight 0.2s ease-out;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 9px;
  &:hover {
    color: ${({ theme }) => theme.colors.white};
    font-weight: 700;
  }
`;

const UserAvatar = styled(ContributorAvatar)`
  height: 32px;
  width: 32px;
`;

const MenuDropdown = styled(Dropdown)`
  top: calc(100% + 25px);
  ${({ theme }) => css`
    background: ${theme.colors.black};
  `}
`;

const MenuDropdownItem = styled(DropdownItem)`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    &:hover {
      color: ${theme.colors.red};
      font-weight: 700;
    }
  `}
`;

const SearchWrap = styled(AuthButtonWrap)<{ $open: boolean }>`
  transition: 0.3s ease-out width;
  width: 32px;
  margin-left: 30px;
  ${Button} {
    margin: 0;
  }
  ${({ $open, theme }) =>
    $open &&
    css`
      width: 330px;
      ${theme.breakpoints.tablet} {
        width: 120px;
      }
      ${Button} {
        width: 0;
      }
    `}
`;

const MenuSocials = styled(DropdownItem)`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  line-height: 0;
  margin-top: 25px;
  > a {
    color: ${({ theme }) => theme.colors.white};
    &:hover {
      color: ${({ theme }) => theme.colors.red};
    }
  }
`;

const RedSearchIcon = styled(SearchIcon)`
  color: ${({ theme }) => theme.colors.black};
  position: absolute;
  right: 8px;
  top: 0;
  bottom: 0;
  margin: auto 0;
`;

export const Header: FC = memo(() => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.max.phone });

  const navigate = useNavigate();

  const [showAuthDropdown, setShowAuthDropdown] = useState(false);
  const [showMenuDropdown, setShowMenuDropdown] = useState(false);
  const [showSearch, setShowSearch] = useState(false);

  const isAuthenticated = useSelector(
    (state: RootState) => !!state.authentication.user
  );

  const contributorSlug = useSelector(
    (state: RootState) => state.authentication.user?.contributor?.slug
  );

  const userAvatar = useSelector(
    (state: RootState) =>
      state.authentication.user?.contributor?.avatar_thumbnail
  );

  const userName = useSelector(
    (state: RootState) => state.authentication.user?.name
  );

  const dispatch = useDispatch<Dispatch>();

  const onLogout = async () => {
    try {
      setShowAuthDropdown(false);
      await dispatch.authentication.logout();
      navigate("/login");
    } catch (err) {
      errorNotification();
    }
  };

  return (
    <Wrap>
      <Container>
        <Inner>
          <Link to="/">
            <Logo />
          </Link>
          <Nav>
            <Button as={Link} to="/upload" $withoutText={isMobile}>
              <UploadIcon />
              {!isMobile && (
                <ButtonText as="span" $color="white" $type="textPoppinsBold">
                  Upload VIDEO
                </ButtonText>
              )}
            </Button>
            {!isMobile && (
              <>
                <SearchWrap $open={showSearch}>
                  <Button onClick={() => setShowSearch(true)} $withoutText>
                    <SearchIcon />
                  </Button>
                  {showSearch && (
                    <>
                      <Searchbar onBlur={() => setShowSearch(false)} />
                      <RedSearchIcon />
                    </>
                  )}
                </SearchWrap>
                <Button as={Link} to="/bookmarks" $withoutText>
                  <StyledBookmarkIcon />
                </Button>
              </>
            )}
            <AuthButtonWrap>
              <Button
                onClick={() => setShowAuthDropdown((prev) => !prev)}
                $withoutText
              >
                {!!userAvatar || !!userName ? (
                  <UserAvatar
                    name={userName || ""}
                    avatar={userAvatar ? { url: userAvatar } : undefined}
                  />
                ) : (
                  <StyledProfileIcon />
                )}
              </Button>
              {showAuthDropdown && (
                <AuthDropdown onClose={() => setShowAuthDropdown(false)}>
                  {isAuthenticated ? (
                    <>
                      <AuthDropdownItem
                        forwardedAs={Link}
                        to={`/contributors/${contributorSlug!}`}
                        onClick={() => setShowAuthDropdown(false)}
                      >
                        My profile
                      </AuthDropdownItem>
                      <AuthDropdownItem onClick={onLogout}>
                        Sign out
                      </AuthDropdownItem>
                    </>
                  ) : (
                    <>
                      <AuthDropdownItem forwardedAs={Link} to="/login">
                        Sign in
                      </AuthDropdownItem>
                      <AuthDropdownItem forwardedAs={Link} to="/register">
                        Sign up
                      </AuthDropdownItem>
                    </>
                  )}
                </AuthDropdown>
              )}
            </AuthButtonWrap>
            <AuthButtonWrap>
              <Button
                onClick={() => setShowMenuDropdown((prev) => !prev)}
                $withoutText
              >
                <MenuIcon />
              </Button>
              {showMenuDropdown && (
                <MenuDropdown onClose={() => setShowMenuDropdown(false)}>
                  <MenuDropdownItem
                    forwardedAs={Link}
                    to="/about-us"
                    onClick={() => setShowMenuDropdown(false)}
                  >
                    About Us
                  </MenuDropdownItem>
                  <MenuDropdownItem
                    forwardedAs="a"
                    href="https://mypointtv.zendesk.com/hc/en-us/requests/new"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => setShowMenuDropdown(false)}
                  >
                    Contact Us
                  </MenuDropdownItem>
                  <MenuDropdownItem
                    forwardedAs={Link}
                    to="/pages/terms-conditions"
                    onClick={() => setShowMenuDropdown(false)}
                  >
                    {`Terms & Conditions`}
                  </MenuDropdownItem>
                  <MenuDropdownItem
                    forwardedAs="a"
                    href="https://mypointtv.zendesk.com/hc/en-us"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => setShowMenuDropdown(false)}
                  >
                    FAQ
                  </MenuDropdownItem>
                  <MenuDropdownItem
                    forwardedAs={Link}
                    to="/pages/privacy-policy"
                    onClick={() => setShowMenuDropdown(false)}
                  >
                    Privacy Policy
                  </MenuDropdownItem>
                  <MenuDropdownItem
                    forwardedAs={Link}
                    to="/pages/cookies-policy"
                    onClick={() => setShowMenuDropdown(false)}
                  >
                    Cookies Policy
                  </MenuDropdownItem>
                  <MenuDropdownItem
                    forwardedAs="a"
                    href="https://medium.com/@mypoint.tv"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => setShowMenuDropdown(false)}
                  >
                    Blog
                  </MenuDropdownItem>
                  <MenuSocials forwardedAs="div">
                    <a
                      href="https://www.facebook.com/mypointtv/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <SocialsIcon type="facebook" />
                    </a>
                    <a
                      href="https://www.instagram.com/mypoint.tv/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <SocialsIcon type="instagram" />
                    </a>
                    <a
                      href="https://twitter.com/MypointTv"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <SocialsIcon type="twitter" />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/mypointtv/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <SocialsIcon type="linkedin" />
                    </a>
                    <a
                      href="https://www.youtube.com/channel/UCaoC14jdnXRELhuX9v_h4kg"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <SocialsIcon type="youtube" />
                    </a>
                  </MenuSocials>
                </MenuDropdown>
              )}
            </AuthButtonWrap>
          </Nav>
        </Inner>
      </Container>
    </Wrap>
  );
});
