import { FC, memo } from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import MediaQuery from "react-responsive";

import { VideoResource } from "@/types/api";
import { Card, Text } from "@/components/common";
import useBookmarks from "@/hooks/useBookmarks";
import { ReactComponent as BookmarkIcon } from "@/assets/icons/bookmark.svg";
import { Duration, PlayButton } from "@/components/Video";
import { Breakpoints } from "@/utilities/theme";
import { ResponsiveImage } from "@/components/ResponsiveImage";
import useAnimatedThumbnail from "@/hooks/useAnimatedThumbnail";
import { ReactComponent as Spinner } from "@/assets/icons/spinner.svg";

const StyledCard = styled(Card)`
  display: flex;
  min-height: 94px;
  margin: 0 0 10px;
  ${({ theme }) => css`
    ${theme.breakpoints.tablet} {
      position: static;
      min-height: 110px;
    }
  `}
`;

const ThumbnailWrap = styled.div`
  position: relative;
  width: 165px;
  flex-shrink: 0;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  &:hover {
    --play-btn-opacity: 1;
  }
  ${({ theme }) => css`
    ${theme.breakpoints.tablet} {
      --play-btn-opacity: 1;
      width: 200px;
    }
    ${theme.breakpoints.phone} {
      --play-btn-opacity: 1;
      width: 165px;
    }
  `}
`;

const Thumbnail = styled(ResponsiveImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
`;

const Content = styled.div`
  padding: 10px 40px 15px 20px;
  flex: 1;
  position: relative;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      padding: 12px 15px 14px;
    }
  `}
`;

const Heading = styled(Text)`
  margin-top: 4px;
  display: inline-block;
  transition: 0.2s ease-out color;
  &:hover {
    color: ${({ theme }) => theme.colors.red};
  }
`;

const Bookmark = styled.button<{ $fillIcon: boolean }>`
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
  > svg {
    width: 13px;
    height: 17px;
    stroke: ${({ theme }) => theme.colors.gray};
    transition: 0.2s ease-out fill, 0.2s ease-out opacity;
    fill: transparent;
  }
  ${({ theme }) => css`
    ${theme.breakpoints.min.smallDesktop} {
      &:hover {
        > svg {
          opacity: 0.75;
        }
      }
    }
  `}
  ${({ theme, $fillIcon }) =>
    $fillIcon &&
    css`
      > svg {
        fill: ${theme.colors.gray};
      }
    `}
`;

const StyledDuration = styled(Duration)`
  && {
    bottom: 10px;
    right: 10px;
  }
`;

const MobileContributorName = styled(Text)`
  padding-right: 20px;
`;

const StyledPlayButton = styled(PlayButton)`
  width: 60px;
  height: 60px;
`;

interface Props {
  video: VideoResource;
  className?: string;
}

export const ListableVideoCard: FC<Props> = memo(({ video, className }) => {
  const { isBookmarked, toggleBookmark, isBookmarkLoading } = useBookmarks(
    video.id
  );

  const [imgState, onMouseEnter, onMouseLeave] = useAnimatedThumbnail(
    video.cloudflare_id,
    video.thumbnail!.media
  );

  return (
    <StyledCard className={className}>
      <ThumbnailWrap
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onTouchStart={onMouseEnter}
        onTouchEnd={onMouseLeave}
      >
        <Thumbnail
          src={imgState.src}
          srcSet={imgState.srcSet}
          alt={video.name}
        />
        <StyledPlayButton to={`/videos/${video.slug}`} />
        {!!video.meta?.duration && (
          <StyledDuration>{video.meta.duration}</StyledDuration>
        )}
      </ThumbnailWrap>
      <Content>
        <MediaQuery minWidth={Breakpoints.min.tablet}>
          <Text $type="label" $color="darkGray">
            {video.contributor!.name}
          </Text>
          <Heading $type="labelSemiBold" as={Link} to={`/videos/${video.slug}`}>
            {video.name}
          </Heading>
          <Bookmark
            onClick={toggleBookmark}
            $fillIcon={isBookmarked}
            disabled={isBookmarkLoading}
            type="button"
          >
            {isBookmarkLoading ? <Spinner /> : <BookmarkIcon />}
          </Bookmark>
        </MediaQuery>
        <MediaQuery maxWidth={Breakpoints.max.phone}>
          <div>
            <MobileContributorName $type="labelNormal">
              {video.contributor!.name}
            </MobileContributorName>
            <Bookmark
              onClick={toggleBookmark}
              $fillIcon={isBookmarked}
              disabled={isBookmarkLoading}
              type="button"
            >
              {isBookmarkLoading ? <Spinner /> : <BookmarkIcon />}
            </Bookmark>
          </div>
          <div>
            <Heading
              $type="textPoppinsSemiBold"
              as={Link}
              to={`/videos/${video.slug}`}
            >
              {video.name}
            </Heading>
          </div>
        </MediaQuery>
      </Content>
    </StyledCard>
  );
});
