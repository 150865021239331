// TODO: add gtag types
export const gtag = (...args: any) => {
  if (process.env.REACT_APP_ENVIRONMENT === "production") {
    return (window as any).gtag(...args);
  }
  console.log("--GA: ", ...args);
};

export const fbq = (...args: any) => {
  if (process.env.REACT_APP_ENVIRONMENT === "production") {
    return (window as any).fbq(...args);
  }
  console.log("--FBQ: ", ...args);
};
