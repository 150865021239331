import { FC, useState } from "react";
import styled, { css } from "styled-components";

import { VideoResource } from "@/types/api";
import { Text } from "@/components/common/Text";
import { MobileReactions } from "@/pages/Video/components/MobileReactions";
import { MobileTrendingVideos } from "@/pages/Video/components/MobileTrendingVideos";

const TabsNav = styled.nav`
  border-top: 1px solid ${({ theme }) => theme.colors.lightGray};
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
  display: flex;
  justify-content: center;
  margin: 30px 0;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      margin: 0 0 24px;
    }
  `}
`;

const TabsButton = styled.button<{ $active: boolean }>`
  height: 52px;
  display: inline-flex;
  align-items: center;
  margin: 0 20px;
  text-transform: uppercase;
  margin-bottom: -1px;
  border-bottom: 1px solid
    ${({ theme, $active }) => ($active ? theme.colors.red : "transparent")};
`;

const TabsButtonText = styled(Text)`
  line-height: 1.6;
`;

enum TabsEnum {
  COMMENTS = "COMMENTS ON THIS VIDEO",
  TRENDING = "TRENDING",
}

interface Props {
  video: VideoResource;
}

export const MobileTabs: FC<Props> = ({ video }) => {
  const [activeTab, setActiveTab] = useState(
    !!video.reactions_count ? TabsEnum.COMMENTS : TabsEnum.TRENDING
  );

  const renderTab = () => {
    switch (activeTab) {
      case TabsEnum.TRENDING:
        return <MobileTrendingVideos video={video} />;
      case TabsEnum.COMMENTS:
        return <MobileReactions video={video} />;
      default:
        return null;
    }
  };

  return (
    <>
      <TabsNav>
        {(Object.keys(TabsEnum) as Array<keyof typeof TabsEnum>).map((key) => (
          <TabsButton
            key={key}
            $active={activeTab === TabsEnum[key]}
            onClick={() => setActiveTab(TabsEnum[key])}
          >
            <TabsButtonText
              as="span"
              $type={activeTab === TabsEnum[key] ? "labelBold" : "labelNormal"}
            >
              {key === "COMMENTS" && !!video.parent
                ? "COMMENTS ON THE ORIGINAL STORY"
                : TabsEnum[key]}
            </TabsButtonText>
          </TabsButton>
        ))}
      </TabsNav>
      {renderTab()}
    </>
  );
};
