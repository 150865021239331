import { FC } from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { Text, Button, Card } from "@/components/common";
import { useMediaQuery } from "react-responsive";
import { Breakpoints } from "@/utilities/theme";

const Wrap = styled(Card)`
  text-align: center;
  margin-top: 50px;
  padding: 100px 15px;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      padding: 66px 15px;
      margin-top: 15px;
    }
  `}
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 15px;
  margin-bottom: 50px;
`;

export const NotAllowed: FC = () => {
  const isMobile = useMediaQuery({
    maxWidth: Breakpoints.max.phone,
  });

  return (
    <>
      <Helmet>
        <title>Not Allowed | MyPoint.tv</title>
      </Helmet>
      <Wrap>
        <Content>
          <Text $type="heading1">Oops!</Text>
          <Text $type="heading2">
            The page you're trying to reach is for contributors only.
          </Text>
          <Text $type="heading2">Please log in as contributor.</Text>
        </Content>
        <Button as={Link} to="/" small={!isMobile}>
          Back to Homepage
        </Button>
      </Wrap>
    </>
  );
};
