import { FC, useEffect } from "react";
import styled, { css } from "styled-components";
import { useMediaQuery } from "react-responsive";
import { Helmet } from "react-helmet-async";
import { useSearchParams } from "react-router-dom";

import useResourceLoader from "@/hooks/useResourceLoader";
import { ContributorResource } from "@/types/api";
import { Column, Loader, Row } from "@/components/common";
import { ContributorCard } from "@/pages/Contributor/components/ContributorCard";
import { Videos } from "@/pages/Contributor/components/Videos";
import { Breakpoints } from "@/utilities/theme";
import { successNotification } from "@/utilities/alerts";

const Wrap = styled.div`
  padding: 32px 0 120px;
`;

const Sidebar = styled(Column)`
  position: absolute;
  right: 0;
  height: 100%;
  ${({ theme }) => css`
    ${theme.breakpoints.tablet} {
      position: static;
      margin-top: 30px;
    }
  `}
`;

const StyledRow = styled(Row)`
  position: relative;
`;

export const Contributor: FC = () => {
  const isMediumDesktop = useMediaQuery({
    maxWidth: Breakpoints.max.smallDesktop,
    minWidth: Breakpoints.min.smallDesktop,
  });

  const isSmallerThanDesktop = useMediaQuery({
    maxWidth: Breakpoints.max.tablet,
  });

  const [contributor, isLoading] = useResourceLoader<ContributorResource>(
    (slug) => `/v1/contributors/${slug}`,
    {
      include: [
        "user",
        "media",
        "videos",
        "socialMedias",
        "videosCount",
        "followersCount",
        "viewsCount",
      ].join(","),
    }
  );

  const [urlSearchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (urlSearchParams.has("payment-success")) {
      successNotification(
        "Thank You!",
        "Your payment was successful. Thank you very much for supporting our contributors!"
      );
      const newSearchParams = new URLSearchParams(urlSearchParams);
      newSearchParams.delete("payment-success");
      setSearchParams(newSearchParams, { replace: true });
    }
  }, [urlSearchParams, setSearchParams]);

  return (
    <Wrap>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Helmet>
            <title>{contributor!.name} | MyPoint.tv</title>
            {!!contributor!.description && (
              <meta name="description" content={contributor!.description} />
            )}
            <meta property="og:title" content={contributor!.name} />
            {!!contributor!.description && (
              <meta
                property="og:description"
                content={contributor!.description}
              />
            )}
            {!!contributor!.avatar && (
              <meta property="og:image" content={contributor!.avatar.url} />
            )}
          </Helmet>
          <StyledRow>
            <Column
              as="main"
              $cols={isSmallerThanDesktop ? 12 : isMediumDesktop ? 7 : 8}
            >
              <ContributorCard contributor={contributor!} />
            </Column>
            <Sidebar
              as="aside"
              $cols={isSmallerThanDesktop ? 12 : isMediumDesktop ? 5 : 4}
            >
              <Videos contributorId={contributor!.id} />
            </Sidebar>
          </StyledRow>
        </>
      )}
    </Wrap>
  );
};
