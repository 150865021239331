import {
  FC,
  useState,
  useEffect,
  useCallback,
  useRef,
  MouseEvent,
} from "react";
import styled, { css } from "styled-components";

import { Loader, SrOnly, Text } from "@/components/common";
import { PaginationLinks, VideoResource } from "@/types/api";
import { ListableVideoCard } from "@/components/ListableVideoCard";
import useInfiniteLoader from "@/hooks/useInfiniteLoader";
import { FilterableReactionTypes, PaginatedResponse } from "@/types";
import { api } from "@/utilities/api";
import { errorNotification } from "@/utilities/alerts";
import { ReactComponent as SortIcon } from "@/assets/icons/sort.svg";
import { ReactComponent as FilterIcon } from "@/assets/icons/filter.svg";
import { Dropdown, DropdownItem } from "@/components/Dropdown";

const Heading = styled(Text)`
  display: inline-flex;
  align-items: center;
  text-transform: uppercase;

  ${({ theme }) =>
    css`
      ${theme.breakpoints.smallDesktop} {
        font-size: 12px;
      }
    `}
`;

const Wrap = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  margin: 0 -15px;
`;

const StyledSortIcon = styled(SortIcon)`
  margin-left: 12px;
  stroke: currentColor;
`;

const StyledFilterIcon = styled(FilterIcon)`
  stroke: currentColor;
  margin-left: 12px;
  fill: transparent;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 29px;
  padding: 0 15px;
  ${({ theme }) =>
    css`
      ${theme.breakpoints.smallDesktop} {
        margin: 0 0 20px;
      }
    `}
`;

const ReactionsFilter = styled.div`
  position: relative;
  line-height: 0;
`;

const HeadingButton = styled.button<{ $red?: boolean }>`
  color: inherit;
  ${({ theme, $red }) =>
    $red &&
    css`
      color: ${theme.colors.red};
    `}
`;

const VideosList = styled.div`
  flex: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding: 15px 15px 0;
  margin-top: -15px;
`;

enum Tabs {
  REACTIONS,
  RELATED_STORIES,
}

enum Sort {
  LATEST = "-published_at",
  OLDEST = "published_at",
  "MOST LIKED" = "-likes_count",
}

interface State {
  videos: VideoResource[];
  links?: PaginationLinks;
  isLoading: boolean;
  activeTab: Tabs;
  reactionsType: FilterableReactionTypes;
  sort: Sort;
  showFiltersDropdown: boolean;
  showSortDropdown: boolean;
}

const INITIAL_STATE = {
  videos: [],
  isLoading: true,
  activeTab: Tabs.REACTIONS,
  reactionsType: FilterableReactionTypes.ALL,
  sort: Sort.LATEST,
  showFiltersDropdown: false,
  showSortDropdown: false,
};

interface Props {
  video: VideoResource;
}

export const SidebarVideos: FC<Props> = ({ video }) => {
  const [state, setState] = useState<State>({
    ...INITIAL_STATE,
    activeTab:
      video.parent || !!!video.reactions_count
        ? Tabs.RELATED_STORIES
        : Tabs.REACTIONS,
  });
  const rootRef = useRef(null);

  const fetchPaginatedVideos = useCallback(
    (link = "/v1/videos") => {
      const include = [
        "media",
        "contributor",
        "contributor.user",
        "meta",
        ...(state.sort === Sort["MOST LIKED"] ? ["likes_count"] : []),
      ].join(",");
      return api.get<never, PaginatedResponse<VideoResource[]>>(link, {
        params:
          state.activeTab === Tabs.RELATED_STORIES
            ? {
                sort: ["-related_tags_count", "-published_at"].join(","),
                include,
                "filter[with_related_tags_count]": video.id,
                per_page: 8,
                "filter[category_id]": video
                  .categories!.map((c) => c.id)
                  .join(","),
                "filter[parent]": 1,
                "filter[exclude]": video.id,
                "filter[visible]": 1,
              }
            : {
                sort: state.sort,
                include,
                per_page: 8,
                "filter[parent_id]": video.id,
                "filter[reaction]": state.reactionsType,
                "filter[visible]": 1,
              },
      });
    },
    [video, state.activeTab, state.reactionsType, state.sort]
  );

  useEffect(() => {
    (async () => {
      try {
        setState((prevState) => ({
          ...prevState,
          isLoading: true,
        }));
        const { data, links } = await fetchPaginatedVideos();
        setState((prevState) => ({
          ...prevState,
          videos: data,
          isLoading: false,
          links,
        }));
      } catch (err) {
        errorNotification();
      }
    })();
  }, [fetchPaginatedVideos]);

  const loader = useCallback(async () => {
    if (!state.links?.next) return;
    const { data: videos, links } = await fetchPaginatedVideos(
      state.links.next
    );
    setState((prevState) => ({
      ...prevState,
      videos: [...prevState.videos, ...videos],
      isLoading: false,
      links,
    }));
  }, [state.links?.next, fetchPaginatedVideos]);

  const { isLoading, ref } = useInfiniteLoader(loader, rootRef.current);

  const onTabChange = (activeTab: Tabs) => {
    if (activeTab === state.activeTab) return;
    setState({
      ...INITIAL_STATE,
      activeTab,
    });
  };

  const toggleFiltersDropdown = (e: MouseEvent<HTMLButtonElement>) => {
    setState((prevState) => ({
      ...prevState,
      showFiltersDropdown: !prevState.showFiltersDropdown,
    }));
  };

  const toggleSortDropdown = (e: MouseEvent<HTMLButtonElement>) => {
    setState((prevState) => ({
      ...prevState,
      showSortDropdown: !prevState.showSortDropdown,
    }));
  };

  const setReactionsType = (reactionsType: FilterableReactionTypes) => {
    if (reactionsType === state.reactionsType) return;
    setState((prevState) => ({
      ...INITIAL_STATE,
      sort: prevState.sort,
      reactionsType,
    }));
  };

  const setSort = (sort: Sort) => {
    if (sort === state.sort) return;
    setState((prevState) => ({
      ...INITIAL_STATE,
      reactionsType: prevState.reactionsType,
      sort,
    }));
  };

  return (
    <Wrap>
      <Header>
        {!!!video.parent && (
          <Heading
            as="div"
            $type="textPoppinsBold"
            $color={state.activeTab === Tabs.REACTIONS ? "black" : "gray"}
          >
            <HeadingButton onClick={() => onTabChange(Tabs.REACTIONS)}>
              COMMENTS ON THIS VIDEO
            </HeadingButton>
            <ReactionsFilter>
              <HeadingButton
                onClick={toggleFiltersDropdown}
                $red={state.showFiltersDropdown}
              >
                <SrOnly>Filter comments</SrOnly>
                <StyledFilterIcon />
              </HeadingButton>
              {state.showFiltersDropdown && (
                <Dropdown
                  onClose={() =>
                    setState((prevState) => ({
                      ...prevState,
                      showFiltersDropdown: false,
                    }))
                  }
                >
                  {Object.values(FilterableReactionTypes).map(
                    (reactionsType) => (
                      <DropdownItem
                        key={reactionsType}
                        onClick={() => setReactionsType(reactionsType)}
                        active={state.reactionsType === reactionsType}
                      >
                        {reactionsType}
                      </DropdownItem>
                    )
                  )}
                </Dropdown>
              )}
            </ReactionsFilter>
            <ReactionsFilter>
              <HeadingButton
                onClick={toggleSortDropdown}
                $red={state.showSortDropdown}
              >
                <SrOnly>Sort comments</SrOnly>
                <StyledSortIcon />
              </HeadingButton>
              {state.showSortDropdown && (
                <Dropdown
                  onClose={() =>
                    setState((prevState) => ({
                      ...prevState,
                      showSortDropdown: false,
                    }))
                  }
                >
                  {(Object.keys(Sort) as Array<keyof typeof Sort>).map(
                    (key) => (
                      <DropdownItem
                        key={key}
                        onClick={() => setSort(Sort[key])}
                        active={state.sort === Sort[key]}
                      >
                        {key}
                      </DropdownItem>
                    )
                  )}
                </Dropdown>
              )}
            </ReactionsFilter>
          </Heading>
        )}
        <Heading
          as="button"
          $type="textPoppinsBold"
          $color={state.activeTab === Tabs.RELATED_STORIES ? "black" : "gray"}
          onClick={() => onTabChange(Tabs.RELATED_STORIES)}
        >
          Trending Videos
        </Heading>
      </Header>
      <VideosList ref={rootRef}>
        {state.isLoading ? (
          <Loader />
        ) : (
          state.videos.map((video) => (
            <ListableVideoCard key={video.id} video={video} />
          ))
        )}
        {!!state.links?.next && <div ref={ref}>{isLoading && <Loader />}</div>}
      </VideosList>
    </Wrap>
  );
};
