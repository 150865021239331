import { SocialMediaResource } from "@/types/api";
import { FC, SVGProps } from "react";
import { ReactComponent as Facebook } from "@/assets/icons/fb.svg";
import { ReactComponent as Twitter } from "@/assets/icons/twitter.svg";
import { ReactComponent as Instagram } from "@/assets/icons/instagram.svg";
import { ReactComponent as LinkedIn } from "@/assets/icons/linkedin.svg";
import { ReactComponent as Youtube } from "@/assets/icons/youtube.svg";

interface Props extends SVGProps<SVGSVGElement> {
  type: SocialMediaResource["type"];
}

export const SocialsIcon: FC<Props> = ({ type, ...props }) => {
  switch (type) {
    case "facebook":
      return <Facebook {...props} />;
    case "twitter":
      return <Twitter {...props} />;
    case "instagram":
      return <Instagram {...props} />;
    case "linkedin":
      return <LinkedIn {...props} />;
    case "youtube":
      return <Youtube {...props} />;
    default:
      throw new Error(`There is no icon for social network of type "${type}"`);
  }
};
