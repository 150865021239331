import Pusher, { AuthorizerGenerator } from "pusher-js";
import { AuthData } from "pusher-js/types/src/core/auth/options";
import Echo from "laravel-echo";

import { api } from "@/utilities/api";

const authorizer: AuthorizerGenerator = (channel) => {
  return {
    authorize: (socketId, callback) => {
      api
        .post<{ socket_id: string; channel_name: string }, AuthData>(
          "/broadcasting/auth",
          {
            socket_id: socketId,
            channel_name: channel.name,
          }
        )
        .then((authData) => {
          callback(null, authData);
        })
        .catch((error) => {
          callback(error, { auth: "" });
        });
    },
  };
};

const client = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY!, {
  cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
  authorizer,
});

const echo = new Echo({
  broadcaster: "pusher",
  client,
});

export default echo;
