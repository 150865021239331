import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";

import { Dispatch, RootState } from "@/utilities/store";
import useAuthGuard from "@/hooks/useAuthGuard";
import { errorNotification } from "@/utilities/alerts";

const useFollow = (
  contributorId: number,
  callback?: (increment: boolean) => void
) => {
  const dispatch = useDispatch<Dispatch>();

  const [isFollowLoading, setIsFollowLoading] = useState(false);

  const isFollowed = useSelector(
    (state: RootState) =>
      !!state.authentication.user?.followed_contributors_ids?.includes(
        contributorId
      )
  );

  const toggleFollow = useAuthGuard(async (contributorId: number) => {
    try {
      setIsFollowLoading(true);
      if (isFollowed) {
        await dispatch.authentication.unfollowContributor(contributorId);
        callback?.(false);
      } else {
        await dispatch.authentication.followContributor(contributorId);
        callback?.(true);
      }
      setIsFollowLoading(false);
    } catch (err) {
      errorNotification();
    }
  });

  return {
    isFollowed,
    toggleFollow: () => toggleFollow(contributorId),
    isFollowLoading,
  };
};

export default useFollow;
