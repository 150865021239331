import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";

import { Dispatch, RootState } from "@/utilities/store";
import useAuthGuard from "@/hooks/useAuthGuard";

const useBookmarks = (videoId: number) => {
  const dispatch = useDispatch<Dispatch>();
  const [isBookmarkLoading, setIsBookmarkLoading] = useState(false);

  const isBookmarked = useSelector(
    (state: RootState) =>
      !!state.authentication.user?.bookmarked_videos_ids?.includes(videoId)
  );

  const toggleBookmark = useAuthGuard(async () => {
    setIsBookmarkLoading(true);
    await (isBookmarked
      ? dispatch.authentication.unbookmarkVideo(videoId)
      : dispatch.authentication.bookmarkVideo(videoId));
    setIsBookmarkLoading(false);
  });

  return { isBookmarked, toggleBookmark, isBookmarkLoading };
};

export default useBookmarks;
