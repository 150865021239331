import { FC } from "react";

import {
  FlexibleContentBlock,
  TextBlock,
  TextBlockWithLeftTitle,
} from "@/types/api";
import { WysiwygContent } from "@/components/common";
import { TitledTextBlock } from "@/pages/Text/components/TitledTextBlock";

interface Props {
  contentBlocks: FlexibleContentBlock[];
}

export const FlexibleContent: FC<Props> = ({ contentBlocks }) => {
  const renderContentBlocks = () => {
    return contentBlocks.map((block) => {
      switch (block.layout) {
        case "text_block":
          return (
            <WysiwygContent
              key={block.key}
              dangerouslySetInnerHTML={{
                __html: (block.attributes as TextBlock).text,
              }}
            />
          );
        case "text_block_with_left_title":
          return (
            <TitledTextBlock
              key={block.key}
              block={block.attributes as TextBlockWithLeftTitle}
            />
          );
        default:
          return null;
      }
    });
  };

  return <>{renderContentBlocks()}</>;
};
