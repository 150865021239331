import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { useMatch, useSearchParams, useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import useDebounce from "@/hooks/useDebounce";

const Input = styled.input`
  border-radius: 16px;
  width: 100%;
  position: absolute;
  height: 32px;
  border: 0;
  padding: 0 30px 0 23px;
  ${({ theme }) => css`
    background: ${theme.colors.white};
    font-size: ${theme.typography.textPoppins.fontSize};
    font-family: ${theme.typography.textPoppins.fontFamily};
    outline: none !important;
    &::placeholder {
      color: ${theme.colors.black};
    }
  `}
`;

interface Props {
  className?: string;
  onBlur: () => void;
}

export const Searchbar: FC<Props> = ({ className, onBlur }) => {
  const mountRef = useRef(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const [value, setValue] = useState("");

  const debouncedValue = useDebounce<string>(value, 300);

  const match = useMatch({ path: "/search", end: true });

  const navigate = useNavigate();

  useEffect(() => {
    if (!mountRef.current) {
      setValue(searchParams.get("s") || "");
      inputRef.current?.focus();
      mountRef.current = true;
      return;
    }
    const newSearchParams = new URLSearchParams(searchParams);
    if (debouncedValue) {
      newSearchParams.set("s", debouncedValue);
    } else {
      newSearchParams.delete("s");
    }
    setSearchParams(newSearchParams, { replace: true });

    // eslint-disable-next-line
  }, [debouncedValue, setSearchParams]);

  useEffect(() => {
    if (debouncedValue && !match) {
      navigate(`/search${window.location.search}`);
    }
  }, [match, navigate, debouncedValue]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) =>
    setValue(e.target.value);

  return (
    <Input
      ref={inputRef}
      type="text"
      placeholder="Search"
      className={className}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
    />
  );
};
