import { FC, MouseEvent } from "react";
import styled, { css } from "styled-components";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  RedditIcon,
  RedditShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  LinkedinShareButton,
  LinkedinIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  ViberShareButton,
  ViberIcon,
} from "react-share";

import { VideoResource } from "@/types/api";
import { copyToClipboard } from "@/utilities/helpers";
import { ReactComponent as LinkIcon } from "@/assets/icons/link.svg";
import { ReactComponent as ChatIcon } from "@/assets/icons/chat.svg";
import { ReactComponent as SnapChatIcon } from "@/assets/icons/snapchat.svg";

import { Dropdown } from "@/components/Dropdown";

const ShareDropdown = styled(Dropdown)`
  width: 290px;
  padding: 16px;
  max-width: 100vw;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: -5px;
  > div {
    margin: 5px;
  }
  button,
  a {
    margin: 0;
    padding: 0;
    &:hover {
      opacity: 0.75;
    }
  }
  svg {
    border-radius: 50%;
  }
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      top: auto;
      bottom: calc(100% + 10px);
      right: 10px;
    }
  `}
`;

const LinkShareButton = styled.button`
  width: 31px;
  height: 31px;
  background: rgb(127, 127, 127);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.white};
  svg {
    height: 20px;
  }
`;

const SnapchatShareButton = styled(LinkShareButton)`
  background: #fffc00;
  svg {
    height: 75%;
  }
`;

interface Props {
  video: VideoResource;
  onClose: () => void;
}

export const ShareDialog: FC<Props> = ({ video, onClose }) => {
  const onCopyToClipboard = (e: MouseEvent<HTMLButtonElement>) => {
    copyToClipboard(video.permalink);
    onClose();
  };

  return (
    <ShareDropdown onClose={onClose}>
      <div>
        <LinkShareButton onClick={onCopyToClipboard}>
          <LinkIcon />
        </LinkShareButton>
      </div>
      <div>
        <EmailShareButton
          url={video.permalink}
          subject={`${video.name} | MyPoint.TV`}
          body="Share:"
        >
          <EmailIcon size={32} round={true} />
        </EmailShareButton>
      </div>
      <div>
        <LinkShareButton as="a" href={`sms:?body=${video.permalink}`}>
          <ChatIcon />
        </LinkShareButton>
      </div>
      <div>
        <FacebookShareButton url={video.permalink}>
          <FacebookIcon size={32} round={true} />
        </FacebookShareButton>
      </div>
      <div>
        <TwitterShareButton
          url={video.permalink}
          title={video.name}
          related={["@MypointTv"]}
        >
          <TwitterIcon size={32} round={true} />
        </TwitterShareButton>
      </div>
      <div>
        <LinkedinShareButton
          url={video.permalink}
          summary={video.description}
          source="MyPoint.TV"
        >
          <LinkedinIcon size={32} round={true} />
        </LinkedinShareButton>
      </div>
      <div>
        <SnapchatShareButton
          as="a"
          href={`https://www.snapchat.com/scan?attachmentUrl=${video.permalink}`}
          target="_blank"
          rel="noreferrer noopener"
        >
          <SnapChatIcon />
        </SnapchatShareButton>
      </div>
      <div>
        <FacebookMessengerShareButton
          url={video.permalink}
          appId={process.env.REACT_APP_FACEBOOK_APP_ID!}
        >
          <FacebookMessengerIcon size={32} round={true} />
        </FacebookMessengerShareButton>
      </div>
      <div>
        <ViberShareButton
          url={video.permalink}
          title={`${video.name} | MyPoint.TV`}
        >
          <ViberIcon size={32} round={true} />
        </ViberShareButton>
      </div>
      <div>
        <WhatsappShareButton
          url={video.permalink}
          title={`${video.name} | MyPoint.TV`}
        >
          <WhatsappIcon size={32} round={true} />
        </WhatsappShareButton>
      </div>
      <div>
        <RedditShareButton
          url={video.permalink}
          title={`${video.name} | MyPoint.TV`}
        >
          <RedditIcon size={32} round={true} />
        </RedditShareButton>
      </div>
    </ShareDropdown>
  );
};
