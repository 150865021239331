import { FC, useState } from "react";
import styled, { css } from "styled-components";
import MediaQuery from "react-responsive";
import { Link, useLocation } from "react-router-dom";

import useBookmarks from "@/hooks/useBookmarks";
import useLikes from "@/hooks/useLikes";
import { Breakpoints } from "@/utilities/theme";
import { ReactComponent as BookmarkIcon } from "@/assets/icons/bookmark.svg";
import { ReactComponent as RespondIcon } from "@/assets/icons/respond.svg";
import { ReactComponent as ShareIcon } from "@/assets/icons/share.svg";
import { ReactComponent as LikeIcon } from "@/assets/icons/like.svg";
import { ReactComponent as EyeIcon } from "@/assets/icons/eye.svg";
import { ReactComponent as ReactionIcon } from "@/assets/icons/reaction.svg";
import { Text } from "@/components/common";
import { VideoResource } from "@/types/api";
import { ShareDialog } from "@/pages/Video/components/ShareDialog";
import { ReactComponent as Spinner } from "@/assets/icons/spinner.svg";

const VideoActions = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

const VideoMetaItem = styled.div`
  display: inline-flex;
  align-items: center;
  margin-right: 28px;
  svg {
    margin-right: 6px;
    stroke: #ffffff;
  }
  ${({ theme }) => css`
    ${theme.breakpoints.smallDesktop} {
      margin-right: 20px;
    }
  `}
`;

const VideoButton = styled(VideoMetaItem)<{ $fillIcon?: boolean }>`
  margin-left: 28px;
  margin-right: 0;
  text-transform: uppercase;
  cursor: pointer;
  svg {
    margin-right: 0;
    margin-left: 5px;
    fill: transparent;
    ${({ $fillIcon, theme }) =>
      $fillIcon &&
      css`
        fill: ${theme.colors.white};
      `}
  }
  &:hover {
    opacity: 0.75;
  }
  ${({ theme }) => css`
    ${theme.breakpoints.smallDesktop} {
      margin-left: 20px;
    }
  `}
`;

const VideoFooter = styled.div`
  background: ${({ theme }) => theme.colors.black};
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 30px;
  margin-top: -1px;
  ${({ theme }) => css`
    ${theme.breakpoints.smallDesktop} {
      padding: 0 15px;
    }
    ${theme.breakpoints.phone} {
      padding: 0;
      align-items: stretch;
      position: relative;
    }
  `}
`;

const MobileButton = styled.button<{ $fillIcon?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 20%;
  align-items: center;
  justify-content: center;
  svg {
    height: 12px;
    stroke: #fff;
    fill: ${({ $fillIcon }) => ($fillIcon ? "#fff" : "transparent")};
    margin: 0 0 7px;
  }
  ${Text} {
    line-height: 1;
    font-size: 9px;
  }
`;

const ShareButtonWrap = styled.div`
  position: relative;
  line-height: 0;
`;

interface Props {
  video: VideoResource;
}

export const VideoCardFooter: FC<Props> = ({ video }) => {
  const location = useLocation();

  const { isBookmarked, toggleBookmark, isBookmarkLoading } = useBookmarks(
    video.id
  );

  const { isLiked, toggleLike, isLikeLoading } = useLikes(video.id);

  const [showSharingDropdown, setShowSharingDropdown] = useState(false);

  return (
    <VideoFooter>
      <MediaQuery maxWidth={Breakpoints.max.phone}>
        <MobileButton as="div">
          <EyeIcon />
          <Text as="span" $color="white" $type="labelNormal">
            {video.views} Views
          </Text>
        </MobileButton>
        <MobileButton as="div">
          <ReactionIcon />
          <Text as="span" $color="white" $type="labelNormal">
            {video.reactions_count} Comments
          </Text>
        </MobileButton>
        <MobileButton
          disabled={isLikeLoading}
          onClick={toggleLike}
          $fillIcon={isLiked}
        >
          {isLikeLoading ? <Spinner /> : <LikeIcon />}
          <Text as="span" $color="white" $type="labelNormal">
            Like
          </Text>
        </MobileButton>
        <MobileButton
          $fillIcon={isBookmarked}
          disabled={isBookmarkLoading}
          onClick={toggleBookmark}
        >
          {isBookmarkLoading ? <Spinner /> : <BookmarkIcon />}
          <Text as="span" $color="white" $type="labelNormal">
            Bookmark
          </Text>
        </MobileButton>
        <MobileButton onClick={() => setShowSharingDropdown((prev) => !prev)}>
          <ShareIcon />
          <Text as="span" $color="white" $type="labelNormal">
            Share
          </Text>
        </MobileButton>
        {showSharingDropdown && (
          <ShareDialog
            onClose={() => setShowSharingDropdown(false)}
            video={video}
          />
        )}
      </MediaQuery>
      <MediaQuery minWidth={Breakpoints.min.tablet}>
        <VideoMetaItem>
          <EyeIcon />
          <Text as="span" $color="white" $type="labelNormal">
            {video.views} Views
          </Text>
        </VideoMetaItem>
        <VideoMetaItem>
          <ReactionIcon />
          <Text as="span" $color="white" $type="labelNormal">
            {video.reactions_count} Comments
          </Text>
        </VideoMetaItem>
        <VideoActions>
          <MediaQuery minWidth={Breakpoints.min.largeDesktop}>
            <VideoButton
              as={Link}
              to={`/videos/${video.slug}/comment`}
              state={{ intended: location }}
            >
              <Text as="span" $color="white" $type="labelSemiBold">
                Respond
              </Text>
              <RespondIcon />
            </VideoButton>
          </MediaQuery>

          <VideoButton
            as="button"
            type="button"
            $fillIcon={isBookmarked}
            disabled={isBookmarkLoading}
            onClick={toggleBookmark}
          >
            <Text as="span" $color="white" $type="labelSemiBold">
              Bookmark
            </Text>
            {isBookmarkLoading ? <Spinner /> : <BookmarkIcon />}
          </VideoButton>
          <VideoButton
            as="button"
            type="button"
            disabled={isLikeLoading}
            onClick={toggleLike}
            $fillIcon={isLiked}
          >
            <Text as="span" $color="white" $type="labelSemiBold">
              Like
            </Text>
            {isLikeLoading ? <Spinner /> : <LikeIcon />}
          </VideoButton>
          <ShareButtonWrap>
            <VideoButton
              as="button"
              type="button"
              onClick={() => setShowSharingDropdown((prev) => !prev)}
            >
              <Text as="span" $color="white" $type="labelSemiBold">
                Share
              </Text>
              <ShareIcon />
            </VideoButton>
            {showSharingDropdown && (
              <ShareDialog
                onClose={() => setShowSharingDropdown(false)}
                video={video}
              />
            )}
          </ShareButtonWrap>
        </VideoActions>
      </MediaQuery>
    </VideoFooter>
  );
};
