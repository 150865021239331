import { FC, useState } from "react";
import { FieldError } from "react-hook-form";
import styled from "styled-components";

import { VideoResource } from "@/types/api";
import { Input, Label, ValidationError } from "@/components/common";
import { Dropdown, DropdownItem } from "@/components/Dropdown";

const Wrap = styled.div`
  position: relative;
`;

const StyledInput = styled(Input)`
  cursor: pointer;
  text-transform: capitalize;
`;

const StyledDropdownItem = styled(DropdownItem)`
  text-transform: capitalize;
`;

interface Props {
  error?: FieldError;
  setReaction: (reaction: VideoResource["reaction"]) => void;
}

export const ReactionDropdown: FC<Props> = ({ error, setReaction }) => {
  const [value, setValue] = useState<string>("");
  const [showDropdown, setShowDropdown] = useState(false);

  const onSelect = (value: Exclude<VideoResource["reaction"], undefined>) => {
    setValue(value);
    setReaction(value);
    setShowDropdown(false);
  };

  return (
    <>
      <Wrap>
        <Label>Reaction</Label>
        <StyledInput
          $hasError={!!error}
          readOnly
          value={value}
          placeholder="Choose reaction type"
          onClick={() => setShowDropdown(true)}
        />
        {showDropdown && (
          <Dropdown onClose={() => setShowDropdown(false)} select>
            {["supporting", "neutral", "opposing"].map((reaction) => (
              <StyledDropdownItem
                select
                key={reaction}
                active={value === reaction}
                onClick={() =>
                  onSelect(
                    reaction as Exclude<VideoResource["reaction"], undefined>
                  )
                }
              >
                {reaction}
              </StyledDropdownItem>
            ))}
          </Dropdown>
        )}
      </Wrap>
      {!!error && <ValidationError>{error.message}</ValidationError>}
    </>
  );
};
