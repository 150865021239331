import { forwardRef, HTMLProps } from "react";

import { Input, ValidationError, Label } from "@/components/common";
import { FieldError } from "react-hook-form";

interface Props extends HTMLProps<HTMLInputElement> {
  error?: FieldError;
  label?: string;
}

export const FormGroup = forwardRef<HTMLInputElement, Props>(
  ({ className, error, label, ...rest }, ref) => {
    return (
      <div className={className}>
        {label && <Label>{label}</Label>}
        <Input {...rest} $hasError={!!error} ref={ref} as="input" />
        <ValidationError>{error?.message}</ValidationError>
      </div>
    );
  }
);
