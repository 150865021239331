import { FC } from "react";
import styled, { css } from "styled-components";

import { Text } from "@/components/common";

const Label = styled(Text)`
  text-align: center;
  letter-spacing: 0.65px;
  margin: 40px 0;
  ${({ theme }) => css`
    ${theme.breakpoints.phone} {
      margin: 0 0 35px;
    }
  `}
`;

export const Separator: FC = ({ children }) => {
  return <Label $type="labelNormal">{children}</Label>;
};
