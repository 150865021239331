import styled, { css } from "styled-components";
import { ComponentProps, FC, useRef } from "react";

import { Card } from "@/components/common";
import useDetectOutsideClick from "@/hooks/useDetectOutsideClick";

export * from "./DropdownItem";

const StyledDropdown = styled(Card)<{ $isSelect: boolean }>`
  width: 220px;
  position: absolute;
  right: 0;
  padding: 32px 15px;
  top: calc(100% + 12px);
  z-index: ${({ theme }) => theme.zIndex.dropdown};
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ $isSelect }) =>
    $isSelect &&
    css`
      width: 100%;
      top: 100%;
      right: 0;
      padding: 7px 43px;
    `}
`;

interface Props extends Pick<ComponentProps<"div">, "className"> {
  onClose: () => void;
  select?: boolean;
}

export const Dropdown: FC<Props> = ({
  children,
  onClose,
  className,
  select,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useDetectOutsideClick(ref, onClose);

  return (
    <StyledDropdown
      as="div"
      ref={ref}
      className={className}
      $isSelect={!!select}
    >
      {children}
    </StyledDropdown>
  );
};
