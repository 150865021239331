import { FC, useState } from "react";
import styled from "styled-components";
import MediaQuery from "react-responsive";
import { Link } from "react-router-dom";

import { Breakpoints } from "@/utilities/theme";
import { ReactComponent as UploadIcon } from "@/assets/icons/upload.svg";
import { ReactComponent as SearchIcon } from "@/assets/icons/search.svg";
import { ReactComponent as BookmarkIcon } from "@/assets/icons/bookmark.svg";
import { SearchModal } from "@/components/SearchModal";

const Bar = styled.nav`
  position: fixed;
  z-index: 5;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  box-shadow: 0 0 15px rgba(32, 37, 44, 0.1);
  background-color: #20252c;
  background: ${({ theme }) => theme.colors.black};
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  a {
    line-height: 0;
    padding: 5px;
  }
  > a:first-of-type {
    svg {
      fill: transparent;
      stroke: ${({ theme }) => theme.colors.white};
    }
  }
`;

const Button = styled.button`
  color: ${({ theme }) => theme.colors.white};
  line-height: 0;
  padding: 5px;
`;

export const MobileNav: FC = () => {
  const [showSearch, setShowSearch] = useState(false);

  return (
    <MediaQuery maxWidth={Breakpoints.max.phone}>
      <Bar>
        <Link to="/bookmarks">
          <BookmarkIcon />
        </Link>
        <Link to="/upload">
          <UploadIcon />
        </Link>
        <Button onClick={() => setShowSearch(true)}>
          <SearchIcon />
        </Button>
        {showSearch && <SearchModal onClose={() => setShowSearch(false)} />}
      </Bar>
    </MediaQuery>
  );
};
